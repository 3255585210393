import { type PropsWithChildren } from 'react'

import { Grid, type GridProps } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'

type WithExcludedGridProps = Omit<GridProps, 'item' | 'xs' | 'sm' | 'md' | 'lg'>
interface Props extends WithExcludedGridProps {
  variant?: 'fluid' | 'slim' | 'stretch'
  className?: string
}

/**
 * @deprecated use `<Container />` from material ui instead
 * ex:
 * ```
 * import { Container } from'@mui/material'
 *
 * <Container>
 *  ...
 *  ...
 * </Container>
 * ```
 * `OBS:` No props or style is needed.
 */
export default function Container(props: PropsWithChildren<Props>) {
  const { children, variant, className, ...other } = props
  const classes = useStyles()

  const xs = clsx({
    12: variant === 'fluid',
    11: variant === 'slim' || variant === 'stretch' || variant === undefined,
  })

  const sm = clsx({
    12: variant === 'fluid',
    7: variant === 'slim',
    11: variant === 'stretch' || variant === undefined,
  })

  const md = clsx({
    5: variant === 'slim',
    9: variant === 'stretch',
    8: variant === undefined,
    12: variant === 'fluid',
  })

  const lg = clsx({
    4: variant === 'slim',
    9: variant === 'stretch',
    8: variant === undefined,
    12: variant === 'fluid',
  })

  return (
    <Grid container justifyContent="center">
      <Grid
        item
        xs={Number(xs)}
        sm={Number(sm)}
        md={Number(md)}
        lg={Number(lg)}
        {...other}
        className={`${className} ${classes.child}`}
      >
        {children}
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    width: 990,
    maxWidth: '70%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  fluid: {
    width: '100%',
  },
  stretch: {
    width: '75%',
  },
  slim: {
    width: '30%',
  },
  child: {
    width: '100%',
  },
}))
