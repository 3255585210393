import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material'
import {
  Button,
  ClickAwayListener,
  Grow,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@mui/material'
import { withStyles } from '@mui/styles'

import { COLORS } from '../../theme'

/**
 * @type {{GREEN: 3, YELLOW: 2, RED: 1, SKIPPED: 0, DOES_NOT_APPLY: 9, ALL: 'ALL'}}
 */
export const FILTERED_BY_OPTIONS = {
  GREEN: 3,
  YELLOW: 2,
  RED: 1,
  SKIPPED: 0,
  DOES_NOT_APPLY: 9,
  ALL: 'ALL',
}

export const SORT_BY_OPTIONS = {
  DEFAULT: 'DEFAULT',
  MOST_GREENS: 'MOST_GREENS',
  MOST_REDS: 'MOST_REDS',
  MOST_YELLOWS: 'MOST_YELLOWS',
  MOST_SKIPPED: 'MOST_SKIPPED',
  MOST_DOES_NOT_APPLY: 'MOST_DOES_NOT_APPLY',
  MOST_PRIORITIZED: 'MOST_PRIORITIZED',
  LESS_PRIORITIZED: 'LESS_PRIORITIZED',
  MOST_ACHIEVED: 'MOST_ACHIEVED',
  LESS_ACHIEVED: 'LESS_ACHIEVED',
}

export const sorter = sortBy => (indA, indB) => {
  let compareResult = 0
  const stoplightCountFinder = (stoplights, filter, total) => {
    const stoplight = stoplights.find(s => s.color === filter) || {
      count: 0,
    }
    return stoplight.count / total
  }
  const { stoplights: stoplightsA = [] } = indA
  const { stoplights: stoplightsB = [] } = indB
  const { priorities: prioritiesA = 0 } = indA
  const { priorities: prioritiesB = 0 } = indB
  const { achievements: achievementsA = 0 } = indA
  const { achievements: achievementsB = 0 } = indB
  const totalA = stoplightsA.reduce((acc, current) => acc + current.count, 0)
  const totalB = stoplightsB.reduce((acc, current) => acc + current.count, 0)
  if (sortBy === SORT_BY_OPTIONS.MOST_GREENS) {
    compareResult =
      stoplightCountFinder(stoplightsB, 3, totalB) -
      stoplightCountFinder(stoplightsA, 3, totalA)
  } else if (sortBy === SORT_BY_OPTIONS.MOST_YELLOWS) {
    compareResult =
      stoplightCountFinder(stoplightsB, 2, totalB) -
      stoplightCountFinder(stoplightsA, 2, totalA)
  } else if (sortBy === SORT_BY_OPTIONS.MOST_REDS) {
    compareResult =
      stoplightCountFinder(stoplightsB, 1, totalB) -
      stoplightCountFinder(stoplightsA, 1, totalA)
  } else if (sortBy === SORT_BY_OPTIONS.MOST_SKIPPED) {
    compareResult =
      stoplightCountFinder(stoplightsB, 0, totalB) -
      stoplightCountFinder(stoplightsA, 0, totalA)
  } else if (sortBy === SORT_BY_OPTIONS.MOST_DOES_NOT_APPLY) {
    compareResult =
      stoplightCountFinder(stoplightsB, 9, totalB) -
      stoplightCountFinder(stoplightsA, 9, totalA)
  } else if (sortBy === SORT_BY_OPTIONS.MOST_PRIORITIZED) {
    compareResult = prioritiesB - prioritiesA
  } else if (sortBy === SORT_BY_OPTIONS.LESS_PRIORITIZED) {
    compareResult = prioritiesA - prioritiesB
  } else if (sortBy === SORT_BY_OPTIONS.MOST_ACHIEVED) {
    compareResult = achievementsB - achievementsA
  } else if (sortBy === SORT_BY_OPTIONS.LESS_ACHIEVED) {
    compareResult = achievementsA - achievementsB
  } else if (sortBy === SORT_BY_OPTIONS.DEFAULT) {
    compareResult = 0
  }
  return compareResult
}

const IndicatorsFilter = ({
  classes,
  greenIndicatorCount,
  yellowIndicatorCount,
  redIndicatorCount,
  skippedIndicatorCount,
  doesNotApplyIndicatorCount,
  filterValue,
  onFilterChanged,
  sorting,
  sortingBy,
  onSortingChanged,
}) => {
  const { t } = useTranslation()

  const [open, setOpen] = useState(false)
  const anchorEl = useRef(null)
  const allIndicatorsCount =
    greenIndicatorCount +
    yellowIndicatorCount +
    redIndicatorCount +
    skippedIndicatorCount +
    doesNotApplyIndicatorCount
  return (
    <div className={classes.mainContainer}>
      <Button
        ref={anchorEl}
        className={classes.button}
        aria-owns={anchorEl ? 'simple-menu' : undefined}
        aria-haspopup="true"
        onClick={() => {
          setOpen(true)
        }}
      >
        <Typography variant="subtitle1" className={classes.buttonText}>
          {!sorting &&
            filterValue === FILTERED_BY_OPTIONS.ALL &&
            `${t(
              'views.indicatorsFilter.allIndicators',
            )}  (${allIndicatorsCount})`}
          {!sorting &&
            filterValue === FILTERED_BY_OPTIONS.GREEN &&
            `${t('views.indicatorsFilter.green')} (${greenIndicatorCount})`}
          {!sorting &&
            filterValue === FILTERED_BY_OPTIONS.YELLOW &&
            `${t('views.indicatorsFilter.yellow')} (${yellowIndicatorCount})`}
          {!sorting &&
            filterValue === FILTERED_BY_OPTIONS.RED &&
            `${t('views.indicatorsFilter.red')} (${redIndicatorCount})`}
          {!sorting &&
            filterValue === FILTERED_BY_OPTIONS.SKIPPED &&
            `${t('views.indicatorsFilter.skipped')} (${skippedIndicatorCount})`}
          {!sorting &&
            filterValue === FILTERED_BY_OPTIONS.DOES_NOT_APPLY &&
            `${t(
              'views.dashboard.doesNotApply',
            )} (${doesNotApplyIndicatorCount})`}
          {sorting && t(`views.indicatorsFilter.sort.${sortingBy}`)}
        </Typography>
        <ArrowDropDownIcon className={classes.buttonIcon} />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorEl.current}
        transition
        disablePortal
        className={classes.popper}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            id="menu-list-grow"
            style={{
              transformOrigin: 'center bottom',
            }}
          >
            <Paper
              style={{
                width: anchorEl.current.clientWidth,
              }}
              className={classes.paper}
            >
              <ClickAwayListener
                onClickAway={() => {
                  setOpen(false)
                }}
              >
                <MenuList>
                  {!sorting && filterValue !== FILTERED_BY_OPTIONS.ALL && (
                    <MenuItem
                      onClick={() => {
                        setOpen(false)
                        onFilterChanged(FILTERED_BY_OPTIONS.ALL)
                      }}
                    >
                      <ListItemIcon className={classes.icon}>
                        <div
                          className={classes.indicatorBall}
                          style={{
                            background: `conic-gradient(${COLORS.GREEN} 25%, ${COLORS.YELLOW} 0 50%, ${COLORS.RED} 0 75%, ${COLORS.LIGHT_GREY} 0)`,
                          }}
                        />
                      </ListItemIcon>
                      <Typography variant="subtitle1">
                        {`${t(
                          'views.indicatorsFilter.allIndicators',
                        )} (${allIndicatorsCount})`}
                      </Typography>
                    </MenuItem>
                  )}
                  {!sorting && filterValue !== FILTERED_BY_OPTIONS.GREEN && (
                    <MenuItem
                      onClick={() => {
                        setOpen(false)
                        onFilterChanged(FILTERED_BY_OPTIONS.GREEN)
                      }}
                    >
                      <ListItemIcon className={classes.icon}>
                        <div
                          className={classes.indicatorBall}
                          style={{ backgroundColor: COLORS.GREEN }}
                        />
                      </ListItemIcon>
                      <Typography variant="subtitle1">
                        {`${t(
                          'views.indicatorsFilter.green',
                        )} (${greenIndicatorCount})`}
                      </Typography>
                    </MenuItem>
                  )}
                  {!sorting && filterValue !== FILTERED_BY_OPTIONS.YELLOW && (
                    <MenuItem
                      onClick={() => {
                        setOpen(false)
                        onFilterChanged(FILTERED_BY_OPTIONS.YELLOW)
                      }}
                    >
                      <ListItemIcon className={classes.icon}>
                        <div
                          className={classes.indicatorBall}
                          style={{ backgroundColor: COLORS.YELLOW }}
                        />
                      </ListItemIcon>
                      <Typography variant="subtitle1">
                        {`${t(
                          'views.indicatorsFilter.yellow',
                        )} (${yellowIndicatorCount})`}
                      </Typography>
                    </MenuItem>
                  )}
                  {!sorting && filterValue !== FILTERED_BY_OPTIONS.RED && (
                    <MenuItem
                      onClick={() => {
                        setOpen(false)
                        onFilterChanged(FILTERED_BY_OPTIONS.RED)
                      }}
                    >
                      <ListItemIcon className={classes.icon}>
                        <div
                          className={classes.indicatorBall}
                          style={{ backgroundColor: COLORS.RED }}
                        />
                      </ListItemIcon>
                      <Typography variant="subtitle1">
                        {`${t(
                          'views.indicatorsFilter.red',
                        )} (${redIndicatorCount})`}
                      </Typography>
                    </MenuItem>
                  )}
                  {!sorting && filterValue !== FILTERED_BY_OPTIONS.SKIPPED && (
                    <MenuItem
                      onClick={() => {
                        setOpen(false)
                        onFilterChanged(FILTERED_BY_OPTIONS.SKIPPED)
                      }}
                    >
                      <ListItemIcon className={classes.icon}>
                        <div
                          className={classes.indicatorBall}
                          style={{ backgroundColor: COLORS.SKIPPED_GREY }}
                        />
                      </ListItemIcon>
                      <Typography variant="subtitle1">
                        {`${t(
                          'views.indicatorsFilter.skipped',
                        )} (${skippedIndicatorCount})`}
                      </Typography>
                    </MenuItem>
                  )}
                  {!sorting &&
                    filterValue !== FILTERED_BY_OPTIONS.DOES_NOT_APPLY && (
                      <MenuItem
                        onClick={() => {
                          setOpen(false)
                          onFilterChanged(FILTERED_BY_OPTIONS.DOES_NOT_APPLY)
                        }}
                      >
                        <ListItemIcon className={classes.icon}>
                          <div
                            className={classes.indicatorBall}
                            style={{
                              backgroundColor: COLORS.DOES_NOT_APPLY_GREY,
                            }}
                          />
                        </ListItemIcon>
                        <Typography variant="subtitle1">
                          {`${t(
                            'views.dashboard.doesNotApply',
                          )} (${doesNotApplyIndicatorCount})`}
                        </Typography>
                      </MenuItem>
                    )}
                  {sorting &&
                    Object.keys(SORT_BY_OPTIONS)
                      .filter(key => sortingBy !== key)
                      .map(key => (
                        <MenuItem
                          className={classes.sortItemStyle}
                          key={key}
                          onClick={() => {
                            setOpen(false)
                            onSortingChanged(key)
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            className={classes.itemTextStyle}
                          >
                            {t(`views.indicatorsFilter.sort.${key}`)}
                          </Typography>
                        </MenuItem>
                      ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}

const styles = () => ({
  mainContainer: {
    width: '100%',
    height: '42px',
    border: '1px solid #DCDEE3',
    boxSizing: 'border-box',
    borderRadius: 2,
    backgroundColor: '#FAFBFC',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    height: '100%',
    borderRadius: 0,
    borderTop: 0,
    color: '#6A6A6A',
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 500,
    textDecoration: 'none',
    width: 300,
    paddingLeft: 15,
    paddingRight: 15,
    textTransform: 'initial',
  },
  paper: {
    border: '0.1px solid #DCDEE3',
    borderRadius: 0,
    backgroundColor: '#FFFFFF',
  },
  popper: {
    zIndex: 100,
  },
  indicatorBall: {
    borderRadius: '50%',
    width: 17,
    height: 17,
  },
  buttonIcon: {
    marginLeft: 'auto',
  },
  buttonText: {
    marginLeft: 'auto',
    fontSize: 14,
  },
  itemTextStyle: {
    fontSize: 14,
  },
  sortItemStyle: {
    minHeight: '42px',
  },
})

export default withStyles(styles)(IndicatorsFilter)
