import { get } from 'lodash'
import { useSnackbar } from 'notistack'

import ConfirmationModal from '../../components/ConfirmationModal'
import { deleteLibraryQuestion } from '../../services/economic-library'

const EconomicLibraryDeleteModal = ({
  open,
  question,
  toggleModal,
  afterSubmit,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)

  const onClose = action => {
    action.submitted && afterSubmit(true)
    toggleModal()
  }

  const handleDeleteQuestion = () => {
    setLoading(true)
    deleteLibraryQuestion(question)
      .then(() => {
        setLoading(false)
        onClose({ submitted: true })
        enqueueSnackbar(t('views.economic.delete.success'), {
          variant: 'success',
        })
      })
      .catch(e => {
        setLoading(false)
        const message = get(
          e,
          'response.data.message',
          t('views.report.table.error'),
        )
        enqueueSnackbar(message, {
          variant: 'error',
        })
      })
  }

  return (
    <ConfirmationModal
      title={t('views.economic.delete.confirmTitle')}
      subtitle={t('views.economic.delete.confirmSubTitle')}
      cancelButtonText={t('general.no')}
      continueButtonText={t('general.yes')}
      onClose={onClose}
      disabledFacilitator={loading}
      open={open}
      confirmAction={handleDeleteQuestion}
    />
  )
}

export default EconomicLibraryDeleteModal
