import { useSelector } from 'react-redux'

import { type Permission, type User } from '../types/user'

export function usePermissions() {
  const user = useSelector((state: { user: User }) => state.user)
  const userHasPermissions =
    Array.isArray(user.permissions) && user.permissions.length > 0

  /**
   * Check if the current user has any of the permissions in the list
   */
  function userHasAnyPermissions(permissions: Permission[]): boolean {
    if (
      userHasPermissions &&
      Array.isArray(permissions) &&
      permissions.length > 0
    ) {
      return permissions.some(permission =>
        user.permissions?.includes(permission),
      )
    }

    return false
  }

  /**
   * Check if the current user has all of the permissions in the list
   */
  function userHasAllPermissions(permissions: Permission[]): boolean {
    if (
      userHasPermissions &&
      Array.isArray(permissions) &&
      permissions.length > 0
    ) {
      return permissions.every(permission =>
        user.permissions?.includes(permission),
      )
    }

    return false
  }

  return { userHasAnyPermissions, userHasAllPermissions }
}
