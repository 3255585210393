import { createQueryKeys } from '@lukemorales/query-key-factory'

import store from '~/redux'
import { getProjectsPaginated } from '~/services/projects-services'
import { type PlatformLanguage } from '~/utils/types/i18n'

const userId = store.getState().user?.id

export const projectsQueries = createQueryKeys('projects', {
  listPaginated: (
    language: PlatformLanguage,
    projectsPaginatedParams: Omit<
      Parameters<typeof getProjectsPaginated>[0],
      'page'
    >,
  ) => ({
    queryKey: [language, { projectsPaginatedParams }, { userId }],
    queryFn: async ({ pageParam = 0 }) => {
      const response = await getProjectsPaginated({
        ...projectsPaginatedParams,
        page: pageParam,
      })
      return response.data.data.searchProjects
    },
  }),
})
