export const PLATFORM_LANGUAGES = [
  { langKey: 'en', langName: 'English' },
  { langKey: 'es', langName: 'Español' },
  { langKey: 'pt-BR', langName: 'Português (BR)' },
  { langKey: 'pt', langName: 'Português (PT)' },
  { langKey: 'ht', langName: 'Creole' },
  { langKey: 'sk', langName: 'Slovenský' },
  { langKey: 'bg', langName: 'български' },
  { langKey: 'ro', langName: 'Român' },
  { langKey: 'ar', langName: 'العربية' },
] as const

export const PRIMARY_LANGUAGES = [
  PLATFORM_LANGUAGES[0],
  PLATFORM_LANGUAGES[1],
] as const

export type PlatformLanguage = (typeof PLATFORM_LANGUAGES)[number]['langKey']
export type PrimaryLanguage = (typeof PRIMARY_LANGUAGES)[number]['langKey']
