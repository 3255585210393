import axios from 'axios'

import { type Organization } from '~/utils/types/user'

import { type ServerResponse } from './../axios'

export interface OrganizationListItem {
  id: number
  name: string
  description: string
  active: boolean
  logoUrlList: string[]
  supportEmail: null | string
  hasSubOrganizations: boolean
}

export async function listOrganizations({
  hubId,
  orgId,
  orgName,
}: {
  hubId: number | null
  orgId: number | null
  orgName: string | null
}) {
  const query = /* GraphQL */ `
    query ListOrganizations($orgName: String, $hubId: Long, $orgId: Long) {
      listOrganizations(
        filter: $orgName
        hubFilter: $hubId
        orgFilter: $orgId
      ) {
        id
        name
        description
        active
        logoUrlList
        supportEmail
      }
    }
  `

  return await axios<
    ServerResponse<{ listOrganizations: OrganizationListItem[] }>
  >({
    url: '/graphql',
    method: 'post',
    data: {
      query,
      variables: { hubId, orgId, orgName },
    },
  })
}

export interface AllOrgsWithSubOrg {
  id: number
  name: string
}

export async function getOrganizationsWithSubOrgs(hubId: number) {
  const query = /* GraphQL */ `
    query AllOrgsWithSubOrgs($hubId: Long!) {
      allOrgsWithSubOrgs(hubFilter: $hubId) {
        id
        name
      }
    }
  `

  return await axios<
    ServerResponse<{ allOrgsWithSubOrgs: AllOrgsWithSubOrg[] }>
  >({
    method: 'post',
    url: '/graphql',
    data: { query, variables: { hubId } },
  })
}

export async function getOrganizationAreaTypes() {
  interface OrganizationAreaType {
    code: string
    description: string
  }

  return await axios<
    ServerResponse<{ organizationAreasTypes: OrganizationAreaType[] }>
  >({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query: 'query { organizationAreasTypes {code, description } }',
    }),
  })
}

export async function getOrganizationFinalUserTypes() {
  interface OrganizationFinalUserType {
    code: string
    description: string
  }

  return await axios<
    ServerResponse<{ organizationFinalUserTypes: OrganizationFinalUserType[] }>
  >({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query: 'query { organizationFinalUserTypes {code, description } }',
    }),
  })
}

export async function getOrganization(organizationId: number) {
  return await axios<Organization>({
    method: 'get',
    url: `/api/v1/organizations/${organizationId}`,
  })
}

export async function getOrganizationTypes() {
  interface OrganizationType {
    code: string
    description: string
  }

  return await axios<
    ServerResponse<{ organizantionTypes: OrganizationType[] }>
  >({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query: 'query { organizantionTypes {code, description } }',
    }),
  })
}

export async function organizationsByHub(hubId: number | null) {
  interface OrganizationsByHub {
    id: number
    name: string
  }

  return await axios<ServerResponse<{ organizations: OrganizationsByHub[] }>>({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query: `query { organizations (hub:${hubId}) {id, name} }`,
    }),
  })
}

export async function getOrganizationEndSurveyTypes() {
  interface OrganizationEndSurveyType {
    code: string
    description: string
  }

  return await axios<
    ServerResponse<{ organizationEndSurveyTypes: OrganizationEndSurveyType[] }>
  >({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query: 'query { organizationEndSurveyTypes {code, description } }',
    }),
  })
}
