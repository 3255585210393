import {
  ROLES,
  canAccess,
  checkAccessToProjects,
  checkAccessToSolution,
} from './role-utils'
import { type Permission, type Role, type User } from './types/user'

export const PERMISSIONS = {
  DASHBOARD_READ: 'dashboard_read',
  DIMENSIONS_DELETE: 'dimensions_delete',
  DIMENSIONS_READ: 'dimensions_read',
  DIMENSIONS_WRITE: 'dimensions_write',
  ECONOMICS_DELETE: 'economics_delete',
  ECONOMICS_READ: 'economics_read',
  ECONOMICS_WRITE: 'economics_write',
  FAMILIES_DELETE: 'families_delete',
  FAMILIES_MERGE: 'families_merge',
  FAMILIES_MOVE: 'families_move',
  FAMILIES_READ: 'families_read',
  FAMILIES_VIEW: 'families_view',
  FAMILIES_WRITE: 'families_write',
  HUBS_DELETE: 'hubs_delete',
  HUBS_READ: 'hubs_read',
  HUBS_VIEW: 'hubs_view',
  HUBS_WRITE: 'hubs_write',
  INDICATORS_DELETE: 'indicators_delete',
  INDICATORS_READ: 'indicators_read',
  INDICATORS_WRITE: 'indicators_write',
  MAP_READ: 'map_read',
  OFFLINE_MAPS_DELETE: 'offline-maps_delete',
  OFFLINE_MAPS_READ: 'offline-maps_read',
  OFFLINE_MAPS_VIEW: 'offline-maps_view',
  OFFLINE_MAPS_WRITE: 'offline-maps_write',
  ORGANIZATIONS_DELETE: 'organizations_delete',
  ORGANIZATIONS_READ: 'organizations_read',
  ORGANIZATIONS_VIEW: 'organizations_view',
  ORGANIZATIONS_WRITE: 'organizations_write',
  PROJECTS_DELETE: 'projects_delete',
  PROJECTS_READ: 'projects_read',
  PROJECTS_VIEW: 'projects_view',
  PROJECTS_WRITE: 'projects_write',
  REPORTS_READ: 'reports_read',
  SOLUTIONS_DELETE: 'solutions_delete',
  SOLUTIONS_READ: 'solutions_read',
  SOLUTIONS_WRITE: 'solutions_write',
  STAKEHOLDERS_DELETE: 'stakeholders_delete',
  STAKEHOLDERS_READ: 'stakeholders_read',
  STAKEHOLDERS_WRITE: 'stakeholders_write',
  SURVEYS_LIST_ASSIGN: 'surveysList_assign',
  SURVEYS_LIST_DELETE: 'surveysList_delete',
  SURVEYS_LIST_MIGRATE: 'surveysList_migrate',
  SURVEYS_LIST_READ: 'surveysList_read',
  SURVEYS_LIST_WRITE: 'surveysList_write',
  USERS_DELETE: 'users_delete',
  USERS_READ: 'users_read',
  USERS_WRITE: 'users_write',
} as const

// Change permissionItems and getPermissionsPerRole together
export const permissionsItems = [
  { item: 'reports' },
  { item: 'allies', options: ['hubs', 'stakeholders'] },
  { item: 'surveysList' },
  { item: 'organizations' },
  { item: 'users' },
  { item: 'families' },
  { item: 'projects' },
  { item: 'map' },
  { item: 'solutions' },
  { item: 'libraries', options: ['dimensions', 'indicators', 'economics'] },
]

export function getHomePagePerPermissions(permissions: Permission[]) {
  const hasAccess = (item: string) =>
    permissions.some(permission => permission === `${item}_read`)

  let optionResult = ''
  const resultItem = [{ item: 'dashboard' }, ...permissionsItems].find(item => {
    if (Array.isArray(item.options)) {
      item.options.forEach(option => {
        if (hasAccess(option)) {
          optionResult = !optionResult ? option : optionResult
          return true
        }
      })
    }
    return hasAccess(item.item)
  })
  const item = resultItem ? resultItem.item : ''
  return optionResult || item
}

export function getPermissionsPerRole(user: User, withSubPermissions = false) {
  const role = user.role

  let permissionsList: Permission[] = []

  // In this  section we grant all permission that don't require organization's level access
  if (role === ROLES.ADMIN) {
    permissionsList = [
      PERMISSIONS.HUBS_READ,
      PERMISSIONS.HUBS_WRITE,
      PERMISSIONS.HUBS_DELETE,
      PERMISSIONS.STAKEHOLDERS_READ,
      PERMISSIONS.STAKEHOLDERS_WRITE,
      PERMISSIONS.STAKEHOLDERS_DELETE,
      PERMISSIONS.ORGANIZATIONS_VIEW,
      PERMISSIONS.OFFLINE_MAPS_VIEW,
      PERMISSIONS.FAMILIES_READ,
      PERMISSIONS.FAMILIES_VIEW,
      PERMISSIONS.FAMILIES_DELETE,
      PERMISSIONS.FAMILIES_MERGE,
      PERMISSIONS.FAMILIES_MOVE,
      PERMISSIONS.REPORTS_READ,
      PERMISSIONS.MAP_READ,
      PERMISSIONS.SURVEYS_LIST_READ,
      PERMISSIONS.SURVEYS_LIST_WRITE,
      PERMISSIONS.SURVEYS_LIST_DELETE,
      PERMISSIONS.SURVEYS_LIST_ASSIGN,
      PERMISSIONS.SURVEYS_LIST_MIGRATE,
      PERMISSIONS.USERS_READ,
      PERMISSIONS.USERS_WRITE,
      PERMISSIONS.USERS_DELETE,
      PERMISSIONS.DASHBOARD_READ,
      PERMISSIONS.DIMENSIONS_READ,
      PERMISSIONS.DIMENSIONS_WRITE,
      PERMISSIONS.DIMENSIONS_DELETE,
      PERMISSIONS.INDICATORS_READ,
      PERMISSIONS.INDICATORS_WRITE,
      PERMISSIONS.INDICATORS_DELETE,
      PERMISSIONS.ECONOMICS_READ,
      PERMISSIONS.ECONOMICS_WRITE,
      PERMISSIONS.ECONOMICS_DELETE,
    ]
  }

  if (role === ROLES.PS_TEAM) {
    permissionsList = [
      PERMISSIONS.HUBS_READ,
      PERMISSIONS.HUBS_WRITE,
      PERMISSIONS.HUBS_DELETE,
      PERMISSIONS.STAKEHOLDERS_READ,
      PERMISSIONS.STAKEHOLDERS_WRITE,
      PERMISSIONS.STAKEHOLDERS_DELETE,
      PERMISSIONS.ORGANIZATIONS_VIEW,
      PERMISSIONS.OFFLINE_MAPS_VIEW,
      PERMISSIONS.REPORTS_READ,
      PERMISSIONS.MAP_READ,
      PERMISSIONS.SURVEYS_LIST_READ,
      PERMISSIONS.SURVEYS_LIST_WRITE,
      PERMISSIONS.SURVEYS_LIST_ASSIGN,
      PERMISSIONS.USERS_READ,
      PERMISSIONS.USERS_WRITE,
      PERMISSIONS.USERS_DELETE,
      PERMISSIONS.DASHBOARD_READ,
      PERMISSIONS.DIMENSIONS_READ,
      PERMISSIONS.DIMENSIONS_WRITE,
      PERMISSIONS.INDICATORS_READ,
      PERMISSIONS.INDICATORS_WRITE,
      PERMISSIONS.ECONOMICS_READ,
      PERMISSIONS.ECONOMICS_WRITE,
    ]
  }

  if (role === ROLES.HUB_ADMIN) {
    permissionsList = [
      PERMISSIONS.ORGANIZATIONS_READ,
      PERMISSIONS.ORGANIZATIONS_WRITE,
      PERMISSIONS.ORGANIZATIONS_DELETE,
      PERMISSIONS.OFFLINE_MAPS_READ,
      PERMISSIONS.OFFLINE_MAPS_WRITE,
      PERMISSIONS.OFFLINE_MAPS_DELETE,
      PERMISSIONS.FAMILIES_READ,
      PERMISSIONS.FAMILIES_VIEW,
      PERMISSIONS.FAMILIES_DELETE,
      PERMISSIONS.FAMILIES_MOVE,
      PERMISSIONS.REPORTS_READ,
      PERMISSIONS.MAP_READ,
      PERMISSIONS.SURVEYS_LIST_READ,
      PERMISSIONS.SURVEYS_LIST_ASSIGN,
      PERMISSIONS.USERS_READ,
      PERMISSIONS.USERS_WRITE,
      PERMISSIONS.USERS_DELETE,
      PERMISSIONS.DASHBOARD_READ,
    ]
  }

  if (role === ROLES.ORGANIZATION_ADMIN) {
    permissionsList = [
      PERMISSIONS.FAMILIES_READ,
      PERMISSIONS.FAMILIES_VIEW,
      PERMISSIONS.FAMILIES_WRITE,
      PERMISSIONS.REPORTS_READ,
      PERMISSIONS.MAP_READ,
      PERMISSIONS.SURVEYS_LIST_READ,
      PERMISSIONS.USERS_READ,
      PERMISSIONS.USERS_WRITE,
      PERMISSIONS.USERS_DELETE,
      PERMISSIONS.DASHBOARD_READ,
    ]
  }

  if (role === ROLES.FACILITATOR_ADMIN) {
    permissionsList = [
      PERMISSIONS.FAMILIES_READ,
      PERMISSIONS.FAMILIES_VIEW,
      PERMISSIONS.FAMILIES_WRITE,
      PERMISSIONS.MAP_READ,
      PERMISSIONS.USERS_READ,
      PERMISSIONS.USERS_WRITE,
      PERMISSIONS.USERS_DELETE,
      PERMISSIONS.DASHBOARD_READ,
    ]
  }

  if (role === ROLES.FACILITATOR) {
    permissionsList = [
      PERMISSIONS.FAMILIES_READ,
      PERMISSIONS.FAMILIES_VIEW,
      PERMISSIONS.FAMILIES_WRITE,
      PERMISSIONS.MAP_READ,
    ]
  }

  // Here we grant permissions to modules that requires organization level access
  // TODO: check access to Family Users and Interventions modules
  if (checkAccessToSolution(user)) {
    permissionsList.push(
      PERMISSIONS.SOLUTIONS_READ,
      PERMISSIONS.SOLUTIONS_WRITE,
      PERMISSIONS.SOLUTIONS_DELETE,
    )
  }

  if (checkAccessToProjects(user)) {
    if (
      role === ROLES.ADMIN ||
      role === ROLES.PS_TEAM ||
      role === ROLES.HUB_ADMIN
    ) {
      permissionsList.push(PERMISSIONS.PROJECTS_VIEW)
      withSubPermissions &&
        permissionsList.push(
          ...[
            PERMISSIONS.PROJECTS_READ,
            PERMISSIONS.PROJECTS_WRITE,
            PERMISSIONS.PROJECTS_DELETE,
          ],
        )
    }
    if (role === ROLES.ORGANIZATION_ADMIN) {
      permissionsList.push(
        ...[
          PERMISSIONS.PROJECTS_READ,
          PERMISSIONS.PROJECTS_WRITE,
          PERMISSIONS.PROJECTS_DELETE,
        ],
      )
    }
    if (
      role === ROLES.FACILITATOR ||
      role === ROLES.FACILITATOR_ADMIN ||
      role === ROLES.SURVEY_TAKER
    ) {
      permissionsList.push(PERMISSIONS.PROJECTS_VIEW)
    }
  }

  if (withSubPermissions) {
    if (role === ROLES.ADMIN || role === ROLES.PS_TEAM) {
      permissionsList = [
        ...permissionsList,
        PERMISSIONS.ORGANIZATIONS_READ,
        PERMISSIONS.ORGANIZATIONS_WRITE,
        PERMISSIONS.ORGANIZATIONS_DELETE,
        PERMISSIONS.OFFLINE_MAPS_READ,
        PERMISSIONS.OFFLINE_MAPS_WRITE,
        PERMISSIONS.OFFLINE_MAPS_DELETE,
      ]
    }
    if (role === ROLES.ADMIN) {
      permissionsList = [...permissionsList, PERMISSIONS.FAMILIES_WRITE]
    }
  }

  const permissionsByModules = permissionListToObject(permissionsList)
  return permissionsByModules
}

export function permissionListToObject(permissionsList: Permission[]) {
  const permissionsByModules: Record<string, Record<string, boolean>> = {}

  permissionsList.forEach(rawPermission => {
    const [module, permission] = rawPermission.split('_')

    const modulesPermissions = permissionsByModules[module] || {}
    permissionsByModules[module] = {
      ...modulesPermissions,
      [permission]: true,
    }
  })
  return permissionsByModules
}

export function objectToPermissionList(
  permissions: Record<string, Record<string, boolean>>,
): Permission[] {
  const moduleWithAccess = Object.entries(permissions).map(
    ([module, access]) => {
      return {
        module,
        access: Object.keys(access).filter(a => !!access[a]),
      }
    },
  )

  const result = moduleWithAccess.reduce<Permission[]>((acc, curr) => {
    const permissions = curr.access.map(a => `${curr.module}_${a}`)

    const validPermissions = Object.values(PERMISSIONS).filter(value =>
      permissions.includes(value),
    )

    acc = [...acc, ...validPermissions]
    return acc
  }, [])

  return result
}

export function canReadOrView(
  user: User,
  view: string,
  defaultRoles: Role[] = [],
) {
  return (
    canPerform(user, view, 'read', defaultRoles) ||
    canPerform(user, view, 'view', defaultRoles)
  )
}

export function canPerform(
  user: User,
  view: string,
  permission: string,
  defaultRoles: Role[] = [],
) {
  const { permissions, role } = user || {}
  const hasPermission = Array.isArray(permissions)
  if (hasPermission) {
    return canAccess(user, view, permission)
  } else {
    return defaultRoles.some(r => r === role)
  }
}
