import { type PayloadAction, createSlice } from '@reduxjs/toolkit'

import { type User } from '~/utils/types/user'

const backupUserSlice = createSlice({
  name: 'backupUser',
  initialState: null as User | null,
  reducers: {
    updateBackupUser: (_state, action: PayloadAction<User | null>) =>
      action.payload,
  },
})

export const { updateBackupUser } = backupUserSlice.actions

export default backupUserSlice.reducer
