import { countries } from 'countries-list'

import { type PlatformLanguage } from '~/utils/types/i18n'

export function useLocalizedCountries(locale: PlatformLanguage | string) {
  const countryKeys = useRef(Object.keys(countries))

  const localizedCountries = useMemo(() => {
    const sanitizedLocale = locale.replaceAll('_', '-')

    const regionNames = new Intl.DisplayNames([sanitizedLocale], {
      type: 'region',
    })

    const countryNames = countryKeys.current.map(code => {
      const localizedName = regionNames.of(code)

      if (!localizedName) {
        throw new Error(`Invalid country code "${code}" provided`)
      }

      return { code, label: localizedName }
    })

    return countryNames
  }, [locale])

  return localizedCountries
}
