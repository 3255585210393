import { mapToBackendLanguageWithFallback } from '~/axios'

import { PLATFORM_LANGUAGES } from './types/i18n'

export const getLanguageByCode = (langCode = '') =>
  langCode.includes('ht') ? 'fr' : langCode

export const normalizeLanguages = (lang: string) => {
  const shortLang = lang ? lang.substring(0, 2) : 'en'
  const languages = {
    en: 'en',
    es: 'es',
    pt: 'pt',
    ht: 'ht',
    br: 'br',
  }
  return languages[shortLang] || languages.en
}

export const languageOptions = PLATFORM_LANGUAGES.map(option => ({
  label: option.langName,
  // NOTE: we don't need to have a fallback here, in theory we're sure that we
  // have the right locale but we don't have that kind of helper yet and we
  // need to create a standard with our backend first to make the right
  // abstractions
  value: mapToBackendLanguageWithFallback(option.langKey),
}))
