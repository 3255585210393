// Third Party
import { zodResolver } from '@hookform/resolvers/zod'
import { Edit as EditIcon } from '@mui/icons-material'
import { Button, CircularProgress, TextField, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { type TFunction } from 'i18next'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { z } from 'zod'

import iconAch from '~/assets/imgAch.png'
import BottomSpacer from '~/components/BottomSpacer'
import Container from '~/components/Container'
import TitleBar from '~/components/TitleBar'
import { useAppDispatch, useAppSelector } from '~/redux/hooks'
import { updateDraft } from '~/redux/slices/currentDraft'
import { COLORS } from '~/theme'
import { isTimerNum, remakeTimers, screenTimer } from '~/utils/survey-utils'
import {
  type LifemapQuestionType,
  type SurveyLifemapQuestion,
} from '~/utils/types/surveys'

type PriorityTranslations = Extract<
  LifemapQuestionType,
  'ACHIEVEMENT_ACTION' | 'ACHIEVEMENT_ROADMAP'
>

function getQuestionTitles(
  t: TFunction,
  questions: SurveyLifemapQuestion[],
  key: PriorityTranslations,
) {
  const translations: { [key in PriorityTranslations]: string } = {
    ACHIEVEMENT_ACTION: t('views.lifemap.whatDidItTakeToAchieveThis'),
    ACHIEVEMENT_ROADMAP: t('views.lifemap.howDidYouGetIt'),
  }

  const label =
    questions.find(q => q.questionType === key)?.questionText ??
    translations[key]

  return label
}

export default function Achievements() {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const params = useParams()
  const { indicator } = params

  const [imageStatus, setImageStatus] = useState('loading')

  const currentDraft = useAppSelector(state => {
    if (!state.currentDraft) throw new Error('No currentDraft')
    return state.currentDraft
  })
  const currentSurvey = useAppSelector(state => {
    if (!state.currentSurvey) throw new Error('No currentSurvey')
    return state.currentSurvey
  })

  const { lifemapQuestions } = currentSurvey
  const question = currentSurvey.surveyStoplightQuestions.find(
    q => q.codeName === indicator,
  )

  const achievement = currentDraft.achievements.find(
    achievement => achievement.indicator === indicator,
  )

  const validationSchema = z.object({
    achievementAction: z
      .string()
      .min(10, t('validation.greaterLength', { min: 10 })),
    achievementRoadmap: z.string().optional(),
  })

  type FormValues = z.infer<typeof validationSchema>

  useEffect(() => {
    if (!question) {
      navigate(-1)
    }
  }, [])

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<FormValues>({
    mode: 'all',
    resolver: zodResolver(validationSchema),
    defaultValues: {
      achievementAction: achievement?.action,
      achievementRoadmap: achievement?.roadmap,
    },
  })

  const stoplightAnswer = currentDraft.indicatorSurveyDataList.find(
    answers => answers.key === question?.codeName,
  )
  const stoplightColor = question?.stoplightColors.find(
    e => e.value === stoplightAnswer?.value,
  )

  let color = ''
  let textColor = 'white'

  if (stoplightColor?.value === 3) {
    color = COLORS.GREEN
  } else if (stoplightColor?.value === 2) {
    color = COLORS.YELLOW
    textColor = 'black'
  } else if (stoplightColor?.value === 1) {
    color = COLORS.RED
  }

  const saveAchievement = (values: FormValues) => {
    const newAchievement = {
      action: values.achievementAction,
      indicator: question?.codeName ?? '',
      roadmap: values.achievementRoadmap ?? '',
    }

    const item = currentDraft.achievements.filter(
      achievement => achievement.indicator === question?.codeName,
    )[0]

    // If the item exists
    if (item) {
      const index = currentDraft.achievements.indexOf(item)
      dispatch(
        updateDraft({
          ...currentDraft,
          achievements: [
            ...currentDraft.achievements.slice(0, index),
            newAchievement,
            ...currentDraft.achievements.slice(index + 1),
          ],
        }),
      )
    } else {
      dispatch(
        updateDraft({
          ...currentDraft,
          achievements: [...currentDraft.achievements, newAchievement],
        }),
      )
    }
    navigate(-1)
  }

  useEffect(() => {
    let updatedDraft = { ...currentDraft }

    if (isTimerNum(updatedDraft)) {
      const remakedTimers = remakeTimers(updatedDraft)
      updatedDraft = { ...updatedDraft, ...remakedTimers }
    }
    const screenTimes = screenTimer(updatedDraft, 'Overview')
    dispatch(updateDraft({ ...updatedDraft, ...screenTimes }))
  }, [])

  return (
    <div>
      <TitleBar
        title={question?.questionText}
        extraTitleText={question?.dimension}
        progressBar={false}
      />
      <>
        <div className={classes.imgAndDescriptionContainer}>
          <div
            className={classes.imageContainer}
            style={{ backgroundColor: color }}
          >
            <>
              {imageStatus === 'loading' && (
                <div className={classes.loadingContainer}>
                  <div className={classes.loadingIndicatorCenter}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {' '}
                      <CircularProgress
                        color="inherit"
                        className={classes.circularProgress}
                      />
                    </div>
                    <img
                      onLoad={() => {
                        setImageStatus('loaded')
                      }}
                      style={{ display: 'none' }}
                      src={stoplightColor?.url}
                      alt=""
                    />
                  </div>
                </div>
              )}
              {imageStatus !== 'loading' && (
                <img
                  className={classes.imgClass}
                  src={stoplightColor?.url}
                  alt="surveyImg"
                />
              )}
            </>
          </div>
          <div
            className={classes.paragraphContainer}
            style={{ backgroundColor: color }}
          >
            <div className={classes.answeredQuestion}>
              <i
                style={{
                  backgroundColor: color,
                }}
                className={`${classes.icon} material-icons`}
              >
                done
              </i>
            </div>
            <div className={classes.editContainer}>
              <EditIcon className={classes.editIcon} />
            </div>
            <Typography
              variant="body2"
              align="center"
              paragraph
              className={classes.paragraphTypography}
              style={{ color: textColor }}
            >
              {stoplightColor?.description}
            </Typography>
          </div>
        </div>

        <div className={classes.pinAndPriority}>
          <img style={{ height: 55 }} src={iconAch} alt="icon" />
          <Typography variant="h5" align="center" style={{ marginTop: '10px' }}>
            {t('views.lifemap.markAchievement')}
          </Typography>
        </div>
        <Container variant="slim">
          <form
            noValidate
            className={classes.formContainer}
            // eslint-disable-next-line
            onSubmit={handleSubmit(saveAchievement)}
          >
            <TextField
              required
              fullWidth
              variant="standard"
              autoComplete="off"
              error={!!errors.achievementAction?.message}
              helperText={errors.achievementAction?.message}
              label={getQuestionTitles(
                t,
                lifemapQuestions,
                'ACHIEVEMENT_ACTION',
              )}
              {...register('achievementAction')}
            />

            <TextField
              fullWidth
              variant="standard"
              autoComplete="off"
              label={getQuestionTitles(
                t,
                lifemapQuestions,
                'ACHIEVEMENT_ROADMAP',
              )}
              {...register('achievementRoadmap')}
            />
            <div className={classes.buttonContainerForm}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={isSubmitting}
              >
                {t('general.save')}
              </Button>
            </div>
          </form>
        </Container>
        <BottomSpacer />
      </>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  imageContainer: { display: 'flex', position: 'inherit', width: '100%' },
  loadingContainer: { position: 'absolute', top: '50%', left: '50%' },
  circularProgress: { color: 'white' },
  icon: {
    color: 'white',
    fontSize: 39,
    height: 80,
    width: 80,
    margin: 'auto',
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingIndicatorCenter: {
    left: -20,
    bottom: -20,
    position: 'absolute',
  },
  editContainer: {
    position: 'absolute',
    top: '20px',
    right: '20px',
    display: 'none', // TODO Hidding edit button, feature not implemented yet
  },
  editIcon: {
    fontSize: 24,
    color: 'white',
  },
  answeredQuestion: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    left: '0%',
    transform: 'translate(-50%,-50%)',
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      top: '0%',
      left: '50%',
    },
  },
  pinAndPriority: {
    marginTop: '40px',
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  imgClass: {
    width: '100%',
    height: '307px',
    minHeight: '100%',
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      height: 185,
    },
  },
  paragraphContainer: {
    position: 'relative',
    margin: '0px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    minHeight: '307px',
    [theme.breakpoints.down('sm')]: {
      minHeight: 185,
    },
  },
  paragraphTypography: {
    fontSize: 16,
    zIndex: 1,
    padding: '30px 30px 30px 40px',
    marginBottom: 0,
  },
  imgAndDescriptionContainer: {
    position: 'relative',
    display: 'flex',
    width: '614px',
    margin: 'auto',
    marginTop: '30px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%',
    },
  },
  buttonContainerForm: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 40,
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    gap: theme.spacing(5),
  },
}))
