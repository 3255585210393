import { Home, Refresh } from '@mui/icons-material'
import { Box, Button, ButtonGroup, Stack, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { useAppSelector } from '~/redux/hooks'
import { getHomePage } from '~/utils/role-utils'

export default function ErrorPage() {
  const { t } = useTranslation()
  const user = useAppSelector(state => {
    if (!state.user) throw new Error('No user')
    return state.user
  })

  const navigate = useNavigate()
  const userHomePage = useMemo(() => getHomePage(user), [user])

  return (
    <Box
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Stack spacing={2} justifyContent="center" alignItems="center">
        <Typography
          variant="h4"
          sx={{
            fontSize: 120,
            fontWeight: 600,
            color: theme => theme.palette.grey[500],
          }}
        >
          {'Oops!'}
        </Typography>
        <Typography variant="h5">{`${t('views.error.subtitle')} `}</Typography>

        <ButtonGroup
          variant="outlined"
          sx={{ justifyContent: 'center' }}
          aria-label="outlined button group"
        >
          <Button
            onClick={() => {
              navigate(0)
            }}
            endIcon={<Refresh />}
          >
            {t('views.error.refreshPage')}
          </Button>
          <Button
            onClick={() => {
              navigate(`/${userHomePage}`)
            }}
            endIcon={<Home />}
          >
            {t('views.error.toHomePage')}
          </Button>
        </ButtonGroup>
      </Stack>
    </Box>
  )
}
