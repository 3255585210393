import { FormHelperText, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { connect } from 'react-redux'
import Select from 'react-select'

import { supportedLanguages } from '../../api'
import { selectStyle } from '../../utils/styles-utils'

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 20,
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
      marginBottom: 10,
      marginTop: 0,
    },
  },
  withTitleContainer: {
    margin: '5px 0',
  },
  label: {
    marginBottom: 5,
    fontSize: 14,
  },
  selector: {
    width: '100%',
  },
}))

const LanguageSelector = ({
  withTitle,
  user,
  languageData,
  onChangeLanguage,
  onBlur,
  required,
  isClearable,
  isDisabled,
  parentLang,
  error,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const classes = useStyles()

  const [loading, setLoading] = useState(false)
  const [languagesOptions, setLanguagesOptions] = useState([])
  const label = `${t('views.languageFilter.label')} ${required ? ' *' : ''}`

  useEffect(() => {
    setLoading(true)
    supportedLanguages()
      .then(response => {
        const formatedLanguages = response.data.data.supportedLanguages.map(
          l => ({
            label: l.description,
            value: l.code,
          }),
        )
        setLanguagesOptions(formatedLanguages)
        setLoading(false)
      })
      .catch(e => {
        console.log(e)
      })
  }, [language, parentLang, user])

  useEffect(() => {
    // If we only recieve the value as prop
    if (
      languageData &&
      !languageData.hasOwnProperty('value') &&
      !!languagesOptions.length
    ) {
      const data = languagesOptions.find(o => o.value === languageData)
      onChangeLanguage(data)
    }
  }, [
    languageData,
    languagesOptions,
    languagesOptions.length,
    onChangeLanguage,
  ])

  return (
    <div
      className={clsx(
        classes.container,
        withTitle && classes.withTitleContainer,
      )}
    >
      {withTitle && (
        <Typography variant="subtitle1" className={classes.label}>
          {label}
        </Typography>
      )}

      <div className={classes.selector}>
        <Select
          value={languageData}
          onChange={value => onChangeLanguage(value)}
          onBlur={onBlur}
          placeholder={withTitle ? '' : label}
          isLoading={loading}
          loadingMessage={() => t('views.languageFilter.loading')}
          noOptionsMessage={() => t('views.languageFilter.noOption')}
          options={languagesOptions}
          components={{
            DropdownDimension: () => <div />,
            DimensionSeparator: () => <div />,
            ClearDimension: () => <div />,
          }}
          styles={selectStyle}
          closeMenuOnSelect={true}
          isClearable={isClearable}
          isDisabled={isDisabled}
          hideSelectedOptions
          maxMenuHeight={150}
        />
      </div>
      {error && (
        <FormHelperText error={error}>
          {t('validation.fieldIsRequired')}
        </FormHelperText>
      )}
    </div>
  )
}

const mapStateToProps = ({ user }) => ({ user })

/**
 * @deprecated create a custom implementation within the component instead of using this one.
 */
export default connect(mapStateToProps)(LanguageSelector)
