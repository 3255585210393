import axios from 'axios'

import i18n from './i18n'
import store from './redux'
import { type PlatformLanguage } from './utils/types/i18n'

export const serverHost = import.meta.env.VITE_API_URL

axios.defaults.baseURL = serverHost
axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8'
axios.defaults.headers.post['Stoplight-Client-Id'] = 'stoplight-web'

axios.interceptors.request.use(
  config => {
    const { user } = store.getState()

    if (user?.token) {
      config.headers.Authorization = `Bearer ${user.token}`
    }

    return config
  },
  async error => await Promise.reject(error),
)

axios.interceptors.request.use(
  config => {
    if (!config.headers['X-locale']) {
      config.headers['X-locale'] = mapToBackendLanguageWithFallback(
        i18n.language as PlatformLanguage,
      )
    }

    return config
  },
  async error => await Promise.reject(error),
)

// NOTE: This is a workaround for the `shouldCreateUserBeforeSurveyFlow` flag
const cleanAxios = axios.create()
cleanAxios.interceptors.request.clear()

export { axios, cleanAxios }

export function normalizeToPrimaryLanguages(language: PlatformLanguage) {
  return language === 'en' ? 'en_US' : 'es_PY'
}

export function mapToBackendLanguageWithFallback(language: PlatformLanguage) {
  type Languages = {
    [key in PlatformLanguage]: string
  }
  const languages = {
    en: 'en_US',
    es: 'es_PY',
    pt: 'pt_PT',
    'pt-BR': 'pt_BR',
    ht: 'ht_HT',
    sk: 'sk_SK',
    bg: 'bg_BG',
    ro: 'ro_RO',
    ar: 'ar_AR',
  } as const satisfies Languages

  return languages[language] || languages.en
}

export function mapToFrontendLanguageWithFallback(language: string) {
  type Languages = {
    [key in string]: PlatformLanguage
  }
  const languages: Languages = {
    en_US: 'en',
    es_PY: 'es',
    pt_BR: 'pt-BR',
    pt_PT: 'pt',
    ht_HT: 'ht',
    sk_SK: 'sk',
    bg_BG: 'bg',
    ro_RO: 'ro',
    ar_AR: 'ar',
  } as const

  return languages[language] || 'en'
}

/**
 * This interface is used as a generic type for axios' responses
 */
export interface ServerResponse<T> {
  data: T
  errors: any[]
  dataPresent: boolean
  extensions: null
}
