import { Box, Button, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'

// Project
import SwitchInput from '~/components/SwitchInput'
import SearchTextFilter from '~/components/filters/SearchTextFilter'
import CountrySelector from '~/components/selectors/CountrySelector'
import GenericSelector from '~/components/selectors/GenericSelector'
import LanguageSelector from '~/components/selectors/LanguageSelector'

const useStyles = makeStyles(() => ({
  verifiedContainer: {
    '& label': {
      marginTop: 0,
    },
  },
}))

const EconomicLibraryFilters = ({
  options,
  loading,
  filterInput,
  setFilterInput,
  handleCreate,
  showManagementFeatures,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const classes = useStyles()

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <SearchTextFilter
            searchLabel={t('views.indicator.filter.search')}
            searchByLabel={t('views.indicator.filter.searchBy')}
            value={filterInput.filter}
            onChange={newValue => setFilterInput({ filter: newValue })}
          />
        </Grid>

        <Grid
          item
          md={3}
          sm={3}
          xs={12}
          container
          alignItems="flex-start"
          justifyContent="center"
          className={classes.verifiedContainer}
        >
          <SwitchInput
            label={t('views.indicator.create.verified')}
            value={filterInput.verified}
            onChange={() => setFilterInput({ verified: !filterInput.verified })}
          />
        </Grid>

        {showManagementFeatures && (
          <Grid
            item
            md={3}
            sm={3}
            xs={12}
            container
            alignItems="center"
            justifyContent="flex-end"
          >
            <Box mb={2}>
              <Button
                color="primary"
                variant="contained"
                onClick={handleCreate}
              >
                {t('views.economic.form.create')}
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>

      <Grid container spacing={2}>
        <Grid item md={3} sm={6} xs={12}>
          <LanguageSelector
            withTitle
            languagesData={filterInput.selectedLanguage}
            parentLang={language}
            onChangeLanguage={selectedLanguage =>
              setFilterInput({ selectedLanguage })
            }
            isClearable
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <CountrySelector
            withTitle
            countryData={filterInput.country}
            onChangeCountry={country => setFilterInput({ country })}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <GenericSelector
            label={t('views.indicator.filter.measurement')}
            withTitle
            value={filterInput.measurementUnit}
            options={options.measurementUnits}
            isLoading={loading}
            onChange={measurementUnit => setFilterInput({ measurementUnit })}
            isClearable
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12} container>
          <GenericSelector
            label={t('views.indicator.filter.stoplights')}
            withTitle
            value={filterInput.stoplightType}
            options={options.stoplights}
            onChange={stoplightType => setFilterInput({ stoplightType })}
            isClearable
          />
        </Grid>
      </Grid>
    </>
  )
}

export default EconomicLibraryFilters
