import React from 'react'

import { Box, Button, Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import propTypes from 'prop-types'

import DefaultOrgLogo from '../../assets/grey_isologo.png'

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingTop: '1rem',
    height: '100%',
    '& $p': {
      fontSize: '14px',
      color: theme.palette.grey[600],
      marginBottom: 7,
    },
    '& $p:last-child': {
      marginBottom: 0,
    },
  },
  titleContainer: {
    height: '20%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    paddingLeft: 17,
    paddingRight: 17,
  },
  titleStyle: {
    color: theme.palette.primary.dark,
    lineHeight: 1.2,
    fontSize: '18px',
    marginRight: 'auto',
    marginBottom: 7,
    fontWeight: theme.typography.fontWeightMedium,
  },
  logoContainer: {
    height: 130,
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
  },
  logoImage: {
    maxWidth: 130,
    maxHeight: 130,
    padding: 10,
    margin: 'auto',
  },
  actionsContainer: {
    display: 'flex',
    height: 50,
    padding: '5px 17px 5px 5px',
    position: 'relative',
  },
  button: {
    borderRadius: '0%',
    fontSize: 14,
    padding: 0,
    marginRight: 5,
    justifyContent: 'center',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      textDecoration: 'none',
    },
  },
  descriptionContainer: {
    height: 80,
    padding: '0 17px',
    marginTop: 5,
  },
}))

const Card = ({
  readOnly,
  item,
  handleEditModal,
  handleDeleteModal,
  canWrite,
  canDelete,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { name, logoUrl, description } = item
  const formattedDescription =
    description.lenght >= 80 ? `${description.slice(0, 80)}...` : description
  return (
    <Grid item xs={12} sm={4} md={3} xl={2}>
      <Box className={classes.container}>
        <Box className={classes.titleContainer}>
          <Typography variant="h6" className={classes.titleStyle}>
            {name}
          </Typography>
        </Box>
        <Box className={classes.logoContainer}>
          <img
            src={logoUrl || DefaultOrgLogo}
            alt="stakeholder logo"
            className={classes.logoImage}
          />
        </Box>
        <Box className={classes.descriptionContainer}>
          <Typography noWrap={false} variant="body2">
            {formattedDescription}
          </Typography>
        </Box>
        <Box className={classes.actionsContainer}>
          {!readOnly && (
            <>
              <Button
                arial-label="Edit"
                classes={{
                  root: classes.button,
                }}
                onClick={() => handleEditModal(item)}
              >
                {t('general.edit')}
              </Button>
              <Button
                aria-label="Delete organization"
                component="span"
                classes={{
                  root: classes.button,
                }}
                onClick={() => {
                  handleDeleteModal(item)
                }}
              >
                {t('general.delete')}
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Grid>
  )
}

Card.propTypes = {
  readOnly: propTypes.bool,
  item: propTypes.object.isRequired,
  handleEditModal: propTypes.func,
  handleDeleteModal: propTypes.func,
  canWrite: propTypes.func,
  canDelete: propTypes.func,
}

export default Card
