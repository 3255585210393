import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { Button, Divider, Menu, MenuItem, Typography } from '@mui/material'
import { useIntercom } from 'react-use-intercom'

import i18n from '~/i18n'
import { PLATFORM_LANGUAGES } from '~/utils/types/i18n'

interface PlatformLanguageSelectorProps {
  withTitle?: boolean
}

export default function PlatformLanguageSelector({
  withTitle,
}: PlatformLanguageSelectorProps) {
  const intercom = useIntercom()

  const {
    t,
    i18n: { language },
  } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const languageOptions = PLATFORM_LANGUAGES.map(lang => ({
    code: lang.langKey,
    label: lang.langName,
    action: () => {
      handleClose(lang.langKey)
    },
  }))

  const selectedLanguageLabel = languageOptions.find(
    lang => lang.code === language,
  )?.label

  function handleClose(event) {
    setIsOpen(!isOpen)
    const isSupportedLanguage = PLATFORM_LANGUAGES.some(
      langOption => langOption.langKey === event,
    )
    if (!isSupportedLanguage) return

    localStorage.setItem('language', event)

    void i18n.changeLanguage(event)
    intercom.update({ languageOverride: event })
  }

  return (
    <>
      {withTitle && (
        <Divider>
          <Typography variant="subtitle1" align="center">
            {t('views.languageFilter.label')}
          </Typography>
        </Divider>
      )}
      <Button
        endIcon={
          isOpen ? (
            <KeyboardArrowUp
              sx={{ color: theme => theme.typography.subtitle1.color }}
            />
          ) : (
            <KeyboardArrowDown
              sx={{ color: theme => theme.typography.subtitle1.color }}
            />
          )
        }
        onClick={event => {
          setIsOpen(!isOpen)
          setAnchorEl(event.currentTarget)
        }}
      >
        <Typography variant="subtitle1">{selectedLanguageLabel}</Typography>
      </Button>

      <Menu
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        slotProps={{
          paper: { sx: { bgcolor: theme => theme.palette.background.default } },
        }}
      >
        {languageOptions.map(lang => (
          <MenuItem key={lang.code} onClick={lang.action}>
            <Typography variant="subtitle1">{lang.label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
