import React from 'react'

import { Alert } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  alert: {
    fontSize: 15,
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(1),
    color: theme.palette.text.primary,
    marginTop: '1.5rem',
  },
}))

const EconomicIntroduction = ({ introduction }) => {
  const classes = useStyles()
  return (
    <React.Fragment>
      {!!introduction && (
        <Alert
          icon={<React.Fragment />}
          severity="warning"
          classes={{ root: classes.alert }}
        >
          {introduction}
        </Alert>
      )}
    </React.Fragment>
  )
}

export default EconomicIntroduction
