/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  AddPhotoAlternate as AddPhotoAlternateIcon,
  Close as CloseIcon,
} from '@mui/icons-material'
import {
  Box,
  Card,
  CardMedia,
  FormControl,
  FormHelperText,
  IconButton,
  Stack,
  Typography,
  alpha,
} from '@mui/material'
import { ErrorCode, type FileRejection, useDropzone } from 'react-dropzone'

import {
  SUPPORTED_IMAGE_MIME_TYPES,
  toReactDropzoneSchema,
} from '~/utils/allowedFileExtensions'
import { MB_SIZE_IN_BYTES, toBase64 } from '~/utils/files-utils'

interface DropImageZoneProps {
  placeholderText: string
  maxImages?: number

  images: string[]
  onChangeImages: (newFile: string[]) => void
}

const TEN_MB = MB_SIZE_IN_BYTES * 10

function isValidDropzoneErrorCode(value: string): value is ErrorCode {
  return Object.values(ErrorCode).some(errorCode => errorCode === value)
}

export default function DropImageZone({
  images,
  placeholderText,

  onChangeImages,

  maxImages = 2,
}: DropImageZoneProps) {
  const { t } = useTranslation()
  const [errorCode, setErrorCode] = useState<ErrorCode | null>(null)

  function isImageCountValid(acceptedFiles: File[]): boolean {
    const currentImagesTotal = images.length
    const acceptedImagesTotal = acceptedFiles.length

    return currentImagesTotal + acceptedImagesTotal <= maxImages
  }

  function isImageSizesValid(acceptedFiles: File[]): boolean {
    const imageSizes = acceptedFiles.map(file => file.size)
    const imageSizesTotal = imageSizes.reduce((a, b) => a + b, 0)

    return imageSizesTotal < TEN_MB
  }

  async function onDropAccepted(acceptedFiles: File[]) {
    setErrorCode(null)
    const isCountValid = isImageCountValid(acceptedFiles)
    const isSizesValid = isImageSizesValid(acceptedFiles)

    if (!isCountValid) {
      setErrorCode(ErrorCode.TooManyFiles)
      return
    }

    if (!isSizesValid) {
      setErrorCode(ErrorCode.FileTooLarge)
      return
    }

    const results = await Promise.allSettled(
      acceptedFiles.map(async file => await toBase64(file)),
    )

    const successfulResults = results.filter(
      (result): result is PromiseFulfilledResult<string> => {
        return result.status === 'fulfilled'
      },
    )

    const base64Images = successfulResults.map(result => result.value)
    onChangeImages([...images, ...base64Images])
  }

  function onDropRejected(fileRejetions: FileRejection[]) {
    const { errors } = fileRejetions[0]
    const errorCode = errors[0].code

    const errorType = isValidDropzoneErrorCode(errorCode)
      ? errorCode
      : ErrorCode.FileInvalidType

    setErrorCode(errorType)
  }

  const dropzone = useDropzone({
    onDropAccepted,
    onDropRejected,
    maxFiles: maxImages,
    multiple: maxImages > 1,
    maxSize: 10 * MB_SIZE_IN_BYTES,
    accept: toReactDropzoneSchema(SUPPORTED_IMAGE_MIME_TYPES),
  })

  function handleImageRemove(idxImageToRemove: number) {
    const filteredImages = images.filter((_img, idx) => {
      return idx !== idxImageToRemove
    })
    onChangeImages(filteredImages)
  }

  const isDropzoneActive = dropzone.isDragActive || dropzone.isFileDialogActive
  const errorMessage = useMemo(() => {
    if (!errorCode) return null

    if (errorCode === ErrorCode.TooManyFiles) {
      return t('views.dropImageZone.limitError', { maxImages })
    }

    if (errorCode === ErrorCode.FileTooLarge) {
      return t('views.dropImageZone.fileUploadError')
    }

    return t('views.dropImageZone.invalidFormatError')
  }, [errorCode, maxImages, t])

  return (
    <FormControl error={!!errorCode}>
      <Box sx={{ position: 'relative' }}>
        <Box
          sx={theme => ({
            p: 5,
            py: 5,
            borderWidth: 4,
            borderRadius: 2,
            outline: 'none',
            cursor: 'pointer',
            opacity: isDropzoneActive ? 0.5 : 1,
            transition: 'all 500ms ease-in-out',

            gap: 2,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',

            borderStyle: 'dashed',
            borderColor: isDropzoneActive
              ? theme.palette.primary.light
              : theme.palette.grey[400],
            backgroundColor: isDropzoneActive
              ? alpha(theme.palette.primary.light, 0.25)
              : theme.palette.grey[100],
          })}
          {...dropzone.getRootProps()}
        >
          <input {...dropzone.getInputProps()} />
          <AddPhotoAlternateIcon
            sx={{
              fontSize: '8vh',
              transition: 'all 500ms ease-in-out',
              color: theme =>
                isDropzoneActive
                  ? theme.palette.grey[800]
                  : theme.palette.grey[400],
            }}
          />
          <Typography
            variant="subtitle1"
            sx={{
              transition: 'all 500ms ease-in-out',
              color: theme =>
                isDropzoneActive
                  ? theme.palette.grey[800]
                  : theme.palette.grey[400],
            }}
          >
            {placeholderText}
          </Typography>
        </Box>
      </Box>
      <FormHelperText>{errorMessage}</FormHelperText>

      <Stack spacing={2} direction="row" sx={{ overflowX: 'auto', mt: 1 }}>
        {images.map((image, idx) => (
          <Card
            key={image}
            variant="outlined"
            sx={{
              p: 2,
              flexShrink: 0,
              width: { xs: 100, md: 150 },
              height: { xs: 100, md: 150 },
              position: 'relative',
            }}
          >
            <IconButton
              size="small"
              color="error"
              sx={{ position: 'absolute', top: 0, right: 0 }}
              onClick={() => {
                handleImageRemove(idx)
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>

            <CardMedia
              alt=""
              component="img"
              src={image}
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
            />
          </Card>
        ))}
      </Stack>
    </FormControl>
  )
}
