import { type ServerResponse, axios } from '~/axios'
import { type EconomicLibraryQuestion } from '~/utils/types/economic-library'

export async function getListLibraryQuestions(filters: {
  language: string
  status: string
  country: string
  measurementUnit: string
  stoplightType: string
  filter: string
  verified: boolean
  page: number
}) {
  return await axios.post<
    ServerResponse<{
      listEconomicLibraryQuestions: {
        content: EconomicLibraryQuestion[]
        totalPages: number
      }
    }>
  >('/graphql', {
    query:
      'query listEconomicLibraryQuestions($language: String, $status : String, $country: String, $measurementUnit: String, $stoplightType: String, $verified: Boolean, $filter: String, $page: Int) {listEconomicLibraryQuestions(language: $language, status: $status, country: $country, measurementUnit: $measurementUnit, stoplightType: $stoplightType, verified: $verified, filter: $filter, page: $page)  { content {id codeName questionText shortName options language country verified measurementUnit status stoplightType answerType topic topicInfo} totalPages  }}',
    variables: {
      ...filters,
    },
  })
}

export async function createOrUpdateQuestion(question) {
  const query = question.id
    ? 'updateEconomicLibraryQuestion'
    : 'createEconomicLibraryQuestion'

  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query: `mutation ${query}($question: EconomicLibraryQuestionModelInput) {${query}(question: $question)  { successful }}`,
      variables: {
        question,
      },
    }),
  })
}

export async function deleteLibraryQuestion(question) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation deleteEconomicLibraryQuestion($question: EconomicLibraryQuestionModelInput) {deleteEconomicLibraryQuestion(question: $question)  { successful  }}',
      variables: {
        question,
      },
    }),
  })
}

export async function verifiedEconomicCodenames() {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query: 'query { listVerifiedEconomicCodenames }',
    }),
  })
}
