import { type ReactNode, createContext, useMemo, useState } from 'react'

import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import {
  CssBaseline,
  type Direction,
  StyledEngineProvider,
  type Theme,
  ThemeProvider,
} from '@mui/material'
import { StylesProvider, jssPreset } from '@mui/styles'
import { create } from 'jss'
import rtl from 'jss-rtl'
import { prefixer } from 'stylis'
import rtlPlugin from 'stylis-plugin-rtl'

import { theme } from '~/theme'

interface MuiWrapperContextProps {
  isLtR: boolean
  isRtL: boolean
  currentDirection: Direction
  changeDirection: (dir: Direction) => void
}

export const MUIWrapperContext = createContext<MuiWrapperContextProps>({
  isLtR: true,
  isRtL: false,
  currentDirection: 'ltr',
  changeDirection: (dir: Direction) => {},
})

interface MUIWrapperProviderProps {
  children: ReactNode
}

// Create rtl cache
const cacheRtl = createCache({
  key: 'muirtl',
  prepend: true,
  stylisPlugins: [prefixer, rtlPlugin],
})

const cacheLtr = createCache({
  key: 'muiltr',
  prepend: true,
})

// Configure JSS
const jss = create({
  plugins: [...jssPreset().plugins, rtl()],
})

export default function MuiWrapperProvider({
  children,
}: MUIWrapperProviderProps) {
  const [direction, setDirection] = useState<Direction>('ltr')

  const muiWrapperUtils: MuiWrapperContextProps = useMemo(
    () => ({
      isLtR: direction === 'ltr',
      isRtL: direction === 'rtl',
      currentDirection: direction,
      changeDirection: (dir: Direction) => {
        document.dir = dir
        setDirection(dir)
      },
    }),
    [direction],
  )

  const customTheme: Theme = useMemo(
    () => ({ ...theme, direction }),
    [direction],
  )

  return (
    <StyledEngineProvider injectFirst>
      {/* @ts-expect-error - This seems to be a problem with CacheProvider's types. As I just updated it. It seems there aren't any reports of this, so I'm mitigating this problem for now. */}
      <CacheProvider value={direction === 'rtl' ? cacheRtl : cacheLtr}>
        <StylesProvider jss={jss}>
          <MUIWrapperContext.Provider value={muiWrapperUtils}>
            <ThemeProvider theme={customTheme}>
              <CssBaseline />
              {children}
            </ThemeProvider>
          </MUIWrapperContext.Provider>
        </StylesProvider>
      </CacheProvider>
    </StyledEngineProvider>
  )
}
