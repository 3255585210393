import { type ServerResponse, axios, cleanAxios } from '~/axios'
import { convertTimers } from '~/utils/survey-utils'
import { type CurrentDraft } from '~/utils/types/current-draft'
import { type Permission, type Role } from '~/utils/types/user'

export function normalizeDraftForSnapshot(draft: CurrentDraft) {
  type NormalizedDraft = Omit<CurrentDraft, 'agreedTerms'> &
    Partial<Pick<CurrentDraft, 'agreedTerms'>>

  //* If this function is new to you. You can read more about it here
  //* https://developer.mozilla.org/en-US/docs/Web/API/structuredClone
  const normalizedDraft: NormalizedDraft = structuredClone(draft)

  const { economicTime, lifemapTime, stoplightTime } = convertTimers({
    ...normalizedDraft,
    agreedTerms: true,
  })

  delete normalizedDraft.previousIndicatorSurveyDataList
  delete normalizedDraft.previousIndicatorPriorities
  delete normalizedDraft.previousIndicatorAchivements
  delete normalizedDraft.isRetake
  delete normalizedDraft.justStoplightRetake
  delete normalizedDraft.agreedTerms
  delete normalizedDraft.familyData.familyMembersList[0].emailVerificationCode
  delete normalizedDraft.familyData.familyMembersList[0].isEmailVerified

  const normalizedLifemapNavHistory = normalizedDraft.lifemapNavHistory?.map(
    element => ({
      ...element,
      state: element.state ? JSON.stringify(element.state) : '',
    }),
  )

  return {
    ...normalizedDraft,
    lifemapTime,
    economicTime,
    stoplightTime,
    //* NOTE: I don't know why whe can't send the pictures key
    //* apparently it was decided on this commit
    //* https://github.com/FundacionParaguaya/stoplight-web/pull/843
    pictures: undefined,
    lifemapNavHistory: normalizedLifemapNavHistory ?? [],
  }
}

/**
 * `NOTE` This function should be non-blocking so
 *  that any problems do not prevent the user from finishing the survey.
 */
type NormalizedDraftForSnapshot = ReturnType<typeof normalizeDraftForSnapshot>
export async function saveSnapshotDraft(
  newSnapshot: NormalizedDraftForSnapshot,
) {
  const response = await axios.post<ServerResponse<unknown>>('/graphql', {
    query:
      'mutation saveSnapshotDraft($newSnapshot: NewSnapshotDTOInput) {saveSnapshotDraft(newSnapshot: $newSnapshot)} ',
    variables: { newSnapshot },
  })

  return response.data.data
}

export async function saveAnonymousSnapshotDraft(
  newSnapshot: NormalizedDraftForSnapshot,
  anonUserToken: string,
) {
  await cleanAxios.post<ServerResponse<unknown>>(
    '/graphql',
    {
      query:
        'mutation saveSnapshotDraft($newSnapshot: NewSnapshotDTOInput) {saveSnapshotDraft(newSnapshot: $newSnapshot)} ',
      variables: { newSnapshot },
    },
    {
      headers: { Authorization: `Bearer ${anonUserToken}` },
    },
  )
}

export async function sendEmailVerificationCode(email: string) {
  const response = await axios.post<
    ServerResponse<{ sendEmailVerificationCode: { successful: boolean } }>
  >('/graphql', {
    query:
      'mutation sendEmailVerificationCode( $email: String ) { sendEmailVerificationCode( email: $email ) { successful } }',
    variables: { email },
  })

  return response.data.data
}

export async function verifyEmailVerificationCode(email: string, code: string) {
  const response = await axios.post<
    ServerResponse<{
      verifyEmailCode: { content: 'SUCCESSFUL' | 'INVALID' }
    }>
  >('/graphql', {
    query:
      'query verifyEmailCode($email: String $code: String) { verifyEmailCode (email: $email code: $code) {content} }',
    variables: { email, code },
  })

  return response.data.data
}

export async function sendPhoneVerificationCode(
  phoneNumber: string,
  surveyDefinitionId: number,
) {
  const query = /* GraphQL */ `
    mutation sendSMSVerificationCode(
      $phoneNumber: String!
      $surveyDefinitionId: Long!
    ) {
      sendSMSVerificationCode(
        phoneNumber: $phoneNumber
        surveyDefinition: $surveyDefinitionId
      ) {
        successful
      }
    }
  `

  const response = await axios.post<
    ServerResponse<{ sendSMSVerificationCode: { successful: boolean } }>
  >('/graphql', {
    query,
    variables: { phoneNumber, surveyDefinitionId },
  })

  return response.data.data
}

export async function verifyPhoneVerificationCode(
  phoneNumber: string,
  code: string,
) {
  const response = await axios.post<
    ServerResponse<{
      verifySMSCode: { content: 'SUCCESSFUL' | 'INVALID' }
    }>
  >('/graphql', {
    query:
      'query verifySMSCode($phoneNumber: String, $code: String) { verifySMSCode(phoneNumber: $phoneNumber, code: $code) { content } }',
    variables: { phoneNumber, code },
  })

  return response.data.data
}

interface User {
  username: string
  password: string
  hub: number
  organization: number
  email: string
  name: string
  role: Role
  projects: Array<{ id: number }>
  active: boolean
  permissions: Permission[] | null
  phoneNumber: string
}

export async function createFamilyUser(
  user: User,
  verificationMethod: 'EMAIL' | 'PHONE',
  code: string,
) {
  const response = await axios.post<
    ServerResponse<{
      createFamilyUser: { username: string }
    }>
  >('/graphql', {
    query:
      'mutation createFamilyUser( $user: UserModelInput $verificationMethod: String $code: String ) { createFamilyUser( user: $user verificationMethod: $verificationMethod code: $code ) { username } }',
    variables: {
      user,
      verificationMethod,
      code,
    },
  })

  return response.data.data
}
