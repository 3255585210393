import { axios, cleanAxios } from '~/axios'

const CLIENT_SECRET = 'secret'
const CLIENT_ID = 'barClientIdPassword'

export async function changePassword(userPasswords: Record<string, unknown>) {
  return await axios.post('/graphql', {
    query:
      'mutation changePassword($oldPassword: String, $newPassword : String, $repeatNewPassword : String) {changePassword(oldPassword: $oldPassword, newPassword: $newPassword, repeatNewPassword : $repeatNewPassword){successful}}',
    variables: {
      oldPassword: userPasswords.currentPassword,
      newPassword: userPasswords.newPassword,
      repeatNewPassword: userPasswords.repeatNewPassword,
    },
  })
}

interface GetTokenResponse {
  env: string
  scope: string
  token_type: string
  expires_in: number
  access_token: string
  refresh_token: string
  user: {
    didExpire: boolean
    willExpire: boolean
    daysToExpirePassword: number
    password: string
    username: string
    enabled: boolean
    permissions: unknown
    stakeholder: unknown
    accountNonLocked: boolean
    accountNonExpired: boolean
    credentialsNonExpired: boolean
    application: Record<string, unknown>
    organization: Record<string, unknown>
    authorities: Array<{ authority: string }>
  }
}

export async function getToken(formData: FormData) {
  return await cleanAxios.post<GetTokenResponse>('/oauth/token', formData, {
    headers: {
      Authorization: `Basic ${window.btoa(`${CLIENT_ID}:${CLIENT_SECRET}`)}`,
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
  })
}

export async function logout() {
  return await axios.get<never>('/oauth/revoke-token')
}
