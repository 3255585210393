// Third Party
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'

// Project
import { getLanguageByCode } from '~/utils/lang-utils'

interface DatePickerProviderProps {
  children: JSX.Element | JSX.Element[]
}

function DatePickerProvider({ children }: DatePickerProviderProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  moment.locale(getLanguageByCode(language))

  return (
    <LocalizationProvider
      adapterLocale={language}
      dateAdapter={AdapterMoment}
      localeText={{
        okButtonLabel: t('general.ok'),
        clearButtonLabel: t('general.clear'),
        cancelButtonLabel: t('general.cancel'),
      }}
    >
      {children}
    </LocalizationProvider>
  )
}

export default DatePickerProvider
