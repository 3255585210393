import { Button, Modal, type Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

// Project
import face from '~/assets/serious_face.png'

const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    width: '90vw',
    maxWidth: 375,
    backgroundColor: theme.palette.background.default,
    outline: 'none',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '35px 50px',
  },
  button: {
    marginRight: theme.spacing(2),
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    width: 55,
  },
  title: {
    textAlign: 'center',
    marginBottom: theme.spacing(1.5),
    color: theme.palette.grey[600],
    fontSize: '24px',
  },
  subtitle: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
    color: theme.palette.grey[500],
    fontSize: 14,
  },
  areYouSureLabel: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
    color: theme.typography.h4.color,
    fontSize: 14,
    lineHeight: '20px',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
  },
}))

interface Props {
  open?: boolean
  onClose: () => void
  onDissmiss: () => void
}

export default function ExitModal({
  open = false,
  onDissmiss,
  onClose,
}: Props) {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Modal
      open={open}
      onClose={() => {
        onDissmiss()
      }}
    >
      <div className={classes.mainContainer}>
        <img src={face} className={classes.icon} alt="Warning icon" />
        <Typography className={classes.title} variant="h5">
          {t('views.exitModal.confirmTitle')}
        </Typography>
        <Typography className={classes.subtitle} variant="subtitle1">
          {t('views.exitModal.cannotUndo')}
        </Typography>
        <Typography className={classes.areYouSureLabel} variant="h5">
          {t('views.exitModal.confirmText')}
        </Typography>

        <div className={classes.buttonContainer}>
          <Button
            className={classes.button}
            variant="outlined"
            color="primary"
            onClick={() => {
              onClose()
            }}
          >
            {t('general.yes')}
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              onDissmiss()
            }}
          >
            {t('general.no')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
