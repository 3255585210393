// Third Party
import { zodResolver } from '@hookform/resolvers/zod'
import { Edit as EditIcon } from '@mui/icons-material'
import {
  Button,
  CircularProgress,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { type TFunction } from 'i18next'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { type PathMatch, useNavigate, useParams } from 'react-router-dom'
import { z } from 'zod'

import iconProprity from '~/assets/iconPriority.png'
import BottomSpacer from '~/components/BottomSpacer'
import Container from '~/components/Container'
import TitleBar from '~/components/TitleBar'
import { useAppSelector } from '~/redux/hooks'
import { updateDraft } from '~/redux/slices/currentDraft'
// Project
import { COLORS } from '~/theme'
import { constructEstimatedMonthsOptions } from '~/utils/form-utils'
import { isTimerNum, remakeTimers, screenTimer } from '~/utils/survey-utils'
import { type CurrentDraft } from '~/utils/types/current-draft'
import { type CurrentSurvey } from '~/utils/types/current-survey'
import {
  type LifemapQuestionType,
  type SurveyLifemapQuestion,
} from '~/utils/types/surveys'

type PriorityTranslations = Extract<
  LifemapQuestionType,
  'PRIORITY_REASON' | 'PRIORITY_ACTION' | 'PRIORITY_ESTIMATED_DATE'
>

function getQuestionTitles(
  t: TFunction,
  questions: SurveyLifemapQuestion[],
  key: PriorityTranslations,
) {
  const translations: { [key in PriorityTranslations]: string } = {
    PRIORITY_REASON: t('views.lifemap.whyDontYouHaveIt'),
    PRIORITY_ACTION: t('views.lifemap.whatWillYouDoToGetIt'),
    PRIORITY_ESTIMATED_DATE: t('views.lifemap.howManyMonthsWillItTake'),
  }

  const label =
    questions.find(q => q.questionType === key)?.questionText ??
    translations[key]

  return label
}

export default function Priority() {
  const params = useParams()
  const { indicator } = params
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { t } = useTranslation()
  const [imageStatus, setImageStatus] = useState('loading')

  const currentDraft = useAppSelector(state => {
    if (!state.currentDraft) throw new Error('No currentDraft')
    return state.currentDraft
  })
  const currentSurvey = useAppSelector(state => {
    if (!state.currentSurvey) throw new Error('No currentSurvey')
    return state.currentSurvey
  })

  const { lifemapQuestions } = currentSurvey
  const monthsOptions = constructEstimatedMonthsOptions(t)

  const priority = currentDraft.priorities.find(p => p.indicator === indicator)
  const question = currentSurvey.surveyStoplightQuestions.find(
    q => q.codeName === indicator,
  )

  const validationSchema = z.object({
    priorityReason: z.string().optional(),
    priorityAction: z.string().optional(),
    estimatedDate: z
      .union([z.string(), z.number(), z.null()])
      .transform((val, ctx) => {
        if (!val) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('validation.fieldIsRequired'),
            fatal: true,
          })
          return z.NEVER
        }

        const parsedVal = parseInt(val.toString())
        if (isNaN(parsedVal)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('validation.fieldIsRequired'),
            fatal: true,
          })
          return z.NEVER
        }

        return parsedVal
      }),
  })

  type FormValues = z.infer<typeof validationSchema>

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    mode: 'onChange',
    resolver: zodResolver(validationSchema),
    defaultValues: {
      priorityReason: priority?.reason ?? '',
      priorityAction: priority?.action ?? '',
      estimatedDate: priority?.estimatedDate,
    },
  })

  useEffect(() => {
    if (!question) navigate(-1)
  }, [])

  const stoplightAnswer = currentDraft.indicatorSurveyDataList.find(
    answers => answers.key === question?.codeName,
  )

  const stoplightColor = question?.stoplightColors.find(
    e => e.value === stoplightAnswer?.value,
  )

  let color
  let textColor = 'white'

  if (stoplightColor?.value === 3) {
    color = COLORS.GREEN
  } else if (stoplightColor?.value === 2) {
    color = COLORS.YELLOW
    textColor = 'black'
  } else if (stoplightColor?.value === 1) {
    color = COLORS.RED
  }

  const savePriority = (values: FormValues) => {
    const { priorityReason, priorityAction, estimatedDate } = values

    const newPriority = {
      reason: priorityReason ?? '',
      action: priorityAction ?? '',
      estimatedDate,
      indicator: question?.codeName ?? '',
    }

    const item = currentDraft.priorities.filter(
      priority => priority.indicator === question?.codeName,
    )[0]

    // If the item does not exist, we create it
    if (!item) {
      dispatch(
        updateDraft({
          ...currentDraft,
          priorities: [...currentDraft.priorities, newPriority],
        }),
      )
      navigate(-1)
      return
    }

    // else, we update it
    const index: number = currentDraft.priorities.indexOf(item)
    dispatch(
      updateDraft({
        ...currentDraft,
        priorities: [
          ...currentDraft.priorities.slice(0, index),
          newPriority,
          ...currentDraft.priorities.slice(index + 1),
        ],
      }),
    )

    navigate(-1)
  }

  useEffect(() => {
    let updatedDraft = { ...currentDraft }

    if (isTimerNum(updatedDraft)) {
      const remakedTimers = remakeTimers(updatedDraft)
      updatedDraft = { ...updatedDraft, ...remakedTimers }
    }

    const screenTimes = screenTimer(updatedDraft, 'Overview')
    dispatch(updateDraft({ ...updatedDraft, ...screenTimes }))
  }, [])

  return (
    <div>
      <TitleBar
        title={question?.questionText}
        extraTitleText={question?.dimension}
        progressBar={false}
      />
      <>
        <div className={classes.imgAndDescriptionContainer}>
          <div
            className={classes.imageContainer}
            style={{ backgroundColor: color }}
          >
            <>
              {imageStatus === 'loading' && (
                <div className={classes.loadingContainer}>
                  <div className={classes.loadingIndicatorCenter}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <CircularProgress
                        color="inherit"
                        className={classes.circularProgress}
                      />
                    </div>

                    <img
                      onLoad={() => {
                        setImageStatus('loaded')
                      }}
                      style={{ display: 'none' }}
                      src={stoplightColor?.url}
                      alt=""
                    />
                  </div>
                </div>
              )}
              {imageStatus !== 'loading' && (
                <img
                  className={classes.imgClass}
                  src={stoplightColor?.url}
                  alt="surveyImg"
                />
              )}
            </>
          </div>
          <div
            className={classes.paragraphContainer}
            style={{ backgroundColor: color }}
          >
            <div className={classes.answeredQuestion}>
              <i
                style={{
                  backgroundColor: color,
                }}
                className={`material-icons ${classes.icon}`}
              >
                done
              </i>
            </div>
            <div className={classes.editContainer}>
              <EditIcon className={classes.editIcon} />
            </div>
            <Typography
              variant="body2"
              align="center"
              paragraph
              className={classes.paragraphTypography}
              style={{ color: textColor }}
            >
              {stoplightColor?.description}
            </Typography>
          </div>
        </div>

        <div className={classes.pinAndPriority}>
          <img style={{ height: 55 }} src={iconProprity} alt="icon" />
          <Typography variant="h5" align="center" style={{ marginTop: '10px' }}>
            {t('views.lifemap.makeThisAPriority')}
          </Typography>
        </div>
        <Container variant="slim">
          <form
            onSubmit={handleSubmit(savePriority)}
            className={classes.formContainer}
          >
            <TextField
              fullWidth
              variant="standard"
              autoComplete="off"
              label={getQuestionTitles(t, lifemapQuestions, 'PRIORITY_REASON')}
              {...register('priorityReason')}
            />

            <TextField
              fullWidth
              variant="standard"
              autoComplete="off"
              label={getQuestionTitles(t, lifemapQuestions, 'PRIORITY_ACTION')}
              {...register('priorityAction')}
            />

            <TextField
              select
              fullWidth
              variant="standard"
              SelectProps={{ MenuProps: { style: { maxHeight: 250 } } }}
              error={!!errors.estimatedDate?.message}
              helperText={errors.estimatedDate?.message}
              label={getQuestionTitles(
                t,
                lifemapQuestions,
                'PRIORITY_ESTIMATED_DATE',
              )}
              value={watch('estimatedDate')}
              {...register('estimatedDate')}
            >
              {monthsOptions.map(month => (
                <MenuItem key={month.label} value={month.value}>
                  {month.label}
                </MenuItem>
              ))}
            </TextField>

            <div className={classes.buttonContainerForm}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={isSubmitting}
              >
                {t('general.save')}
              </Button>
            </div>
          </form>
        </Container>
        <BottomSpacer />
      </>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  imageContainer: { display: 'flex', position: 'inherit', width: '100%' },
  loadingContainer: { position: 'absolute', top: '50%', left: '50%' },
  circularProgress: { color: 'white' },
  icon: {
    color: 'white',
    fontSize: 39,
    height: 80,
    width: 80,
    margin: 'auto',
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingIndicatorCenter: {
    left: -20,
    bottom: -20,
    position: 'absolute',
  },
  editContainer: {
    position: 'absolute',
    top: '20px',
    right: '20px',
    display: 'none', // TODO: Hidding edit button, feature not implemented yet
  },
  editIcon: {
    fontSize: 24,
    color: 'white',
  },
  answeredQuestion: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    left: '0%',
    transform: 'translate(-50%,-50%)',
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      top: '0%',
      left: '50%',
    },
  },
  pinAndPriority: {
    marginTop: '40px',
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  imgClass: {
    width: '100%',
    height: '307px',
    minHeight: '100%',
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      height: 185,
    },
  },
  paragraphContainer: {
    position: 'relative',
    margin: '0px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    minHeight: '307px',
    [theme.breakpoints.down('sm')]: {
      minHeight: 185,
    },
  },
  paragraphTypography: {
    fontSize: 16,
    zIndex: 1,
    padding: '30px 30px 30px 40px',
    marginBottom: 0,
  },
  imgAndDescriptionContainer: {
    position: 'relative',
    display: 'flex',
    width: '614px',
    margin: 'auto',
    marginTop: '30px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%',
    },
  },
  buttonContainerForm: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 40,
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  },
}))
