import { Close as CloseIcon } from '@mui/icons-material'
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Form, Formik } from 'formik'
import { useSnackbar } from 'notistack'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'

import { useLocalizedCountries } from '~/utils/hooks/useLocalizedCountries'
import { getLanguageByCode } from '~/utils/lang-utils'
import { type PlatformLanguage } from '~/utils/types/i18n'

import InputWithLabel from '../../components/InputWithLabel'
import SwitchInput from '../../components/SwitchInput'
import CountrySelector from '../../components/selectors/CountrySelector'
import GenericSelector from '../../components/selectors/GenericSelector'
import LanguageSelector from '../../components/selectors/LanguageSelector'
import { createOrUpdateQuestion } from '../../services/economic-library'
import { type User } from '../../utils/types/user'
import CodeNameSelector from '../indicators/CodeNameSelector'
import EconomicOptionForm from '../survey-builder/economic/EconomicOptionForm'

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'auto',
    maxHeight: '100vh',
  },
  container: {
    padding: '2em',
    backgroundColor: theme.palette.background.default,
    outline: 'none',
    width: '85vw',
    maxWidth: 1000,
    height: '60vh',
    minHeight: 610,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: '1em',
      paddingTop: '2.5rem',
      height: '100vh',
      width: '100vw',
    },
    overflowY: 'auto',
  },
  closeIcon: {
    position: 'absolute',
    top: 5,
    right: 5,
    marginBottom: 15,
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
  },
  buttonContainerForm: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: '3rem',
    marginBottom: '1rem',
  },
  languageSelectorContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
  },
}))

const fieldIsRequired = 'validation.fieldIsRequired'

interface Props {
  open: boolean
  question: Question
  options: Options
  onClose: (submitted: boolean) => void
}

interface Question {
  id: number
  questionText: string
  codeName: string
  topic: string
  topicInfo: string
  answerType: { value: string; label: string }
  shortName: string
  options: Array<{ value: string; text: string }>
  stoplightType: string
  language: string
  country: string
  measurementUnit: { value: string; label: string }
  status: string
  verified: boolean
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
}

interface Options {
  answerType: Array<{ value: string; label: string }>
  measurementUnits: Array<{ value: string; label: string }>
  stoplights: Array<{ value: string; label: string }>
}

const EconomicLibraryForm = ({ open, question, options, onClose }: Props) => {
  const classes = useStyles()
  const user = useSelector((state: { user: User }) => state.user)
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { i18n } = useTranslation()

  const [loading, setLoading] = useState(false)
  const [focusOption, setFocusOption] = useState(null)

  const validationSchema = Yup.object({
    language: Yup.object().required(fieldIsRequired),
    codeName: Yup.object().required(fieldIsRequired),
    otherCodeName: Yup.string()
      .trim()
      .when('codeName', (codeName, schema) =>
        codeName && codeName.value === t('general.other')
          ? schema.required(fieldIsRequired)
          : schema,
      ),
    questionText: Yup.string().trim().required(fieldIsRequired),
    shortName: Yup.string().trim().required(fieldIsRequired),
    answerType: Yup.object().required(fieldIsRequired),
    options: Yup.array().when('answerType', (answerType, schema) =>
      hasOptions(answerType) ? schema.required(fieldIsRequired) : schema,
    ),
  })

  const onSubmit = values => {
    setLoading(true)
    const question = {
      id: values.id,
      codeName: values.otherCodeName
        ? values.otherCodeName
        : values.codeName.value,
      shortName: values.shortName,
      questionText: values.questionText,
      definition: values.definition,
      language: values.language.value,
      country: values.country,
      verified: values.verified,
      measurementUnit: values.measurementUnit
        ? values.measurementUnit.value
        : '',
      options: hasOptions(values.options)
        ? values.options.map(option => option.text)
        : '',
      status: values.status,
      answerType: values.answerType.value,
      topic: values.topic,
      topicInfo: values.topicInfo,
      stoplightType: values.stoplightType ? values.stoplightType.value : '',
    }
    createOrUpdateQuestion(question)
      .then(() => {
        enqueueSnackbar(t('views.economic.form.saved'), {
          variant: 'success',
        })
        setLoading(false)
        onClose(true)
      })
      .catch(() => {
        setLoading(false)
        enqueueSnackbar(t('views.economic.form.saveError'), {
          variant: 'error',
        })
      })
  }

  const hasOptions = valueObject => {
    const answerType = valueObject ? valueObject.value : ''
    return (
      answerType === 'select' ||
      answerType === 'radio' ||
      answerType === 'checkbox'
    )
  }

  const lang = getLanguageByCode(i18n.language as PlatformLanguage)
  const countryOptions = useLocalizedCountries(lang)

  const getCountryByCode = useCallback(
    (code: string) => countryOptions.find(country => country.code === code),
    [countryOptions],
  )

  return (
    <Modal
      disableEnforceFocus
      disableAutoFocus
      className={classes.modal}
      open={open}
      onClose={onClose}
    >
      <div className={classes.container}>
        <Typography variant="h5" align="center" style={{ marginBottom: 10 }}>
          {question.id
            ? t('views.economic.form.edit')
            : t('views.economic.form.create')}
        </Typography>
        <IconButton
          className={classes.closeIcon}
          key="dismiss"
          onClick={() => {
            onClose(false)
          }}
          size="large"
        >
          <CloseIcon style={{ color: 'green' }} />
        </IconButton>
        <Formik
          initialValues={{
            id: question.id || null,
            codeName: question.codeName || '',
            country: question.country || '',
            verified: question.verified || false,
            questionText: question.questionText || '',
            answerType: question.answerType || '',
            shortName: question.shortName || '',
            topic: question.topic || '',
            topicInfo: question.topicInfo || '',
            options: Array.isArray(question.options) ? question.options : [],
            language: question.language || '',
            measurementUnit: question.measurementUnit || '',
            status: question.status || '',
            stoplightType: question.stoplightType || '',
          }}
          validationSchema={validationSchema}
          onSubmit={values => {
            onSubmit(values)
          }}
        >
          {({ setFieldValue, setTouched, touched, values, validateForm }) => (
            <Form noValidate autoComplete={'off'}>
              <Grid item md={12} container>
                <Grid item md={8} xs={12}>
                  {/* @ts-expect-error - Props are not typed correctly */}
                  <InputWithLabel
                    required
                    name="questionText"
                    inputProps={{ maxLength: '255' }}
                    title={t('views.economic.form.title')}
                    onChange={async e =>
                      await setFieldValue('questionText', e.target.value)
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={4}
                  xs={12}
                  container
                  alignItems="center"
                  justifyContent="center"
                >
                  <SwitchInput
                    label={t(`views.indicator.create.verified`)}
                    value={values.verified}
                    onChange={async () =>
                      await setFieldValue('verified', !values.verified)
                    }
                    classes={undefined}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item md={4} sm={6} xs={12}>
                  {/* @ts-expect-error - Props are not typed correctly */}
                  <InputWithLabel
                    required
                    name="shortName"
                    inputProps={{ maxLength: '255' }}
                    title={t(`views.indicator.create.shortName`)}
                    onChange={async e =>
                      await setFieldValue('shortName', e.target.value)
                    }
                  />
                </Grid>
                {/* @ts-expect-error - Props are not typed correctly */}
                <CodeNameSelector
                  isEconomic
                  codeName={values.codeName}
                  error={touched.codeName && !values.codeName}
                  onChangeCodeName={code => {
                    void setFieldValue('otherCodeName', '')
                    void setFieldValue('codeName', code)
                  }}
                  onChangeOther={async other =>
                    await setFieldValue('otherCodeName', other)
                  }
                  onBlur={async () =>
                    await setTouched(
                      Object.assign(touched, {
                        codeName: true,
                      }),
                    )
                  }
                />
              </Grid>

              <Grid container spacing={2}>
                <Grid
                  item
                  md={4}
                  sm={6}
                  xs={12}
                  className={classes.languageSelectorContainer}
                >
                  {/* @ts-expect-error - Props are not typed correctly */}
                  <LanguageSelector
                    required
                    withTitle
                    parentLang={language}
                    languageData={values.language}
                    onChangeLanguage={async lang =>
                      await setFieldValue('language', lang)
                    }
                    error={touched.language && !values.language}
                    onBlur={async () =>
                      await setTouched(
                        Object.assign(touched, {
                          language: true,
                        }),
                      )
                    }
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <GenericSelector
                    label={t('views.indicator.filter.stoplights')}
                    withTitle
                    name="stoplightType"
                    // @ts-expect-error - I think that values.stoplightType has a wrong type of 'string' instead of '{label: string, value: string}'
                    value={values.stoplightType}
                    options={options.stoplights}
                    onChange={async stoplightType =>
                      await setFieldValue('stoplightType', stoplightType)
                    }
                    isClearable
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  {/* @ts-expect-error - Props are not typed correctly */}
                  <InputWithLabel
                    required
                    name="topic"
                    inputProps={{ maxLength: '255' }}
                    title={t('views.economic.form.topic')}
                    onChange={async e =>
                      await setFieldValue('topic', e.target.value)
                    }
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item md={4} sm={6} xs={12} container>
                  {/* @ts-expect-error - Props are not typed correctly */}
                  <CountrySelector
                    withTitle
                    countryData={getCountryByCode(values.country)}
                    onChangeCountry={async country =>
                      await setFieldValue('country', country)
                    }
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  {/* @ts-expect-error - Props are not typed correctly */}
                  <GenericSelector
                    label={t('views.indicator.filter.measurement')}
                    withTitle
                    value={values.measurementUnit}
                    options={options.measurementUnits}
                    isClearable
                    onChange={async measurementUnit =>
                      await setFieldValue('measurementUnit', measurementUnit)
                    }
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  {/* @ts-expect-error - Props are not typed correctly */}
                  <InputWithLabel
                    multiline
                    name="topicInfo"
                    inputProps={{ maxLength: '255' }}
                    title={t('views.economic.form.topicInfo')}
                    onChange={async e =>
                      await setFieldValue('topicInfo', e.target.value)
                    }
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item md={4} sm={6} xs={12}>
                  <GenericSelector
                    label={t('views.economic.form.answerType')}
                    withTitle
                    value={values.answerType}
                    options={options.answerType}
                    required
                    loading={false}
                    error={(touched.answerType && !values.answerType) ?? false}
                    onChange={async answerType =>
                      await setFieldValue('answerType', answerType)
                    }
                    // @ts-expect-error - Props are not typed correctly
                    onBlur={async () =>
                      await setTouched(
                        Object.assign(touched, {
                          answerType: true,
                        }),
                      )
                    }
                  />
                </Grid>
                {hasOptions(values.answerType) && (
                  <Grid item md={4} sm={6} xs={12}>
                    {/* @ts-expect-error - Props are not typed correctly */}
                    <EconomicOptionForm
                      options={values.options}
                      focusOption={focusOption}
                      touched={!!touched.options}
                      onFocusOption={e => {
                        setFocusOption(e)
                      }}
                      onBlurOption={() => {
                        setFocusOption(null)
                      }}
                      updateOptions={async options =>
                        await setFieldValue('options', options)
                      }
                    />
                  </Grid>
                )}
              </Grid>

              {loading && (
                <CircularProgress className={classes.loadingContainer} />
              )}
              <div className={classes.buttonContainerForm}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={loading}
                >
                  {t('general.save')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  )
}

export default EconomicLibraryForm
