import { Close as CloseIcon, Save as SaveIcon } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, IconButton, Modal, Stack, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Form, Formik } from 'formik'
import { useSnackbar } from 'notistack'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'

import DropImageZone from '~/components/DropImageZone'
import { queries } from '~/queries'
import {
  type Stakeholder,
  createStakeholder,
  updateStakeholder,
} from '~/services/stakeholder-services'
import { type PlatformLanguage } from '~/utils/types/i18n'
import { excludeFalsyWithMessage } from '~/utils/zod'

import AutocompleteWithFormik from '../../components/AutocompleteWithFormik'
import BooleanWithFormik from '../../components/BooleanWithFormik'
import ExitModal from '../../components/ExitModal'
import InputWithFormik from '../../components/InputWithFormik'
import CountrySelector from '../../components/selectors/CountrySelector'
import { useLocalizedCountries } from '../../utils/hooks/useLocalizedCountries'
import { languageOptions } from '../../utils/lang-utils'

const FIVE_MINUTES_IN_MS = 1000 * 60 * 5

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  innerContainer: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '40px 50px',
    minHeight: '35vh',
    maxHeight: '85vh',
    maxWidth: 500,
    overflowY: 'auto',
    position: 'relative',
    outline: 'none',
  },
  closeIcon: {
    position: 'absolute',
    top: 5,
    right: 5,
    marginBottom: 15,
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: 30,
  },
}))

interface StakeholderFormModalProps {
  open: boolean
  stakeholder: Stakeholder
  afterSubmit: () => void
  toggleModal: () => void
}

export default function StakeholderFormModal({
  open,
  stakeholder,
  afterSubmit,
  toggleModal,
}: StakeholderFormModalProps) {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const isCreate = !stakeholder.id
  const platformLanguage = language as PlatformLanguage

  const [openExitModal, setOpenExitModal] = useState(false)

  const stakeholderLogo = stakeholder.logoUrl ? [stakeholder.logoUrl] : []
  const [logos, setLogos] = useState<string[]>(stakeholderLogo)

  const countryOptions = useLocalizedCountries(platformLanguage)

  type FormInitValues = z.input<typeof validationSchema>
  type FormValues = z.infer<typeof validationSchema>
  const validationSchema = useMemo(() => {
    const requiredMessage = t('validation.fieldIsRequired')

    return z.object({
      id: z.number().nullable(),
      name: z
        .string()
        .trim()
        .min(1, { message: requiredMessage })
        .max(50, { message: t('views.stakeholder.form.nameLengthExceeded') }),
      description: z
        .string()
        .trim()
        .max(256, {
          message: t('views.stakeholder.form.descriptionLengthExceeded'),
        }),
      language: z.string().min(1, { message: requiredMessage }),
      country: z
        .object({ value: z.string(), label: z.string() })
        .nullable()
        .transform(excludeFalsyWithMessage(requiredMessage)),
      active: z.boolean(),
      areaOfExpertise: z.string().optional(),
      stakeholderType: z.string().optional(),
    })
  }, [t])

  const organizationTypesQuery = useQuery({
    ...queries.organizations.types(platformLanguage),
    staleTime: FIVE_MINUTES_IN_MS,
    select: data => {
      return data.data.data.organizantionTypes.map(type => ({
        value: type.code,
        label: type.description,
      }))
    },
  })

  const organizationAreasTypesQuery = useQuery({
    ...queries.organizations.areaTypes(platformLanguage),
    staleTime: FIVE_MINUTES_IN_MS,
    select: data => {
      return data.data.data.organizationAreasTypes.map(type => ({
        value: type.code,
        label: type.description,
      }))
    },
  })

  const createStakeholderMutation = useMutation({
    mutationFn: createStakeholder,
    onSuccess: () => {
      enqueueSnackbar(t('views.stakeholder.form.save.success'), {
        variant: 'success',
      })
      onClose(true)
    },
    onError: () => {
      enqueueSnackbar(t('views.stakeholder.form.save.failed'), {
        variant: 'error',
      })
    },
  })

  const updateStakeholderMutation = useMutation({
    mutationFn: updateStakeholder,
    onSuccess: () => {
      enqueueSnackbar(t('views.stakeholder.form.save.success'), {
        variant: 'success',
      })
      onClose(true)
    },
    onError: () => {
      enqueueSnackbar(t('views.stakeholder.form.save.failed'), {
        variant: 'error',
      })
      onClose(false)
    },
  })

  function onClose(didSubmit: boolean) {
    setLogos([])
    if (didSubmit) {
      afterSubmit()
    }

    toggleModal()
  }

  async function onSubmit(values: FormValues) {
    const sanitazedValues = structuredClone(values)
    if (!sanitazedValues.stakeholderType) {
      delete sanitazedValues.stakeholderType
    }
    if (!sanitazedValues.areaOfExpertise) {
      delete sanitazedValues.areaOfExpertise
    }

    if (sanitazedValues.id) {
      await updateStakeholderMutation.mutateAsync({
        ...sanitazedValues,
        id: sanitazedValues.id,
        logoUrl: logos.at(0) ?? null,
        country: sanitazedValues.country.value,
      })
      return
    }

    await createStakeholderMutation.mutateAsync({
      ...sanitazedValues,
      logoUrl: logos.at(0) ?? null,
      country: sanitazedValues.country.value,
    })
  }

  function formattedCountry(item: string) {
    const country =
      countryOptions.find(country => {
        return country.code === item
      }) ?? countryOptions[0]

    return {
      label: country.label,
      value: item,
    }
  }

  return (
    <Modal
      disableAutoFocus
      disableEnforceFocus
      className={classes.modal}
      open={open}
      onClose={() => {
        !isCreate ? onClose(false) : setOpenExitModal(true)
      }}
    >
      <Box className={classes.innerContainer}>
        <ExitModal
          open={openExitModal}
          onDissmiss={() => {
            setOpenExitModal(false)
          }}
          onClose={() => {
            setOpenExitModal(false)
            onClose(false)
          }}
        />
        <Typography variant="h5" align="center" style={{ marginBottom: 10 }}>
          {isCreate
            ? t('views.stakeholder.form.addTitle')
            : t('views.stakeholder.form.editTitle')}
        </Typography>
        <IconButton
          className={classes.closeIcon}
          key="dismiss"
          onClick={() => {
            onClose(false)
          }}
          size="large"
        >
          <CloseIcon style={{ color: 'green' }} />
        </IconButton>
        <Formik<FormInitValues>
          initialValues={{
            id: (!!stakeholder.id && stakeholder.id) || null,
            name: (!!stakeholder.name && stakeholder.name) || '',
            description:
              (!!stakeholder.description && stakeholder.description) || '',
            active: isCreate ? true : stakeholder.active,
            language: (!!stakeholder.language && stakeholder.language) || '',
            country: stakeholder.country
              ? formattedCountry(stakeholder.country)
              : null,
            stakeholderType:
              (!!stakeholder.stakeholderType && stakeholder.stakeholderType) ||
              '',
            areaOfExpertise:
              (!!stakeholder.areaOfExpertise && stakeholder.areaOfExpertise) ||
              '',
          }}
          validationSchema={toFormikValidationSchema(validationSchema)}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, setFieldValue, values, touched, setTouched }) => (
            <Form noValidate>
              <InputWithFormik
                label={t('views.stakeholder.form.name')}
                name="name"
                required
              />
              <InputWithFormik
                label={t('views.stakeholder.form.description')}
                name="description"
                required
              />
              {/* @ts-expect-error - Avoid using Formik/(component-name)WithFormik. We're migrating towards react-hook-form */}
              <AutocompleteWithFormik
                label={t('views.stakeholder.form.language')}
                name="language"
                rawOptions={languageOptions}
                labelKey="label"
                valueKey="value"
                maxSelectMenuHeight={190}
                isClearable={false}
                required
              />

              {/* @ts-expect-error - Avoid using Formik/(component-name)WithFormik. We're migrating towards react-hook-form */}
              <CountrySelector
                withTitle
                withAutoCompleteStyle
                countryData={values.country}
                onChangeCountry={async country =>
                  await setFieldValue('country', country)
                }
                onBlur={async () =>
                  await setTouched(
                    Object.assign(touched, {
                      country: true,
                    }),
                  )
                }
                parentLang={values.language}
                error={touched.country && !values.country}
                required={true}
              />

              <Stack justifyContent="stretch" my={2}>
                <DropImageZone
                  images={logos}
                  maxImages={1}
                  onChangeImages={newImages => {
                    setLogos(newImages)
                  }}
                  placeholderText={
                    !isCreate &&
                    stakeholder.logoUrl &&
                    stakeholder.logoUrl.length === 0
                      ? t('views.stakeholder.form.changeLogo')
                      : t('views.stakeholder.form.logoUpload')
                  }
                />
              </Stack>

              {/* @ts-expect-error - Avoid using Formik/(component-name)WithFormik. We're migrating towards react-hook-form */}
              <AutocompleteWithFormik
                label={t('views.stakeholder.form.stakeholderType')}
                name="stakeholderType"
                labelKey="label"
                valueKey="value"
                isClearable={false}
                rawOptions={
                  organizationTypesQuery.isSuccess
                    ? organizationTypesQuery.data
                    : []
                }
              />

              {/* @ts-expect-error - Avoid using Formik/(component-name)WithFormik. We're migrating towards react-hook-form */}
              <AutocompleteWithFormik
                label={t('views.stakeholder.form.areaOfExpertise')}
                name="areaOfExpertise"
                labelKey="label"
                valueKey="value"
                isClearable={false}
                rawOptions={
                  organizationAreasTypesQuery.isSuccess
                    ? organizationAreasTypesQuery.data
                    : []
                }
              />
              {!isCreate && (
                // @ts-expect-error - Avoid using Formik/(component-name)WithFormik. We're migrating towards react-hook-form
                <BooleanWithFormik
                  name="active"
                  label={t('views.stakeholder.form.active')}
                  onChange={async () =>
                    await setFieldValue('active', !values.active)
                  }
                />
              )}
              <Box className={classes.buttonContainer}>
                <LoadingButton
                  type="submit"
                  color="primary"
                  variant="contained"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  startIcon={<SaveIcon />}
                >
                  {t('general.save')}
                </LoadingButton>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  )
}
