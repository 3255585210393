import { Search as SearchIcon } from '@mui/icons-material'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import { useEventListener } from 'usehooks-ts'

interface SearchInputProps {
  value: string
  label?: string
  onChange: (value: string) => void
}

export default function SearchInput({
  value,
  label,
  onChange,
}: SearchInputProps) {
  const { t } = useTranslation()
  const [searchText, setSearchText] = useState(value)

  const inputRef = useRef<HTMLInputElement>(null)

  useEventListener('keydown', ({ key, ctrlKey, metaKey }) => {
    if ((ctrlKey && key === 'k') || (metaKey && key === 'k')) {
      inputRef.current?.focus()
    }
  })

  useEffect(() => {
    setSearchText(value)
  }, [value])

  return (
    <TextField
      fullWidth
      inputRef={inputRef}
      label={label ?? t('views.hub.list.search')}
      value={searchText}
      onChange={e => {
        setSearchText(e.target.value)
      }}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          onChange(searchText)
        }
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              color="primary"
              onClick={() => {
                onChange(searchText)
              }}
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}
