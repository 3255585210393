// React
import { Fragment } from 'react'

import { Cancel as CancelIcon } from '@mui/icons-material'
import {
  Chip,
  MenuItem,
  NoSsr,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import { emphasize } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import Select, { components } from 'react-select'
// Third Party
import AsyncSelect from 'react-select/async'

import theme from '~/theme'

const useStyles = makeStyles(theme => ({
  input: {
    minHeight: 19,
    height: '100%',
    display: 'flex',
    padding: '0 12px !important',
    backgroundColor: '#f3f4f6',
    borderRadius: '8px 8px 0 0!important',
  },
  valueContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
    minHeight: 49,
  },
  chip: {
    margin: `${theme.spacing() / 2}px ${theme.spacing() / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.mode === 'light'
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing()}px ${theme.spacing(2)}`,
  },
  singleValue: {
    // fontSize: 16
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 100,
    marginTop: 0,
    left: 0,
    right: 0,
  },
  indicatorSeparator: {
    alignSelf: 'stretch',
    backgroundColor: 'hsl(0,0%,80%)',
    width: 1,
    boxSizing: 'border-box',
  },
  itemSelected: {
    fontWeight: 500,
    fontSize: 16,
    whiteSpace: 'pre-wrap',
  },
  itemNotSelected: {
    fontWeight: 400,
    fontSize: 16,
    whiteSpace: 'pre-wrap',
  },
}))

const StyledMenuItem = forwardRef((props, _unusedRef) => {
  // We only do this because of MUI's documentation.
  // Avoid using forwardRefs https://mui.com/material-ui/guides/composition/#caveat-with-refs
  // NOTE: props has the necessary 'ref' we need from react-select. Don't use any other Refs.
  return (
    <MenuItem
      {...props}
      sx={{
        ':root': { height: 'auto' },
      }}
    />
  )
})

function NoOptionsMessage({ selectProps }) {
  const { t } = useTranslation()

  return (
    <StyledMenuItem component="div">
      <Typography className={selectProps.classes.itemNotSelected}>
        {t('general.noOptionsToShow')}
      </Typography>
    </StyledMenuItem>
  )
}

function LoadingMessage(props) {
  const { t } = useTranslation()

  return (
    <Typography
      color="default"
      variant="body2"
      className={props.selectProps.classes.noOptionsMessage}
    >
      {t('views.survey.loading')}
    </Typography>
  )
}

const InputComponent = forwardRef((props, _unusedRef) => {
  // We only do this because of MUI's documentation.
  // Avoid using forwardRefs https://mui.com/material-ui/guides/composition/#caveat-with-refs
  // NOTE: props has the necessary 'ref' we need from react-select. Don't use any other Refs.
  return <div {...props} />
})

function Control(props) {
  return (
    <TextField
      disabled={props.isDisabled}
      test-id={props.selectProps.name}
      variant="filled"
      fullWidth
      value={props.hasValue ? props.getValue()[0].label : ''}
      FormHelperTextProps={{
        sx: {
          fontSize: theme => theme.typography.subtitle1.fontSize,
        },
      }}
      InputProps={{
        inputComponent: InputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          ref: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  )
}

function Option(props) {
  return (
    <StyledMenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      {...props.innerProps}
    >
      <Typography
        className={clsx('', {
          [props.selectProps.classes.itemSelected]: props.isSelected,
          [props.selectProps.classes.itemNotSelected]: !props.isSelected,
        })}
      >
        {props.children}
      </Typography>
    </StyledMenuItem>
  )
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
      noWrap
      style={{ fontSize: 16 }}
    >
      {props.children}
    </Typography>
  )
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  )
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  )
}

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  )
}

function IndicatorSeparator(props) {
  if (props.isDisabled) return <Fragment />
  return <span className={props.selectProps.classes.indicatorSeparator} />
}

function DropdownIndicator(props) {
  return props.isDisabled ? (
    <Fragment />
  ) : (
    <components.DropdownIndicator {...props} />
  )
}

const customComponents = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  LoadingMessage,
  IndicatorSeparator,
  DropdownIndicator,
}

function IntegrationReactSelect({
  loadOptions,
  components: propsComponents,
  onChange,
  maxSelectMenuHeight,
  value,
  name,
  async,
  isClearable = true,
  ...remaining
}) {
  const classes = useStyles()

  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  }

  return (
    <NoSsr>
      {async && (
        <AsyncSelect
          test-id={name}
          classes={classes}
          name={name}
          styles={selectStyles}
          loadOptions={loadOptions}
          defaultOptions
          components={customComponents}
          value={value}
          onChange={onChange}
          placeholder=""
          isClearable={isClearable}
          {...remaining}
        />
      )}
      {!async && (
        <Select
          {...remaining}
          classes={classes}
          name={name}
          styles={selectStyles}
          components={customComponents}
          value={value}
          onChange={onChange}
          isSearchable={true}
          hideSelectedOptions
          maxMenuHeight={maxSelectMenuHeight}
          isClearable={isClearable}
        />
      )}
    </NoSsr>
  )
}

IntegrationReactSelect.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default IntegrationReactSelect
