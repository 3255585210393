import { CardMedia, Stack, Typography } from '@mui/material'

interface ScreenHeaderProps {
  title: string
  subtitle?: string
  image: string
}

export default function ScreenHeader({
  title,
  subtitle,
  image,
}: ScreenHeaderProps) {
  return (
    <Stack
      height={175}
      direction="row"
      position="relative"
      justifyContent="space-between"
    >
      <Stack justifyContent="center">
        <Typography variant="h4">{title}</Typography>

        {subtitle && (
          <Typography
            variant="h6"
            sx={{ color: theme => theme.palette.grey[600] }}
          >
            {subtitle}
          </Typography>
        )}
      </Stack>
      <CardMedia
        component="img"
        sx={{
          width: 'fit-content',
          display: { xs: 'none', md: 'inline' },
          objectFit: 'scale-down',
        }}
        src={image}
        alt={title}
      />
    </Stack>
  )
}
