import { Box, Button, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import mixpanel from 'mixpanel-browser'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import leftBarDots from '~/assets/left_bar_dots.png'
import rightBarDots from '~/assets/right_bar_dots.png'
import { type CurrentDraft } from '~/utils/types/current-draft'
import { type CurrentSurvey } from '~/utils/types/current-survey'
import { type User } from '~/utils/types/user'

import Container from './Container'
// Project
import NavIcons from './NavIcons'
import ProgressBar from './ProgressBar'

interface TopTitleContainerProps {
  title?: string
  progressBar?: boolean
  showButton?: boolean
  extraTitleText?: string
}

export default function TitleBar({
  title,
  extraTitleText,
  showButton = true,
  progressBar = false,
}: TopTitleContainerProps) {
  const classes = useStyles()
  const { t } = useTranslation()

  const navigate = useNavigate()
  const user = useSelector((state: { user: User }) => state.user)

  const currentDraft = useSelector(
    (state: { currentDraft: CurrentDraft | null }) => state.currentDraft,
  )
  const currentSurvey = useSelector(
    (state: { currentSurvey: CurrentSurvey }) => state.currentSurvey,
  )

  const [showLeaveModal, setShowLeaveModal] = useState(false)

  return (
    <>
      {showLeaveModal ? (
        <div className={classes.leaveModal}>
          <Typography sx={{ my: '40px' }} variant="h5">
            {t('views.modals.yourLifemapIsNotComplete')}
          </Typography>
          <Box sx={{ display: 'flex' }}>
            <Button
              sx={{ mt: 8, width: '100px' }}
              fullWidth
              onClick={() => {
                setShowLeaveModal(false)
              }}
            >
              {t('general.no')}
            </Button>
            <Button
              sx={{ mt: 8, width: '100px' }}
              color="primary"
              variant="contained"
              fullWidth
              onClick={() => {
                navigate('/surveys')
                mixpanel.track('leave_survey', {
                  env: user.env,
                  role: user.role,
                  draft: currentDraft,
                  user: user.username,
                  survey: currentSurvey.title,
                })
              }}
            >
              {t('general.yes')}
            </Button>
          </Box>
        </div>
      ) : (
        <Container variant="fluid" className={classes.container}>
          {showButton && <NavIcons style={{ position: 'absolute' }} />}
          <Container className={classes.innerContainer}>
            <img className={classes.barDots} src={leftBarDots} alt="Bar Dots" />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {extraTitleText && (
                <Typography
                  variant="subtitle1"
                  className={classes.extraTitleText}
                >
                  {extraTitleText}
                </Typography>
              )}
              <Typography
                variant="h4"
                test-id="title-bar"
                className={classes.titleMainAll}
              >
                {title}
              </Typography>
            </Box>
            <img
              className={classes.barDots}
              src={rightBarDots}
              alt="Bar Dots"
            />
          </Container>
          {progressBar && <ProgressBar />}
        </Container>
      )}
    </>
  )
}

const useStyles = makeStyles(theme => ({
  leaveModal: {
    height: '100vh',
    width: '720px',
    position: 'fixed',
    zIndex: 2,
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  container: {
    backgroundColor: theme.palette.background.default,
    borderBottom: '1px solid #DCDEE3;',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      minHeight: '70%',
    },
  },
  innerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxHeight: 140,
  },
  titleMainAll: {
    margin: 'auto',
    zIndex: 1,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
      lineHeight: 1.2,
    },
  },
  barDots: {
    height: '70%',
    objectFit: 'contain',
    [theme.breakpoints.down('sm')]: {
      height: '50%',
      paddingTop: '20px',
    },
  },
  extraTitleText: {
    textAlign: 'center',
    fontWeight: 400,
    textTransform: 'uppercase',
    color: 'rgba(0,0,0,0.5)',
    marginBottom: 10,
    lineHeight: '25px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      lineHeight: 1.2,
    },
  },
}))
