import { type EconomicSurveyData } from '~/api'
import { type ServerResponse, axios } from '~/axios'
import { type Achievement } from '~/utils/types/current-draft'
import { type IndicatorValue } from '~/utils/types/surveys'

interface Families {
  content: Family[]
  totalPages: number
  totalElements: number
}

export interface Family {
  familyId: number
  name: string
  code: string
  birthDate: number
  documentTypeText: string
  documentNumber: string
  countFamilyMembers: number
  organizationName: string
  anonymous: boolean
}

export async function downloadFamiliesReport({
  hubId,
  organizationIds,
  projectIds,
  facilitatorIds,
  name,
}: {
  hubId: number | null
  organizationIds: number[]
  projectIds: number[]
  facilitatorIds: number[]
  name: string
}) {
  return await axios.post<Blob>(
    '/api/v1/families/familyListReportXslx',
    {
      hub: hubId,
      organizations: organizationIds,
      projects: projectIds,
      facilitators: facilitatorIds,
      name,
    },
    {
      responseType: 'blob',
    },
  )
}

export async function getFamiliesList({
  hubId,
  organizationIds,
  projectIds,
  facilitatorIds,
  name,
  page,
  sortBy,
  sortDirection,
  pageSize = 25,
}: {
  hubId: number | null
  organizationIds: number[]
  projectIds: number[]
  facilitatorIds: number[]
  name: string
  page: number
  sortBy: string | null
  sortDirection: string | null
  pageSize?: number
}) {
  return await axios.post<ServerResponse<{ families: Families | null }>>(
    '/graphql',
    {
      query: /* GraphQL */ `
        query families(
          $facilitatorIds: [Long]
          $hubId: Long
          $organizationIds: [Long]
          $name: String
          $page: Int
          $sortBy: String
          $sortDirection: String
          $projectIds: [Long]
          $pageSize: Int
        ) {
          families(
            facilitators: $facilitatorIds
            hub: $hubId
            organizations: $organizationIds
            name: $name
            projects: $projectIds
            page: $page
            sortBy: $sortBy
            sortDirection: $sortDirection
            pageSize: $pageSize
          ) {
            content {
              familyId
              name
              code
              birthDate
              documentTypeText
              documentNumber
              countFamilyMembers
              organizationName
              anonymous
              user
            }
            totalPages
            totalElements
          }
        }
      `,
      variables: {
        facilitatorIds,
        hubId,
        organizationIds,
        projectIds,
        name,
        page,
        sortBy,
        sortDirection,
        pageSize,
      },
    },
  )
}

export async function deleteFamily(familyId: number) {
  return await axios.delete<null>(`/api/v1/families/user/${familyId}`)
}
export interface SurveysDefinitionByFamilyElement {
  id: number
  title: string
}

export async function surveysDefinitionByFamily(familyId: string) {
  const { data } = await axios.post<
    ServerResponse<{
      surveysDefinitionByFamily: SurveysDefinitionByFamilyElement[]
    }>
  >(
    '/graphql',
    JSON.stringify({
      query:
        'query surveysDefinitionByFamily($family: Long) { surveysDefinitionByFamily (family: $family) { id title  } }',
      variables: {
        family: familyId,
      },
    }),
  )

  return data.data.surveysDefinitionByFamily
}

export interface FamilyByID {
  user: { userId: string; username: string }
  familyId: number
  name: string
  code: string
  latitude: string
  longitude: string
  numberOfSnapshots: number
  lastSnapshot: number
  allowRetake: boolean
  organization: Organization
  country: { country: string }
  project: null | { title: string; id: number }
  familyMemberDTOList: FamilyMembersList[]
  snapshotEconomics: SnapshotEconomic[]
  membersEconomic: MembersEconomic[]
  snapshotIndicators: SnapshotIndicators
}

export interface MembersEconomic {
  memberIdentifier: string
  firstName: string
  economic: SnapshotEconomic[]
}

interface Organization {
  id: number
  name: string
  application: { id: number; name: string }
}

export interface SnapshotEconomic {
  codeName: string
  multipleText: null
  multipleTextArray: null
  multipleValue: null
  multipleValueArray: null
  other: null
  questionText: string
  text: string
  topic: string
  value: string
}

interface SnapshotIndicators {
  createdAt: number
  stoplightSkipped: boolean
  surveyId: number
  indicatorSurveyDataList: IndicatorSurveyDataList[]
  priorities: unknown[]
  achievements: unknown[]
  countRedIndicators: number
  countYellowIndicators: number
  countGreenIndicators: number
  countSkippedIndicators: number
  countDoesNotApplyIndicators: number
  countIndicatorsAchievements: number
  countIndicatorsPriorities: number
  indicatorsPriorities: unknown[]
}

interface IndicatorSurveyDataList {
  value: number
  shortName: string
  dimension: string
  key: string
  snapshotStoplightId: number
}

export async function getFamily(familyId: string) {
  const { data } = await axios.post<ServerResponse<{ familyById: FamilyByID }>>(
    '/graphql',
    JSON.stringify({
      query:
        'query familyById($id: Long) { familyById(id: $id) {user{userId username} familyId name code latitude longitude numberOfSnapshots lastSnapshot allowRetake organization { id, name application{id name} } country{country} project {id title}' +
        'familyMemberDTOList { memberIdentifier firstParticipant firstName lastName gender genderText customGender birthDate documentType documentTypeText customDocumentType documentNumber birthCountry email phoneNumber phoneCode} ' +
        'snapshotEconomics { codeName value multipleValueArray questionText text multipleText multipleTextArray other topic} membersEconomic{ memberIdentifier firstName economic{codeName value multipleValue multipleValueArray questionText text multipleText multipleTextArray other topic} } ' +
        'snapshotIndicators{ createdAt  stoplightSkipped surveyId indicatorSurveyDataList{value shortName dimension key snapshotStoplightId} priorities{key} achievements{key} countRedIndicators countYellowIndicators countGreenIndicators countSkippedIndicators countDoesNotApplyIndicators countIndicatorsAchievements countIndicatorsPriorities indicatorsPriorities{indicator}} }}',
      variables: {
        id: familyId,
      },
    }),
  )

  return data.data.familyById
}

export interface Snapshot {
  snapshotId: number
  surveyDefinition: { id: number; title: string }
  snapshotDate: number
  isLast: boolean
  stoplightDate: null
  stoplightSkipped: boolean
  surveyUser: string
  projectTitle: string
  projectId: null | number
  stoplight: Stoplight[]
  priorities: null
  achievements: Achievement[]
  familyData: FamilyData
  economic: any[]
  membersEconomic: MembersEconomic[]
}

interface FamilyData {
  name: string
  latitude: string
  longitude: string
  familyMembersList: FamilyMembersList[]
}

export interface FamilyMembersList {
  email: string | null
  gender: string
  lastName: string
  firstName: string
  birthDate: number
  phoneNumber: string | null
  phoneCode: string
  genderText: string
  customGender: string | null
  documentType: string
  birthCountry: string
  documentNumber: string
  memberIdentifier: string
  documentTypeText: string
  customDocumentType: string | null
  firstParticipant: boolean
  socioEconomicAnswers: EconomicSurveyData[] | null
  isEmailVerified?: boolean
  emailVerificationCode?: string
}

export interface Stoplight {
  codeName: string
  value: IndicatorValue
  shortName: string
  lifemapName: string
  priority: null
  achievement: null
}

export async function getSnapshotsByFamily(familyId: string) {
  const { data } = await axios.post<
    ServerResponse<{ familySnapshotsOverview: { snapshots: Snapshot[] } }>
  >(
    '/graphql',
    JSON.stringify({
      query:
        'query getSnapshotsByFamily($familyId: Long!) { familySnapshotsOverview (familyId: $familyId) { snapshots { snapshotId surveyDefinition { id title } snapshotDate isLast stoplightDate stoplightSkipped surveyUser projectTitle projectId stoplight {codeName value shortName lifemapName priority achievement } priorities {indicator} achievements {indicator} familyData{ name latitude longitude familyMembersList { memberIdentifier firstParticipant firstName lastName gender genderText customGender birthDate documentType documentTypeText customDocumentType documentNumber birthCountry email phoneNumber phoneCode socioEconomicAnswers{ key value } } } economic {codeName value multipleValueArray questionText text multipleText multipleTextArray other topic} membersEconomic{ memberIdentifier firstName economic{codeName value multipleValue multipleValueArray questionText text multipleText multipleTextArray other topic} } } } }',
      variables: {
        familyId,
      },
    }),
  )

  return data.data.familySnapshotsOverview.snapshots
}
