import { createQueryKeys } from '@lukemorales/query-key-factory'

import store from '~/redux'
import {
  getOrganization,
  getOrganizationAreaTypes,
  getOrganizationEndSurveyTypes,
  getOrganizationFinalUserTypes,
  getOrganizationTypes,
  getOrganizationsWithSubOrgs,
  listOrganizations,
  organizationsByHub,
} from '~/services/organizations-services'
import { type PlatformLanguage } from '~/utils/types/i18n'

const userId = store.getState().user?.id

export const organizationQueries = createQueryKeys('organizations', {
  list: (
    language: PlatformLanguage,
    listOrganizationsParams: Parameters<typeof listOrganizations>[0],
  ) => ({
    queryKey: [language, listOrganizationsParams, { userId }],
    queryFn: async () => await listOrganizations(listOrganizationsParams),
  }),
  listWithSubOrgs: (language: PlatformLanguage, hubId: number) => ({
    queryKey: [language, hubId, { userId }],
    queryFn: async () => await getOrganizationsWithSubOrgs(hubId),
  }),
  detail: (orgId: number) => ({
    queryKey: [orgId, { userId }],
    queryFn: async () => await getOrganization(orgId),
  }),
  types: (language: PlatformLanguage) => ({
    queryKey: [language, { userId }],
    queryFn: getOrganizationTypes,
  }),
  areaTypes: (language: PlatformLanguage) => ({
    queryKey: [language, { userId }],
    queryFn: getOrganizationAreaTypes,
  }),
  finalUserTypes: (language: PlatformLanguage) => ({
    queryKey: [language, { userId }],
    queryFn: getOrganizationFinalUserTypes,
  }),
  endSurveyTypes: (language: PlatformLanguage) => ({
    queryKey: [language, { userId }],
    queryFn: getOrganizationEndSurveyTypes,
  }),
  byHub: (language: PlatformLanguage, hubId?: number) => ({
    queryKey: [language, hubId, { userId }],
    queryFn: async () => await organizationsByHub(hubId ?? null),
  }),
})
