import { createTheme } from '@mui/material/styles'

export const COLORS = {
  //* NOTE: Stoplight Colors
  RED: '#e1504d',
  GREEN: '#50aa47',
  YELLOW: '#f0cb17',
  SKIPPED_GREY: '#E1DDD8',
  DOES_NOT_APPLY_GREY: '#989898',

  LIGHT_GREY: '#E6E4E2',
  GREY: ' #6A6A6A',
  MEDIUM_GREY: '#828282',
  TEXT_GREY: '#6A6A6A',
  TEXT_LIGHTGREY: 'rgba(0,0,0,0.5)',
} as const

const PRIMARY_MAIN = '#309E43'
const PRIMARY_DARK = '#50aa47'
const SECONDARY_COLOR = '#e1504d'

/**
 * @deprecated Access theme using the sx prop (recommended) or from the makeStyles function (discouraged)
 */
export const theme = createTheme({
  palette: {
    primary: { main: PRIMARY_MAIN, dark: PRIMARY_DARK },
    secondary: { main: SECONDARY_COLOR },

    error: { main: SECONDARY_COLOR },

    text: { primary: '#1c212f' },
    background: { paper: '#f3f4f6' },
  },
  typography: {
    fontFamily: "'Poppins', 'Open Sans'",
    body2: { fontSize: 18 },
    h4: { color: '#626262', fontWeight: 600 },
    h5: { color: '#626262', fontWeight: 600 },
    h6: { fontWeight: 400 },
    subtitle1: { color: '#6A6A6A', fontWeight: 500 },
    subtitle2: { color: '#1C212F', fontWeight: 400 },
  },

  components: {
    MuiButton: {
      //* Removes uppercase from buttons
      styleOverrides: { root: { textTransform: 'initial' } },
    },
  },
})

/**
 * @deprecated Access theme using the sx prop or from the makeStyles function
 */
export default theme
