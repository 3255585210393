import { type PropsWithChildren } from 'react'

import { useLocation } from 'react-router-dom'

import { MUIWrapperContext } from '~/components/providers/MuiWrapperProvider'
import { normalizeLanguages } from '~/utils/lang-utils'

export default function LanguageProvider({
  children,
}: PropsWithChildren<object>) {
  const location = useLocation()

  const { changeDirection } = useContext(MUIWrapperContext)

  const { i18n } = useTranslation()

  const urlLanguage = useMemo(() => {
    const queryStrings = new URLSearchParams(location.search)
    const lang = queryStrings.get('lang')
    return lang
  }, [location.search])

  const localLanguage = localStorage
    .getItem('language')
    //* NOTE: Sanitize the language string by removing "" or ''
    ?.replace(/"|'/g, '')

  const browserLanguage = normalizeLanguages(window.navigator.language)

  useEffect(() => {
    const selectedLanguage = urlLanguage ?? localLanguage ?? browserLanguage

    void i18n.changeLanguage(selectedLanguage)
    document.documentElement.lang = selectedLanguage
  }, [urlLanguage, browserLanguage, localLanguage, i18n])

  useEffect(() => {
    const isRightToLeftLanguage = i18n.language === 'ar'
    changeDirection(isRightToLeftLanguage ? 'rtl' : 'ltr')
  }, [changeDirection, i18n.language])

  return <>{children}</>
}
