import React from 'react'

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import { connect } from 'formik'
import { get } from 'lodash'
import PropTypes from 'prop-types'

import { getErrorLabelForPath, pathHasError } from '../utils/form-utils'
import StyledFormLabel from './StyledFormLabel'

const removeByIndex = (array, index) => {
  const prior = array.slice(0, index)
  const following = array.slice(index + 1)
  return [...prior, ...following]
}

const CheckboxWithFormik = ({
  rawOptions,
  label,
  classes,
  name,
  onChange,
  formik,
  required,
}) => {
  const { t } = useTranslation()
  const values = get(formik.values, name) || []
  const error = pathHasError(name, formik.touched, formik.errors)
  const helperText = getErrorLabelForPath(
    name,
    formik.touched,
    formik.errors,
    t,
  )

  const labelProps = { required, error }
  const handleChange = e => {
    const index = values.indexOf(e.target.value)
    if (index === -1) return onChange([...values, e.target.value])
    return onChange(removeByIndex(values, index))
  }

  return (
    <FormControl className={classes.formContainer}>
      <StyledFormLabel component="legend" {...labelProps}>
        {label}
      </StyledFormLabel>

      <Grid container spacing={1}>
        {rawOptions.map(option => (
          <Grid
            item
            xs={6}
            sm={4}
            key={option.value}
            className={classes.gridCentering}
          >
            <FilledFormControlLabel
              control={
                <GreenCheckbox
                  name={name}
                  onChange={e => {
                    handleChange(e)
                  }}
                  checked={values.indexOf(option.value) !== -1}
                />
              }
              label={option.text}
              value={option.value}
            />
          </Grid>
        ))}
      </Grid>
      {error && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormControl>
  )
}

CheckboxWithFormik.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  rawOptions: PropTypes.array.isRequired,
}

const GreenCheckbox = withStyles(theme => ({
  root: {
    color: '#909090',
  },
  checked: {
    color: theme.palette.primary.main,
  },
}))(props => <Checkbox color={'primary'} {...props} />)

const styles = {
  formLabel: {
    color: '#909090',
    marginBottom: 15,
    marginTop: 15,
  },
  gridCentering: {
    display: 'flex',
    alignItems: 'center',
  },
  formContainer: {
    width: '100%',
    marginBottom: 20,
  },
}

const FilledFormControlLabel = withStyles(() => ({
  root: {
    marginLeft: 0,
    minWidth: '100%',
    paddingRight: 15,
    overflowWrap: 'break-word',
  },
  label: {
    maxWidth: '75%',
  },
}))(props => <FormControlLabel color={'primary'} {...props} />)

export default connect(withStyles(styles)(CheckboxWithFormik))
