import { type SexType, faker } from '@faker-js/faker'
import { type PayloadAction, createSlice } from '@reduxjs/toolkit'

import { screenTimer } from '~/utils/survey-utils'
import {
  type CurrentDraft,
  type LifemapNavHistory,
} from '~/utils/types/current-draft'

const draftSlice = createSlice({
  name: 'currentDraft',
  initialState: null as CurrentDraft | null,
  reducers: {
    updateDraft: (_state, action: PayloadAction<CurrentDraft | null>) =>
      action.payload,

    updateNavigationHistory: (
      state,
      action: PayloadAction<LifemapNavHistory[]>,
    ) => {
      if (!state) return

      state.lifemapNavHistory = action.payload
    },

    updateFamilyMemberFieldByIndex: (
      state,
      action: PayloadAction<{ index: number; field: string; value: unknown }>,
    ) => {
      if (!state) return

      state.familyData.familyMembersList[action.payload.index][
        action.payload.field
      ] = action.payload.value
    },

    // @ts-expect-error missing properties
    prepareDraft: (
      _state,
      action: PayloadAction<{
        projectId: number | null
        surveyId: number
        surveyVersionId: number | null
        countryCode: string
        phoneCode: string
        currentLocation: string
      }>,
    ) => {
      return {
        agreedTerms: true,
        project: action.payload.projectId,
        sign: '',
        pictures: [],
        draftId: crypto.randomUUID(),
        surveyId: action.payload.surveyId,
        surveyVersionId: action.payload.surveyVersionId,
        created: Date.now(),
        economicSurveyDataList: [],
        indicatorSurveyDataList: [],
        economicTime: [],
        stoplightTime: [],
        lifemapTime: [],
        priorities: [],
        achievements: [],
        familyData: {
          familyMembersList: [
            {
              firstParticipant: true,
              socioEconomicAnswers: [],
              birthCountry: action.payload.countryCode,
              phoneCode: action.payload.phoneCode,
            },
          ],
        },
        lifemapNavHistory: [{ url: action.payload.currentLocation }],
      }
    },

    // @ts-expect-error missing properties
    prepareDemoDraft: (
      _state,
      action: PayloadAction<{
        projectId: number | null
        draftId: string
        surveyId: number
        surveyVersionId: number | null
        currentLocation: string
        documentTypeValues: string[]
        phoneCodeValues: string[]
      }>,
    ) => {
      type GenderMap = Record<SexType, 'F' | 'M'>

      const genderMap: GenderMap = {
        female: 'F',
        male: 'M',
      }

      const sex = faker.person.sexType()
      const firstName = faker.person.firstName(sex)
      const lastName = faker.person.lastName(sex)

      return {
        agreedTerms: true,
        project: action.payload.projectId,
        sign: '',
        pictures: [],
        draftId: crypto.randomUUID(),
        surveyId: action.payload.surveyId,
        surveyVersionId: action.payload.surveyVersionId,
        created: Date.now(),
        economicSurveyDataList: [],
        indicatorSurveyDataList: [],
        economicTime: [],
        stoplightTime: [],
        lifemapTime: [],
        priorities: [],
        achievements: [],
        familyData: {
          familyMembersList: [
            {
              firstParticipant: true,
              socioEconomicAnswers: [],
              firstName,
              lastName,
              gender: genderMap[sex],
              birthCountry: faker.location.countryCode({ variant: 'alpha-2' }),
              birthDate: Math.floor(
                faker.date.birthdate({ min: 1910 }).getTime() / 1000,
              ),
              documentType: faker.helpers.arrayElement(
                action.payload.documentTypeValues,
              ),
              documentNumber: faker.number
                .int({ min: 1000000, max: 9999999 })
                .toString(),
              phoneCode: faker.helpers.arrayElement(
                action.payload.phoneCodeValues,
              ),
            },
          ],
          countFamilyMembers: 1,
        },
        lifemapNavHistory: [{ url: action.payload.currentLocation }],
      }
    },

    setTimersByScreen: (
      state,
      action: PayloadAction<
        | 'FamilyParticipant'
        | 'FamilyMembersNames'
        | 'Location'
        | 'SocioEconomicQuestion'
        | 'BeginLifemap'
        | 'Help'
        | 'Question'
        | 'Overview'
        | 'Picture'
        | 'Priorities'
        | 'SignIn'
        | 'Final'
        | 'Exit'
      >,
    ) => {
      if (!state) return
      const screenTimes = screenTimer(state, action.payload)

      return { ...state, ...screenTimes }
    },
  },
})

export const {
  updateDraft,
  updateNavigationHistory,
  updateFamilyMemberFieldByIndex,
  prepareDraft,
  prepareDemoDraft,
  setTimersByScreen,
} = draftSlice.actions

export default draftSlice.reducer
