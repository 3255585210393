import React from 'react'

import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { get } from 'lodash'
import { useSnackbar } from 'notistack'
import { connect } from 'react-redux'

import stakeholdersBanner from '../assets/hub.png'
import ActionsPanel from '../components/ActionsPanel'
import ConfirmationModal from '../components/ConfirmationModal'
import Container from '../components/Container'
import ScreenHeader from '../components/ScreenHeader'
import withLayout from '../components/withLayout'
import {
  deleteStakeholder,
  getStakeholderPaginated,
} from '../services/stakeholder-services'
import { canAccess } from '../utils/role-utils'
import FormModal from './stakeholders/StakeholderFormModal'
import StakeholderList from './stakeholders/StakeholdersList'

const useStyles = makeStyles(theme => ({
  mainContainer: {
    backgroundColor: theme.palette.background.paper,
    height: '100%',
    minHeight: '90vh',
  },
}))

/**
 * @typedef {import('../services/stakeholder-services').Stakeholder} Stakeholder
 */

const Stakeholders = ({ user }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [openFormModal, setOpenFormModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [selectedStakeholder, setSelectedStakeholder] = useState(
    /** @type {Stakeholder | null} */ (null),
  )
  const [stakeholders, setStakeHolders] = useState([])
  const [paginationData, setPaginationData] = useState({
    page: 0,
    totalPages: 0,
    totalElements: 0,
    prevPage: 0,
  })

  const toggleFormModal = stakeholder => {
    setSelectedStakeholder(stakeholder)
    setOpenFormModal(!openFormModal)
  }
  const toggleDeleteModal = item => {
    setSelectedStakeholder(item)
    setOpenDeleteModal(!openDeleteModal)
  }

  const loadStakeholders = useCallback(
    (overwrite, items, prevPage, actualPage) => {
      const page = overwrite ? 0 : actualPage
      if (overwrite || page !== prevPage) {
        setLoading(true)
        getStakeholderPaginated(page)
          .then(response => {
            const data = get(response, 'data.data.listStakeholder')

            const stakeholderList = overwrite
              ? data.content
              : [...items, ...data.content]
            setStakeHolders(stakeholderList)
            setPaginationData({
              page,
              totalPages: data.totalPages,
              totalElements: data.totalElements,
              prevPage: page,
            })
          })
          .finally(() => {
            setLoading(false)
          })
      }
    },
    [user],
  )

  useEffect(() => {
    loadStakeholders(true)
  }, [loadStakeholders])

  useEffect(() => {
    if (!loading) {
      loadStakeholders(
        false,
        stakeholders,
        paginationData.prevPage,
        paginationData.page,
      )
    }
  }, [
    loading,
    paginationData.prevPage,
    paginationData.page,
    loadStakeholders,
    stakeholders,
  ])

  const reloadPage = () => {
    loadStakeholders(true)
  }
  const handleDeleteStakeholder = () => {
    setLoading(true)
    if (!selectedStakeholder) return

    deleteStakeholder(selectedStakeholder.id)
      .then(() => {
        toggleDeleteModal({})
        reloadPage()
        enqueueSnackbar(t('views.stakeholder.delete.success'), {
          variant: 'success',
        })
      })
      .catch(() => {
        enqueueSnackbar(t('views.stakeholder.delete.error'), {
          variant: 'error',
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleEditModal = item => {
    toggleFormModal(item)
  }
  const handleDeleteModal = item => {
    toggleDeleteModal(item)
  }

  const canWrite = () => {
    return canAccess(user, 'hubs', 'write')
  }

  const canDelete = () => {
    return canAccess(user, 'hubs', 'delete')
  }

  return (
    <Box className={classes.mainContainer}>
      {selectedStakeholder && (
        <FormModal
          open={openFormModal}
          afterSubmit={reloadPage}
          stakeholder={selectedStakeholder}
          toggleModal={() => {
            setOpenFormModal(!openFormModal)
            setSelectedStakeholder(null)
          }}
        />
      )}
      <ConfirmationModal
        title={t('views.stakeholder.delete.confirmTitle')}
        subtitle={t('views.stakeholder.delete.confirmSubTitle')}
        cancelButtonText={t('general.no')}
        continueButtonText={t('general.yes')}
        onClose={handleDeleteModal}
        disabledFacilitator={loading}
        open={openDeleteModal}
        confirmAction={handleDeleteStakeholder}
      />
      <Container variant="stretch">
        <ScreenHeader
          title={t('views.toolbar.stakeholders')}
          image={stakeholdersBanner}
        />
        <ActionsPanel
          actionText={t('views.stakeholder.addStakeholder')}
          handleClick={() => {
            toggleFormModal({})
          }}
          canWrite={canWrite}
        />
        <StakeholderList
          loading={loading}
          items={stakeholders}
          handleEditModal={handleEditModal}
          handleDeleteModal={handleDeleteModal}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
          canWrite={canWrite}
          canDelete={canDelete}
        />
      </Container>
    </Box>
  )
}

const mapStateToProps = ({ user }) => ({ user })

export default connect(mapStateToProps)(withLayout(Stakeholders))
