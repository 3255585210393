import { Button, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import SignatureCanvas from 'react-signature-canvas'

// Project
import iconPen from '~/assets/pen_icon.png'
import Container from '~/components/Container'
import TitleBar from '~/components/TitleBar'
import { updateDraft } from '~/redux/slices/currentDraft'
import {
  normalizeDraftForSnapshot,
  saveSnapshotDraft,
} from '~/services/lifemap-survey-services'
import { isTimerNum, remakeTimers, screenTimer } from '~/utils/survey-utils'
import { type CurrentDraft } from '~/utils/types/current-draft'
import { type User } from '~/utils/types/user'

export default function LifemapSignature() {
  const classes = useStyles()

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const signaturePad = useRef<SignatureCanvas>(null)

  const [isEmpty, setIsEmpty] = useState(true)
  const [canDisplaySignature, setCanDisplaySignature] = useState(false)

  const user = useSelector((state: { user: User }) => state.user)
  const currentDraft = useSelector(
    (state: { currentDraft: CurrentDraft }) => state.currentDraft,
  )

  const onClear = () => {
    !canDisplaySignature && signaturePad.current?.clear()
    setIsEmpty(true)
    setCanDisplaySignature(false)
  }

  const onSave = () => {
    const normalizedDraft = normalizeDraftForSnapshot(currentDraft)
    void saveSnapshotDraft(normalizedDraft)

    if (!canDisplaySignature) {
      const url = signaturePad.current
        ?.getTrimmedCanvas()
        .toDataURL('image/png')

      dispatch(updateDraft({ ...currentDraft, sign: url }))
    }

    navigate('/lifemap/final')
  }

  useEffect(() => {
    let updatedDraft = { ...currentDraft }

    if (isTimerNum(updatedDraft)) {
      const remakedTimers = remakeTimers(updatedDraft)
      updatedDraft = { ...updatedDraft, ...remakedTimers }
    }

    const screenTimes = screenTimer(updatedDraft, 'SignIn')

    dispatch(updateDraft({ ...updatedDraft, ...screenTimes }))

    if (currentDraft.sign) {
      setIsEmpty(false)
      setCanDisplaySignature(true)
    }
  }, [])

  const allowSumit = () => {
    if (!signaturePad.current?.isEmpty()) {
      setIsEmpty(false)
    }
  }

  return (
    <div>
      <TitleBar title={t('views.sign.addSign')} progressBar />
      <Container className={classes.basicInfo} variant="stretch">
        <div className={classes.iconPriorityBorder}>
          <img
            src={iconPen}
            className={classes.iconPriority}
            alt="Priority icon"
          />
        </div>
      </Container>

      <Container className={classes.basicInfoText} variant="fluid">
        <Typography variant="h5">{t('views.sign.signHere')}</Typography>
      </Container>
      <div className={classes.questionsContainer}>
        {canDisplaySignature ? (
          <img
            src={currentDraft.sign}
            alt="signImg"
            style={{ maxHeight: '25rem', maxWidth: '100%' }}
          />
        ) : (
          <SignatureCanvas
            ref={signaturePad}
            onEnd={allowSumit}
            canvasProps={{ className: classes.canvas }}
          />
        )}
      </div>

      <div className={classes.buttonContainerForm}>
        <Button variant="outlined" onClick={onClear}>
          {t('views.sign.erase')}
        </Button>

        <Button
          color="primary"
          variant="contained"
          onClick={onSave}
          disabled={isEmpty}
        >
          {t('views.sign.continue')}
        </Button>
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  backButton: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    justifyContent: 'center',
    zIndex: 9,
    marginBottom: '3rem',
  },
  typographyStyle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  buttonContainerForm: {
    display: 'flex',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    justifyContent: 'space-evenly',
  },
  confirmationModal: {
    backgroundColor: theme.palette.background.default,
    outline: 'none',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'column',
    padding: '40px 50px',
  },
  questionsContainer: {
    height: '25rem',
    paddingTop: '1%',
    paddingBottom: 0,
    paddingLeft: '9%',
    paddingRight: '9%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  basicInfo: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    justifyContent: 'center',
    zIndex: 9,
    marginTop: '3rem',
    // position: 'relative'
  },
  basicInfoText: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconPriorityBorder: {
    border: '2px solid #FFFFFF',
    borderRadius: '50%',
    backgroundColor: '#FFFFFF',
    minWidth: 90,
    minHeight: 90,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '-2%',
    position: 'relative',
  },
  iconPriority: {
    maxWidth: 50,
    maxHeight: 50,
    objectFit: 'contain',
  },
  modalTitle: {
    paddingBottom: '2rem',
  },
  canvas: {
    height: '25rem',
    width: '100%',
    background: 'rgba(243, 244, 246, 0.558396)',
    borderRadius: '2px',
  },
}))
