import { type Ref } from 'react'

import {
  type FilledInputProps,
  FormControl,
  type InputProps,
  type OutlinedInputProps,
  TextField,
  type TextFieldVariants,
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { type FormikProps, connect } from 'formik'
import { get } from 'lodash'
import { NumericFormat } from 'react-number-format'

import { getErrorLabelForPath, pathHasError } from '~/utils/form-utils'

import StyledFormLabel from './StyledFormLabel'

interface NumberFormatCustomProps {
  inputRef?: Ref<unknown>
  onChange: (e: unknown) => void
}

function NumberFormatCustom({
  inputRef,
  onChange,
  ...other
}: NumberFormatCustomProps) {
  return (
    <NumericFormat
      {...other}
      type="text"
      allowNegative={false}
      getInputRef={inputRef}
      valueIsNumericString={true}
      allowedDecimalSeparators={['.', ',']}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        })
      }}
    />
  )
}

interface InputWithFormikProps {
  formik?: FormikProps<unknown>
  classes?: Record<string, unknown>

  //* NOTE: KNOWN props that are passed directly to TextField
  //* There is high chance that some props are missing here.
  name: string
  label?: string
  required?: boolean
  className?: string
  multiline?: boolean
  placeholder?: string
  notAutoFill?: boolean
  variant?: TextFieldVariants
  type?: 'text' | 'number' | 'password'
  InputProps?:
    | Partial<InputProps>
    | Partial<FilledInputProps>
    | Partial<OutlinedInputProps>
}

function InputWithFormik({
  name,
  label,
  formik,
  required,
  classes = {},
  notAutoFill = false,

  type = 'text',

  ...props
}: InputWithFormikProps) {
  const { t } = useTranslation()

  const value = get(formik?.values, name) || ''
  const error = pathHasError(name, formik?.touched, formik?.errors)

  const helperText = getErrorLabelForPath(
    name,
    formik?.touched,
    formik?.errors,
    t,
  )
  const onBlur = formik?.handleBlur
  const onChange = formik?.handleChange

  const innerProps = {
    name,
    className: classes.input,
    variant: 'filled',
    value,
    error,
    helperText,
    onBlur,
    onChange,
    fullWidth: true,
  }

  const textFieldProps: Record<string, unknown> = {
    ...innerProps,
    //* NOTE: '...props' is very unsafe. Try to not modify this.
    ...props,
  }

  return (
    <FormControl sx={{ width: '100%' }}>
      {!!label && (
        <StyledFormLabel component="legend" required={required} error={error}>
          {label}
        </StyledFormLabel>
      )}
      <TextField
        sx={{ '& legend': { display: 'none' } }}
        {...textFieldProps}
        hiddenLabel
        autoComplete={notAutoFill ? 'new-password' : 'off'}
        InputProps={{
          inputComponent: type === 'number' ? NumberFormatCustom : undefined,
          ...(textFieldProps.InputProps || {}),
        }}
        FormHelperTextProps={{
          sx: {
            fontSize: theme => theme.typography.subtitle1.fontSize,
          },
        }}
      />
    </FormControl>
  )
}

/**
 * @deprecated Migrate your component to react-hook-forms & implement your own text input component.
 */
export default connect(InputWithFormik)
