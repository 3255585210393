import { type ServerResponse, axios } from '~/axios'

export async function getSnapshots(filters) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'query familiesSnapshot( $surveyDefinition: Long!, $hubs: [Long], $orgs: [Long], $surveyUsers: [Long], $locationAvailable: Boolean) { familiesSnapshot ( surveyDefinition: $surveyDefinition, hubs: $hubs, orgs: $orgs, surveyUsers: $surveyUsers,  locationAvailable: $locationAvailable) { page totalElements additionalData content {id familyName familyCode latitude longitude snapshotDate stoplight {codeName value} } } }',
      variables: {
        surveyDefinition: filters.survey?.value ?? 0,
        hubs: filters.hubs,
        orgs: filters.orgs,
        surveyUsers: filters.surveyUsers,
        locationAvailable: true,
      },
    }),
  })
}

export interface OfflineMap {
  center: [number, number]
  from: [number, number]
  id: number
  name: string
  to: [number, number]
}

export async function listOfflineMapsByOrg(organizationId: number) {
  const query = /* GraphQL */ `
    query SearchOfflineMaps($organizationId: Long) {
      searchOfflineMaps(organization: $organizationId) {
        id
        name
        from
        to
        center
      }
    }
  `

  return await axios<ServerResponse<{ searchOfflineMaps: OfflineMap[] }>>({
    method: 'post',
    url: '/graphql',
    data: { query, variables: { organizationId } },
  })
}
