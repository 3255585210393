import { ArrowForward } from '@mui/icons-material'
import { Alert, Button, Container, Stack } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'

import FooterPopup from '~/components/FooterPopup'
import LeaveModal from '~/components/LeaveModal'
import SummaryBarChart from '~/components/SummaryBarChart'
import TitleBar from '~/components/TitleBar'
import DimensionQuestion from '~/components/summary/DimensionQuestion'
import IndicatorsFilter, {
  FILTERED_BY_OPTIONS,
} from '~/components/summary/IndicatorsFilter'
import SummaryDonut from '~/components/summary/SummaryDonut'
import { useAppDispatch, useAppSelector } from '~/redux/hooks'
import { updateDraft } from '~/redux/slices/currentDraft'
import {
  normalizeDraftForSnapshot,
  saveSnapshotDraft,
} from '~/services/lifemap-survey-services'
import { screenTimer, screenValidation } from '~/utils/survey-utils'
import { type IndicatorValue } from '~/utils/types/surveys'

type FilterOptions =
  (typeof FILTERED_BY_OPTIONS)[keyof typeof FILTERED_BY_OPTIONS]

interface UserAnser {
  value: IndicatorValue
  questionText: string
  dimension: string
  key: string
}

export default function Overview() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const dispatch = useAppDispatch()

  const currentDraft = useAppSelector(state => {
    if (!state.currentDraft) throw new Error('No currentDraft')
    return state.currentDraft
  })

  const currentSurvey = useAppSelector(state => {
    if (!state.currentSurvey) throw new Error('No currentSurvey')
    return state.currentSurvey
  })

  const greenIndicatorCount = currentDraft.indicatorSurveyDataList.filter(
    i => i.value === 3,
  ).length
  const yellowIndicatorCount = currentDraft.indicatorSurveyDataList.filter(
    i => i.value === 2,
  ).length
  const redIndicatorCount = currentDraft.indicatorSurveyDataList.filter(
    i => i.value === 1,
  ).length
  const skippedIndicatorCount = currentDraft.indicatorSurveyDataList.filter(
    i => i.value === 0,
  ).length
  const doesNotApplyIndicatorCount =
    currentDraft.indicatorSurveyDataList.filter(i => i.value === 9).length

  const shouldShowFooterPopup =
    currentDraft.priorities.length === 0 &&
    currentDraft.achievements.length === 0

  const [showFooterPopup, setShowFooterPopup] = useState(shouldShowFooterPopup)
  const [userAnswers, setUserAnswers] = useState<UserAnser[]>([])

  const [indicatorFilterValue, setIndicatorFilterValue] =
    useState<FilterOptions>(FILTERED_BY_OPTIONS.ALL)

  const [openModal, setOpenModal] = useState(false)

  function toggleModal() {
    setOpenModal(prev => !prev)
  }

  function handleFooterButtonClick() {
    setShowFooterPopup(false)
  }

  function goToNextView() {
    const { signSupport, pictureSupport } = currentSurvey.surveyConfig

    const normalizedDraft = normalizeDraftForSnapshot(currentDraft)
    void saveSnapshotDraft(normalizedDraft)

    if (
      pictureSupport &&
      !currentDraft.justStoplight &&
      !currentDraft.justStoplightRetake
    ) {
      navigate('/lifemap/upload-pictures')
    } else if (
      signSupport &&
      !currentDraft.justStoplight &&
      !currentDraft.justStoplightRetake
    ) {
      navigate('/lifemap/sign')
    } else {
      navigate('/lifemap/final')
    }
  }

  function finishSurvey() {
    const { minimumPriorities } = currentSurvey

    if (minimumPriorities === 0) {
      goToNextView()
      return
    }

    if (minimumPriorities - currentDraft.priorities.length > 0)
      setOpenModal(true)
    else goToNextView()

    let priorDiff = 0
    currentDraft.indicatorSurveyDataList.forEach(elem => {
      if (elem.value === 2 || elem.value === 1) priorDiff += 1
    })

    if (
      minimumPriorities -
        minimumPriorities +
        priorDiff -
        currentDraft.priorities.length ===
      0
    ) {
      goToNextView()
    }
  }

  const footerTitle = useMemo(() => {
    if (!currentDraft.previousIndicatorPriorities) {
      return t('views.overview.footerTitleCase1')
    }

    if (currentDraft.previousIndicatorPriorities.length > 0) {
      const prevIndicatorPriorities =
        currentDraft.previousIndicatorPriorities.length.toString()
      const titleValue = t('views.overview.footerTitleCase2').replace(
        'XX',
        prevIndicatorPriorities,
      )
      return titleValue
    }

    return t('views.overview.footerTitleCase3')
  }, [currentDraft.previousIndicatorPriorities, t])

  const footerDescription = useMemo(() => {
    if (!currentDraft.previousIndicatorPriorities) {
      return t('views.overview.footerDescriptionCase1')
    }

    if (currentDraft.previousIndicatorPriorities.length > 0) {
      return t('views.overview.footerDescriptionCase2')
    }

    return t('views.overview.footerDescriptionCase3')
  }, [currentDraft.previousIndicatorPriorities, t])

  function pushIndicator(indicator: UserAnser) {
    if (indicator.value === 9 || indicator.value === 0) return

    let forward = 'skipped-indicator'
    if (indicator.value) {
      forward = indicator.value === 3 ? 'achievement' : 'priority'
    }
    navigate(`/lifemap/${forward}/${indicator.key}`)
  }

  useEffect(() => {
    const validationResult = screenValidation(
      currentDraft,
      'OVERVIEW',
      currentSurvey,
    )

    if (validationResult) {
      enqueueSnackbar(t('validation.completionRequired'), { variant: 'error' })
      navigate(validationResult)
    }

    const screenTimes = screenTimer(currentDraft, 'Overview')
    dispatch(updateDraft({ ...currentDraft, ...screenTimes }))
  }, [dispatch, currentSurvey, enqueueSnackbar, navigate, t])

  useEffect(() => {
    const answers: UserAnser[] = []

    if (!currentSurvey) return

    currentSurvey.surveyStoplightQuestions.forEach(e => {
      currentDraft.indicatorSurveyDataList.forEach(ele => {
        if (e.codeName === ele.key) {
          answers.push({
            value: ele.value,
            questionText: e.questionText,
            dimension: e.dimension,
            key: ele.key,
          })
        }
      })
    })

    setUserAnswers(answers)
  }, [currentDraft.indicatorSurveyDataList, currentSurvey])

  return (
    <>
      <LeaveModal
        singleAction
        open={openModal}
        onClose={toggleModal}
        leaveAction={toggleModal}
        title={t('views.lifemap.toComplete')}
        continueButtonText={t('general.gotIt')}
        subtitle={`${t('general.create')} ${
          currentSurvey.minimumPriorities -
          (currentDraft.priorities || []).length
        } ${
          currentSurvey.minimumPriorities -
            (currentDraft.priorities || []).length !==
          1
            ? t('views.lifemap.priorities').toLowerCase()
            : t('views.lifemap.priority').toLowerCase()
        }`}
      />
      <TitleBar title={t('views.yourLifeMap')} progressBar />

      <Container>
        <Stack gap={2} py={2}>
          <Stack
            direction="row"
            alignItems="center"
            flexWrap={{ xs: 'wrap', md: 'nowrap' }}
          >
            <SummaryDonut
              greenIndicatorCount={greenIndicatorCount}
              redIndicatorCount={redIndicatorCount}
              yellowIndicatorCount={yellowIndicatorCount}
              skippedIndicatorCount={skippedIndicatorCount}
              doesNotApplyIndicatorCount={doesNotApplyIndicatorCount}
              isAnimationActive={false}
              countingSection={false}
              width="100%"
              height={160}
            />
            <SummaryBarChart
              greenIndicatorCount={greenIndicatorCount}
              redIndicatorCount={redIndicatorCount}
              yellowIndicatorCount={yellowIndicatorCount}
              skippedIndicatorCount={skippedIndicatorCount}
              doesNotApplyIndicatorCount={doesNotApplyIndicatorCount}
              isAnimationActive={false}
              width="100%"
            />
          </Stack>
          <IndicatorsFilter
            greenIndicatorCount={greenIndicatorCount}
            yellowIndicatorCount={yellowIndicatorCount}
            redIndicatorCount={redIndicatorCount}
            skippedIndicatorCount={skippedIndicatorCount}
            doesNotApplyIndicatorCount={doesNotApplyIndicatorCount}
            filterValue={indicatorFilterValue}
            onFilterChanged={(newValue: FilterOptions) => {
              setIndicatorFilterValue(newValue)
            }}
          />
          <Alert
            severity="info"
            sx={{
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            {t('views.lifemap.createPriority')}
          </Alert>
          <DimensionQuestion
            previousIndicators={currentDraft.previousIndicatorSurveyDataList}
            previousPriorities={currentDraft.previousIndicatorPriorities}
            previousAchivements={currentDraft.previousIndicatorAchivements}
            priorities={currentDraft.priorities}
            achievements={currentDraft.achievements}
            mustShowPointer={true}
            onClickIndicator={pushIndicator}
            isRetake={currentDraft.isRetake}
            questions={userAnswers.filter(
              ind =>
                indicatorFilterValue === FILTERED_BY_OPTIONS.ALL ||
                ind.value === indicatorFilterValue,
            )}
          />
          {!showFooterPopup && (
            <Stack direction="row" justifyContent="center">
              <Button
                color="primary"
                test-id="continue"
                variant="contained"
                onClick={finishSurvey}
                endIcon={<ArrowForward />}
              >
                {t('general.continue')}
              </Button>
            </Stack>
          )}
        </Stack>
      </Container>
      <FooterPopup
        title={footerTitle}
        isOpen={showFooterPopup}
        description={footerDescription}
        handleButtonClick={handleFooterButtonClick}
        buttonText={t('views.overview.priorityFooterButton')}
      />
    </>
  )
}
