import { Close } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { type SnackbarKey, SnackbarProvider, useSnackbar } from 'notistack'

import PasswordWarningSnackbar from '~/components/snackbars/PasswordWarningSnackbar'
// Project
import { COLORS } from '~/theme'

interface CustomSnackbarProviderProps {
  children: JSX.Element | JSX.Element[]
}

export default function CustomSnackbarProvider({
  children,
}: CustomSnackbarProviderProps) {
  const classes = useStyles()

  return (
    <SnackbarProvider
      maxSnack={5}
      preventDuplicate
      autoHideDuration={7000}
      action={key => <DismissAction id={key} />}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      classes={{
        containerRoot: classes.contentRoot,

        // @ts-expect-error
        variantError: classes.error,
        variantSuccess: classes.success,
        variantWarning: classes.warning,
      }}
      //* NOTE: Custom Components
      Components={{
        passwordWarning: PasswordWarningSnackbar,
      }}
    >
      {children}
    </SnackbarProvider>
  )
}

interface DismissActionProps {
  id: SnackbarKey
}

function DismissAction({ id }: DismissActionProps) {
  const { closeSnackbar } = useSnackbar()
  return (
    <IconButton
      key="dismiss"
      onClick={() => {
        closeSnackbar(id)
      }}
      size="large"
    >
      <Close style={{ color: 'white' }} />
    </IconButton>
  )
}

const useStyles = makeStyles(theme => ({
  contentRoot: {
    flexWrap: 'nowrap',
    '& .MuiSnackbarContent-message': {
      [theme.breakpoints.down('xl')]: {
        width: '80%',
      },
    },
  },
  success: { backgroundColor: COLORS.GREEN },
  error: { backgroundColor: COLORS.RED },
  warning: { backgroundColor: COLORS.YELLOW },
}))
