import { Button, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { type FileWithPath, useDropzone } from 'react-dropzone'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import BottomSpacer from '~/components/BottomSpacer'
import Container from '~/components/Container'
// Project
import FileForm from '~/components/FileForm'
import TitleBar from '~/components/TitleBar'
import { updateDraft } from '~/redux/slices/currentDraft'
import {
  normalizeDraftForSnapshot,
  saveSnapshotDraft,
} from '~/services/lifemap-survey-services'
import {
  SUPPORTED_IMAGE_MIME_TYPES,
  toReactDropzoneSchema,
} from '~/utils/allowedFileExtensions'
import { isTimerNum, remakeTimers, screenTimer } from '~/utils/survey-utils'
import { type CurrentDraft } from '~/utils/types/current-draft'
import { type CurrentSurvey } from '~/utils/types/current-survey'
import { type User } from '~/utils/types/user'

type MyFile = NonNullable<Pick<CurrentDraft, 'pictures'>['pictures']>

export default function UploadPictures() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const user = useSelector((state: { user: User }) => state.user)
  const currentDraft = useSelector(
    (state: { currentDraft: CurrentDraft }) => state.currentDraft,
  )
  const currentSurvey = useSelector(
    (state: { currentSurvey: CurrentSurvey }) => state.currentSurvey,
  )

  const redirectUrl = currentSurvey.surveyConfig.signSupport
    ? '/lifemap/sign'
    : '/lifemap/final'

  const [size, setSize] = useState(0)
  const [hasError, setHasError] = useState(false)
  const [myFiles, setMyFiles] = useState<MyFile>([])

  useEffect(() => {
    dispatch(
      updateDraft({
        ...currentDraft,
        pictures: myFiles,
      }),
    )
  }, [myFiles])

  useEffect(() => {
    let updatedDraft = { ...currentDraft }

    if (isTimerNum(updatedDraft)) {
      const remakedTimers = remakeTimers(updatedDraft)
      updatedDraft = { ...updatedDraft, ...remakedTimers }
    }

    const screenTimes = screenTimer(updatedDraft, 'Picture')
    dispatch(updateDraft({ ...updatedDraft, ...screenTimes }))

    if (currentDraft.pictures && currentDraft.pictures?.length > 0) {
      const picturesWithoutUrls = currentDraft.pictures.map(picture => {
        delete picture.url
        return picture
      })

      setMyFiles(picturesWithoutUrls)
    }
  }, [])

  async function toBase64(file) {
    return await new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        resolve(reader.result)
      }
      reader.onerror = error => {
        reject(error)
      }
    })
  }

  async function onDrop<T extends FileWithPath>(acceptedFiles: T[]) {
    let dropSize = 0

    try {
      const pictures = await Promise.all(
        acceptedFiles.map(async file => {
          //! NOTE: I've tried to type this but couldn't figure out
          // the correct type. If you believe you can correct this. Please do!
          const image: any = {}

          image.url = window.URL.createObjectURL(file)

          const base64File = await toBase64(file)
          image.key = new Date().getTime()
          image.path = file.path
          image.fileSize = file.size

          dropSize += file.size

          image.base64 = {
            content: base64File,
            name: file.name,
            type: file.type,
          }
          return image
        }),
      )

      if (size + dropSize <= 10485760) {
        setMyFiles([...myFiles, ...pictures])
        setHasError(false)
        setSize(size + dropSize)
      } else {
        setHasError(true)
      }
    } catch (error) {
      enqueueSnackbar(t('general.error'), {
        variant: 'error',
      })
      console.error('PSP Platform', error)
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    onDrop,
    multiple: true,
    maxSize: 10485760,
    onDropRejected: () => {
      setHasError(true)
    },
    accept: toReactDropzoneSchema(SUPPORTED_IMAGE_MIME_TYPES),
  })

  function removeItem(key: string) {
    let deletedSize = 0

    const updatedFiles = myFiles.filter(file => {
      if (file.key === key && file.fileSize) deletedSize = file.fileSize
      return file.key !== key
    })

    updatedFiles ? setMyFiles([...updatedFiles]) : setMyFiles([])

    setHasError(false)
    setSize(size - deletedSize)
  }

  function handleSave() {
    const normalizedDraft = normalizeDraftForSnapshot(currentDraft)
    void saveSnapshotDraft(normalizedDraft)

    navigate(redirectUrl)
  }

  return (
    <div>
      <TitleBar title={t('views.yourPictures')} progressBar />
      <br />
      <Container variant="stretch" style={{ textAlign: 'center' }}>
        <FileForm
          acceptedFiles={myFiles}
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          removeItem={removeItem}
          t={t}
        />
        {hasError && (
          <Typography color="error">{t('views.fileUploadError')}</Typography>
        )}
        <Button
          variant="contained"
          test-id="continue"
          color="primary"
          onClick={() => {
            handleSave()
          }}
          style={{ color: 'white' }}
        >
          {t('general.continue')}
        </Button>
        <BottomSpacer />
      </Container>
    </div>
  )
}
