import { Link, Stack, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

import { version } from '../package.json'

export default function Footer() {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const localeForURL = language === 'es' ? 'es' : 'en'
  const termsConditionsURL = `https://www.povertystoplight.org/${localeForURL}/terms-and-conditions`
  const privacyURL = `https://www.povertystoplight.org/${localeForURL}/privacy-policy`

  return (
    <Stack
      px={2}
      py={1}
      gap={2}
      direction="row"
      alignItems="center"
      flexWrap={{ xs: 'wrap', md: 'nowrap' }}
      justifyContent={{ xs: 'center', md: 'space-between' }}
    >
      <Typography fontSize={12}>
        ©Copyright 2020 - Stoplight Platform {version}
      </Typography>

      <Stack
        gap={2}
        direction="row"
        alignSelf="flex-end"
        justifyContent="center"
        flexWrap={{ xs: 'wrap', md: 'nowrap' }}
      >
        <Link target="_blank" to={termsConditionsURL} component={RouterLink}>
          {t('views.termsConditions')}
        </Link>
        <Link target="_blank" to={privacyURL} component={RouterLink}>
          {t('views.privacyPolicy')}
        </Link>
      </Stack>
    </Stack>
  )
}
