import {
  Notifications as NotificationsIcon,
  Schedule as ScheduleIcon,
} from '@mui/icons-material'
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  IconButton,
  ListItem,
  Menu,
  MenuItem,
  Skeleton,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import { useInfiniteQuery, useMutation } from '@tanstack/react-query'
import { Trans } from 'react-i18next'

import {
  type Notification,
  getAllNotificationsByUser,
  markNotificationsAsRead,
} from '~/services/notifications'
import { formatDate } from '~/utils/format-date'

const StyledToggleButton = withStyles(theme => ({
  root: {
    '&$selected': {
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
    },
  },
  selected: {},
}))(ToggleButton)

const StyledMenuItem = withStyles({
  root: {
    '& strong': {
      fontWeight: '500',
    },
    whiteSpace: 'normal',
    width: 380,
    paddingTop: 12,
    paddingBottom: 12,
    fontSize: 14,
  },
})(MenuItem)

const StyledListItem = withStyles({
  root: {
    whiteSpace: 'normal',
    width: 380,
    paddingTop: 12,
    paddingBottom: 12,
    fontSize: 14,
  },
})(ListItem)

const NOTIFICATION_CODES: Record<Notification['code'], string> = {
  surveyStoplightNoteAdded: 'notifications.codes.surveyStoplightNoteAdded',
  indicatorSuggestedChange: 'notifications.codes.indicatorSuggestedChange',
}

const FIVE_MINUTES_IN_MS = 5 * 60 * 1000

export default function NotificationsMenu() {
  const { t } = useTranslation()

  const [hasNotificationsToRead, setHasNotificationsToRead] = useState(false)
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null)
  /*
   * NOTE: The default is to show "Unread" notifications now but will keep the
   * `showAll` name because the backend uses that name and I want things to be
   * easy to map mentally.
   */
  const [showAll, setShowAll] = useState(false)
  const [markedAsRead, setMarkedAsRead] = useState<number[]>([])

  const notifications = useInfiniteQuery(
    ['notifications', 'list', { showAll }],
    async ({ pageParam = 0 }) =>
      await getAllNotificationsByUser({
        page: pageParam,
        size: 25,
        showAll,
      }),
    {
      refetchInterval: FIVE_MINUTES_IN_MS,
      getNextPageParam: lastPage =>
        lastPage.page + 1 < lastPage.totalPages ? lastPage.page + 1 : null,
      onSuccess: data => {
        if (anchorEl ?? !showAll) {
          return
        }

        const hasUnreadNotifications = data.pages[0].content.some(
          notification => !notification.read,
        )

        if (!hasUnreadNotifications) {
          return
        }

        setHasNotificationsToRead(true)
      },
    },
  )

  const markAsRead = useMutation({
    mutationKey: ['notifications', 'markAsRead', markedAsRead],
    mutationFn: async () => await markNotificationsAsRead(markedAsRead),
  })

  function checkIfNotificationIsInListToBeMarked(id: number) {
    return markedAsRead.some(readId => readId === id)
  }

  function handleChangeFilter(_event, newValue: boolean | null) {
    // enforcing that the filter have a value
    if (newValue === null) {
      return
    }

    setShowAll(newValue)
  }

  function handleClickMenuItem(_event, notification: Notification) {
    const alreadyInList = checkIfNotificationIsInListToBeMarked(notification.id)

    if (alreadyInList || notification.read) {
      return
    }

    setMarkedAsRead([...markedAsRead, notification.id])
  }

  function handleCloseMenu(_event) {
    setAnchorEl(null)
    setHasNotificationsToRead(false)

    if (markedAsRead.length) {
      markAsRead.mutate()
    }
  }

  return (
    <>
      <IconButton
        color="default"
        onClick={event => {
          setAnchorEl(anchorEl ? null : event.currentTarget)
        }}
        aria-controls="notifications"
        aria-haspopup="menu"
        aria-label={t('notifications.aria.iconButton')}
        size="large"
      >
        <Badge
          color="primary"
          variant="dot"
          invisible={!hasNotificationsToRead}
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>

      <Menu
        id="notifications"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {!notifications.isLoading && (
          <StyledListItem style={{ justifyContent: 'center' }}>
            <ToggleButtonGroup
              value={showAll}
              exclusive
              onChange={handleChangeFilter}
              aria-label="filters"
            >
              <StyledToggleButton value={false} aria-label="unread">
                {t('notifications.filters.unread')}
              </StyledToggleButton>
              <StyledToggleButton
                value={true}
                aria-label={t('notifications.filters.all')}
              >
                {t('notifications.filters.all')}
              </StyledToggleButton>
            </ToggleButtonGroup>
          </StyledListItem>
        )}

        {notifications.isLoading &&
          [...Array(12)].map((_item, index) => (
            <StyledListItem key={index}>
              <div style={{ width: '100%' }}>
                <Skeleton animation="wave" variant="text" width="100%" />
                <Skeleton animation="wave" variant="text" width="80%" />
              </div>
            </StyledListItem>
          ))}

        {notifications.isSuccess &&
          notifications.data.pages[0].content.length === 0 && (
            <StyledListItem>{t('notifications.messages.empty')}</StyledListItem>
          )}

        {notifications.isSuccess &&
          notifications.data.pages.map(group => [
            group.content.length > 0 &&
              group.content.map(notification => (
                <StyledMenuItem
                  key={notification.id}
                  onClick={event => {
                    handleClickMenuItem(event, notification)
                  }}
                >
                  <Box>
                    <Trans
                      i18nKey={NOTIFICATION_CODES[notification.code]}
                      values={{
                        createdBy: notification.createdBy,
                        message: notification.message,
                      }}
                      components={{ bold: <strong /> }}
                    />
                    <Typography sx={{ fontSize: 12 }}>
                      <ScheduleIcon
                        sx={{
                          width: 14,
                          height: 'auto',
                          verticalAlign: 'sub',
                        }}
                      />{' '}
                      {formatDate(new Date(notification.createdAt), {
                        dateStyle: 'full',
                        timeStyle: 'short',
                      })}
                    </Typography>
                  </Box>
                  <div style={{ marginLeft: 10 }}>
                    {!checkIfNotificationIsInListToBeMarked(notification.id) &&
                      !notification.read && (
                        <Box
                          sx={{
                            width: 10,
                            height: 10,
                            background: theme => theme.palette.primary.main,
                            borderRadius: '50%',
                          }}
                        />
                      )}
                  </div>
                </StyledMenuItem>
              )),
          ])}

        {notifications.hasNextPage && (
          <StyledListItem style={{ justifyContent: 'center' }}>
            {!notifications.isFetchingNextPage ? (
              <Button
                variant="contained"
                color="primary"
                onClick={async () => await notifications.fetchNextPage()}
              >
                {t('general.showMore')}
              </Button>
            ) : (
              <CircularProgress />
            )}
          </StyledListItem>
        )}
      </Menu>
    </>
  )
}
