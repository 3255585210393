import {
  type AllHubUsersByHubResponse,
  type AllPSTeamUsersResponse,
} from '~/utils/types/user'

import { type ServerResponse, axios } from '../axios'

/**
 * Get all the team users
 */
export async function getAllPSTeamUsers() {
  const response = await axios.post<AllPSTeamUsersResponse>('/graphql', {
    query: 'query { listAllPsTeamUsers { id username role } }',
  })

  return response.data.data.listAllPsTeamUsers
}

/**
 * Get all the hub users
 * @param hubs The list of hubs to list his users
 */
export async function getAllHubUsersByHub(hubs: number[] = []) {
  const response = await axios.post<AllHubUsersByHubResponse>('/graphql', {
    query:
      'query listAllHubUsersByHub($hubs: [Long]) { listAllHubUsersByHub(hubs: $hubs) { id username role } }',
    variables: {
      hubs,
    },
  })

  return response.data.data.listAllHubUsersByHub
}

/**
 * Assign a list of users to a survey
 * @param usersToAssign The list of users id to assign
 * @param survey The id of the survey
 */
export async function assignUsersToSurvey(
  usersToAssign: number[],
  survey: number,
) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation assignUsersToSurveyDefinition($users: [Long],$surveyDefinition: Long) { assignUsersToSurveyDefinition (users:$users, surveyDefinition:$surveyDefinition) {  successful  } }',
      variables: {
        users: usersToAssign,
        surveyDefinition: survey,
      },
    }),
  })
}

export interface Notification {
  id: number
  user: number
  username: string
  createdAt: string
  createdBy: string
  code: 'indicatorSuggestedChange' | 'surveyStoplightNoteAdded'
  message: string[]
  read: boolean
}

export interface NotificationsByUser {
  content: Notification[]
  page: number
  totalElements: number
  totalPages: number
}

/**
 * Get all the notifications for the current user
 * @param params
 */
export async function getAllNotificationsByUser(
  params = {
    page: 0,
    size: 25,
    showAll: true,
  },
) {
  const response = await axios<
    ServerResponse<{ listAllNotificationsByUserPaginated: NotificationsByUser }>
  >({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'query listAllNotificationsByUserPaginated ($page: Int, $size: Int, $showAll: Boolean) { listAllNotificationsByUserPaginated (page: $page, size: $size, showAll: $showAll) { content {id user username createdAt createdBy code message read } page totalElements totalPages} }',
      variables: params,
    }),
  })

  return response.data.data.listAllNotificationsByUserPaginated
}

/**
 * Mark a list of notifications as read
 * @param notifications The list of notifications id to mark as read
 */
export async function markNotificationsAsRead(notifications: number[]) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation markNotificationsAsRead($notifications : [Long]) { markNotificationsAsRead(notifications:$notifications) { successful } }',
      variables: { notifications },
    }),
  })
}
