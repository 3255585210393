import { type ReactNode } from 'react'

import {
  ArrowForward as ArrowForwardIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  CardMedia,
  CircularProgress,
  Modal,
  Typography,
} from '@mui/material'
import { Stack } from '@mui/system'

import modalImage from '~/assets/serious_face.png'

interface ConfirmationModalProps {
  open: boolean

  title: string
  subtitle: ReactNode
  loading?: boolean

  continueButtonText?: string
  cancelButtonText?: string

  /**
   * @deprecated Use `loading` prop instead
   */
  disabledFacilitator?: boolean

  onClose: (action: { submitted: boolean }) => void
  confirmAction: () => void
}

export default function ConfirmationModal({
  open,

  title,
  loading,
  subtitle,
  continueButtonText,
  cancelButtonText,

  disabledFacilitator = false,

  onClose,
  confirmAction,
}: ConfirmationModalProps) {
  return (
    <Modal
      open={open}
      onClose={() => {
        if (loading ?? disabledFacilitator) return
        onClose({ submitted: false })
      }}
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Stack
        sx={{
          p: 2,
          borderRadius: 5,
          width: { xs: '90vw', md: 400 },
          minHeight: '40vh',
          maxHeight: 500,
          bgcolor: theme => theme.palette.background.default,
          overflow: 'auto',
        }}
        alignItems="center"
        gap={2}
      >
        <Typography
          variant="h5"
          sx={{ wordWrap: 'break-word', width: '100%' }}
          textAlign="center"
        >
          {title}
        </Typography>

        <CardMedia
          component="img"
          src={modalImage}
          sx={{
            width: 'auto',
            height: 100,
          }}
        />

        <Typography variant="body2" textAlign="center">
          {subtitle}
        </Typography>

        {(disabledFacilitator || loading) && <CircularProgress />}

        <Stack flexDirection="row" gap={2} mt="auto">
          <Button
            color="error"
            onClick={() => {
              onClose({ submitted: false })
            }}
            variant="contained"
            startIcon={<CancelIcon />}
            disabled={disabledFacilitator || loading}
          >
            {cancelButtonText ?? 'Cancel'}
          </Button>

          <LoadingButton
            color="primary"
            loading={loading}
            variant="outlined"
            onClick={confirmAction}
            endIcon={<ArrowForwardIcon />}
            disabled={disabledFacilitator}
          >
            {continueButtonText ?? 'Continue'}
          </LoadingButton>
        </Stack>
      </Stack>
    </Modal>
  )
}
