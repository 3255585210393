import { type ServerResponse, axios } from '~/axios'
import { type PlatformLanguage } from '~/utils/types/i18n'

interface StakeholderInput {
  name: string
  description: string
  active: boolean
  country: string
  language: string
  logoUrl: string | null
  stakeholderType?: string
  areaOfExpertise?: string
}

export async function createStakeholder(values: StakeholderInput) {
  const query = /* GraphQL */ `
    mutation createStakeholder($stakeholder: StakeholderModelInput) {
      createStakeholder(stakeholder: $stakeholder) {
        successful
      }
    }
  `

  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query,
      variables: {
        stakeholder: {
          name: values.name,
          description: values.description,
          active: values.active,
          country: values.country,
          logoUrl: values.logoUrl,
          language: values.language,
          stakeholderType: values.stakeholderType,
          areaOfExpertise: values.areaOfExpertise,
        },
      },
    }),
  })
}

export async function updateStakeholder(
  values: StakeholderInput & { id: number },
) {
  const query = /* GraphQL */ `
    mutation updateStakeholder($stakeholder: StakeholderModelInput) {
      updateStakeholder(stakeholder: $stakeholder) {
        successful
      }
    }
  `

  return await axios<
    ServerResponse<{ updateStakeholder: { successful: boolean } }>
  >({
    method: 'post',
    url: '/graphql',
    data: {
      query,
      variables: {
        stakeholder: {
          id: values.id,
          name: values.name,
          description: values.description,
          active: values.active,
          country: values.country,
          logoUrl: values.logoUrl,
          language: values.language,
          stakeholderType: values.stakeholderType,
          areaOfExpertise: values.areaOfExpertise,
        },
      },
    },
  })
}

export interface Stakeholder {
  id: number
  name: string
  description: string
  language: string
  active: boolean
  country: string
  stakeholderType: null
  areaOfExpertise: null
  logoUrl: string | null
}

export async function getStakeholderPaginated(page: number) {
  const query = /* GraphQL */ `
    query ListStakeholder($page: Int) {
      listStakeholder(page: $page) {
        content {
          id
          name
          description
          language
          active
          country
          stakeholderType
          logoUrl
          areaOfExpertise
        }
        totalPages
        totalElements
      }
    }
  `

  return await axios<ServerResponse<{ listStakeholder: Stakeholder[] }>>({
    method: 'post',
    url: '/graphql',
    data: { query, variables: { page } },
  })
}

export const stakeholderListByUser = async () => {
  interface ListStakeholdersByUserResponse {
    id: number
    name: string
    description: string
    language: PlatformLanguage
    active: boolean
    country: string
    stakeholderType: string | null
    logoUrl: string | null
    areaOfExpertise: string | null
  }

  return await axios<
    ServerResponse<{ listStakeholdersByUser: ListStakeholdersByUserResponse[] }>
  >({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'query { listStakeholdersByUser {id name description language active country stakeholderType logoUrl areaOfExpertise } }',
    }),
  })
}

export const deleteStakeholder = async id =>
  await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation deleteStakeholder($id: Long) { deleteStakeholder (id:$id) { successful} }',
      variables: {
        id,
      },
    }),
  })
