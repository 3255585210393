import { OutlinedInput, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { connect } from 'formik'
import { get } from 'lodash'

import { getErrorLabelForPath, pathHasError } from '../utils/form-utils'

const useInputWithLabelStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start',
  },
  label: {
    marginRight: 10,
    marginBottom: 5,
    fontSize: 14,
  },
  field: {
    width: '-webkit-max-content',
  },
  outlinedInputContainer: {
    marginBottom: 20,
    backgroundColor: theme.palette.background.default,
  },
  outlinedInput: {
    maxHeight: 300,
  },
  disabledInput: {
    maxHeight: 'none',
  },
  disabled: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
}))

const InputWithLabel = ({
  title,
  multiline,
  inputProps,
  formik,
  name,
  minHeight,
  required,
  disabled,
  customClasses = {},
  ...props
}) => {
  const value = get(formik.values, name) || ''
  const error = pathHasError(name, formik.touched, formik.errors)
  const { t } = useTranslation()
  const helperText = getErrorLabelForPath(
    name,
    formik.touched,
    formik.errors,
    t,
  )
  const onBlur = formik.handleBlur
  const onChange = formik.handleChange

  const classes = useInputWithLabelStyles()
  const { root = '', input = '', disabledClass = '' } = customClasses
  return (
    <div className={classes.container}>
      <Typography variant="subtitle1" className={classes.label}>
        {title} {required ? '*' : ''}
      </Typography>
      <OutlinedInput
        name={name}
        classes={{
          root: clsx(classes.outlinedInputContainer, root),
          input: clsx(
            classes.outlinedInput,
            input,
            disabled && classes.disabledInput,
          ),
          disabled: clsx(classes.disabled, disabledClass),
        }}
        placeholder={helperText}
        multiline={multiline}
        minRows={multiline && props.minRows ? props.minRows : 2}
        value={value}
        inputProps={inputProps}
        onChange={onChange}
        onBlur={onBlur}
        fullWidth={true}
        margin="dense"
        error={error}
        disabled={disabled}
        sx={{ minHeight }}
        {...props}
      />
    </div>
  )
}

export default connect(InputWithLabel)
