import { type Dispatch, type RefObject, type SetStateAction } from 'react'

import { Close, Download } from '@mui/icons-material'
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Card,
  Divider,
  Modal,
  Stack,
  Typography,
} from '@mui/material'
import { t } from 'i18next'
import { useSnackbar } from 'notistack'
import QRCode from 'react-qr-code'

interface QrCodeModalProps {
  title: string
  qrCodeValue: string
  isModalOpen: boolean
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
}

function downloadQR(qrRef: RefObject<HTMLElement>, fileName: string) {
  if (!qrRef.current) throw new Error('QR Element not found')

  const svgData = new XMLSerializer().serializeToString(qrRef.current)

  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  const img = new Image()

  if (!ctx) throw new Error('Canvas not found')

  img.onload = function () {
    canvas.width = img.width
    canvas.height = img.height
    ctx.drawImage(img, 0, 0)

    const pngFile = canvas.toDataURL('image/png')
    const downloadLink = document.createElement('a')

    downloadLink.download = fileName
    downloadLink.href = `${pngFile}`
    downloadLink.click()
  }

  img.src = 'data:image/svg+xml;base64,' + window.btoa(svgData)
}

export default function QrCodeModal({
  title,
  qrCodeValue,
  isModalOpen,
  setIsModalOpen,
}: QrCodeModalProps) {
  const { enqueueSnackbar } = useSnackbar()
  const qrCodeRef = useRef<HTMLElement>(null)

  function handleOnQrDownload() {
    try {
      downloadQR(qrCodeRef, `${title} - QR Code`)

      enqueueSnackbar(t('views.familyProfile.qrDownloaded'), {
        variant: 'success',
      })
    } catch (error) {
      enqueueSnackbar(t('views.familyProfile.qrDownloadError'), {
        variant: 'error',
      })
    }
  }

  return (
    <Modal
      sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
      open={isModalOpen}
      onClose={() => {
        setIsModalOpen(false)
      }}
    >
      <Card sx={{ p: 2, maxWidth: { xs: '75%', md: 500 } }}>
        <Stack gap={2} alignItems="center">
          <Typography variant="h4" textAlign="center">
            {title}
          </Typography>
          <Divider flexItem />

          <Box sx={{ maxWidth: { xs: '100%', md: 300 } }}>
            <QRCode
              // @ts-expect-error - ref is not typed at all. https://github.com/rosskhanas/react-qr-code/blob/master/src/QRCodeSvg/index.js#L20
              ref={qrCodeRef}
              value={qrCodeValue}
              style={{
                width: '100%',
                height: 'auto',
                borderRadius: 5,
                maxWidth: '100%',
              }}
            />
          </Box>

          <Alert severity="warning">
            {/* TODO: Translate this */}
            <Typography variant="body1">
              {t('views.familyProfile.qrInfo')}
            </Typography>
          </Alert>

          <ButtonGroup>
            <Button
              variant="outlined"
              startIcon={<Download />}
              onClick={handleOnQrDownload}
            >
              {t('views.familyProfile.download')}
            </Button>
            <Button
              variant="outlined"
              startIcon={<Close />}
              onClick={() => {
                setIsModalOpen(false)
              }}
            >
              {t('general.cancel')}
            </Button>
          </ButtonGroup>
        </Stack>
      </Card>
    </Modal>
  )
}
