import { type PayloadAction, createSlice } from '@reduxjs/toolkit'

import { type InterventionFormValues } from '~/screens/massive-interventions/MassiveInterventionForm'
import { type Family } from '~/services/family-services'

export enum MassiveInterventionFormTab {
  Intervention = 'intervention',
  Families = 'families',
  Summary = 'summary',
}

interface MassiveInterventionFormSlice {
  form: InterventionFormValues | null
  families: Family[]
  currentTab: MassiveInterventionFormTab
}

const initialState: MassiveInterventionFormSlice = {
  form: null,
  families: [],
  currentTab: MassiveInterventionFormTab.Intervention,
}

const massiveInterventionFormSlice = createSlice({
  name: 'massiveInterventionFormSlice',
  initialState,
  reducers: {
    updateInterventionForm: (
      state,
      action: PayloadAction<Partial<InterventionFormValues>>,
    ) => ({ ...state, form: { ...state.form, ...action.payload } }),
    resetInterventionForm: state => ({ ...state, form: null }),
    updateFamilies: (state, action: PayloadAction<Family[]>) => {
      return { ...state, families: action.payload }
    },
    clearFamilies: state => ({ ...state, families: [] }),
    updateTab: (state, action: PayloadAction<MassiveInterventionFormTab>) => {
      return { ...state, currentTab: action.payload }
    },
    resetTabs: state => ({
      ...state,
      currentTab: MassiveInterventionFormTab.Intervention,
    }),
  },
})

export const {
  updateInterventionForm,
  resetInterventionForm,
  updateFamilies,
  clearFamilies,
  updateTab,
  resetTabs,
} = massiveInterventionFormSlice.actions
export default massiveInterventionFormSlice.reducer
