import {
  Check as CheckIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  LocationOn as LocationOnIcon,
} from '@mui/icons-material'
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useSelector } from 'react-redux'

import { getLibraryFilterOptions } from '~/api'
import chooseLifeMap from '~/assets/choose_life_map.png'
import Container from '~/components/Container'
import ScreenHeader from '~/components/ScreenHeader'
import withLayout from '~/components/withLayout'
import { getListLibraryQuestions } from '~/services/economic-library'
import { useLocalizedCountries } from '~/utils/hooks/useLocalizedCountries'
import { usePermissions } from '~/utils/hooks/usePermissions'
import { useRole } from '~/utils/hooks/useRole'
import { getLanguageByCode } from '~/utils/lang-utils'
import { PERMISSIONS } from '~/utils/permissions-utils'
import { ROLES } from '~/utils/role-utils'
import { type EconomicLibraryQuestion } from '~/utils/types/economic-library'
import { type PlatformLanguage } from '~/utils/types/i18n'
import { type User } from '~/utils/types/user'

import EconomicLibraryDeleteModal from './economics/EconomicLibraryDeleteModal'
import EconomicLibraryDetailsModal from './economics/EconomicLibraryDetailsModal'
import EconomicLibraryFilters from './economics/EconomicLibraryFilters'
import EconomicLibraryForm from './economics/EconomicLibraryForm'
import QuestionType from './survey-builder/economic/QuestionType'

const useStyles = makeStyles(theme => ({
  loadingContainer: {
    zIndex: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    backgroundColor: theme.palette.text.secondary,
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
  },
  cardContainer: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    padding: '1rem 20px 5px 20px',
    height: '100%',
    minHeight: 200,
    alignItems: 'flex-start',
    boxShadow: `1px 3px 7px ${theme.palette.grey[500]}`,
    borderTop: `4px solid ${theme.palette.background.default}`,
    borderTopWidth: 4,
    '&:hover': {
      borderTop: `4px solid ${theme.palette.primary.main}`,
    },
  },
  cardTitle: {
    color: theme.palette.primary.dark,
    fontSize: 18,
    fontWeight: theme.typography.fontWeightMedium,
    cursor: 'pointer',
    fontFamily: 'Poppins',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fullWidthContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  description: {
    fontFamily: 'Poppins',
    color: theme.palette.grey[600],
    textAlign: 'justify',
    cursor: 'pointer',
  },
  icon: {
    fontSize: 22,
    color: theme.palette.grey[600],
  },
  showMoreButtonContainer: {
    width: '100%',
    margin: '2rem 0',
    display: 'flex',
    justifyContent: 'center',
  },
}))

function processingFunc(option: { code: string; description: string }) {
  return {
    label: option.description,
    value: option.code,
  }
}

interface Option {
  label: string
  value: string
}

function EconomicLibrary() {
  const user = useSelector((state: { user: User }) => state.user)
  const classes = useStyles()

  const { userHasAnyRole } = useRole()
  const { userHasAnyPermissions } = usePermissions()
  const { t, i18n } = useTranslation()

  const lang = getLanguageByCode(i18n.language as PlatformLanguage)
  const countryOptions = useLocalizedCountries(lang)
  const answerTypesOptions = [
    { value: 'checkbox', label: t('answerType.checkbox') },
    { value: 'select', label: t('answerType.select') },
    { value: 'number', label: t('answerType.number') },
    { value: 'radio', label: t('answerType.radio') },
    { value: 'text', label: t('answerType.text') },
  ]

  const [loading, setLoading] = useState(true)
  const [selectedQuestion, setSelectedQuestion] = useState({})
  const [openModal, setOpenModal] = useState<
    'VIEW' | 'CREATE' | 'DELETE' | null
  >(null)
  const [questions, setQuestions] = useState<EconomicLibraryQuestion[]>([])
  const [filterInput, setFilterInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      selectedLanguage: '',
      dimension: '',
      country: '',
      measurementUnit: [],
      stoplightType: '',
      verified: false,
      filter: '',
    },
  )
  const [options, setOptions] = useState<{
    answerType: Option[]
    measurementUnits: Option[]
    stoplights: Option[]
  }>({
    answerType: [],
    measurementUnits: [],
    stoplights: [],
  })
  const [paginationData, setPaginationData] = useState<{
    page: number
    totalPages: number
    prevPage?: number
  }>({
    page: 0,
    totalPages: 0,
    prevPage: 0,
  })

  const loadQuestions = useCallback(
    async (overwrite: boolean) => {
      const page = overwrite ? 0 : paginationData.page

      if (page === paginationData.prevPage && !overwrite) {
        return
      }

      setLoading(true)

      const filters = {
        language: filterInput.selectedLanguage
          ? filterInput.selectedLanguage.value
          : '',
        status: '',
        country:
          !!filterInput.country && !!filterInput.country.value
            ? filterInput.country.value
            : filterInput.country,
        measurementUnit: filterInput.measurementUnit
          ? filterInput.measurementUnit.value
          : '',
        stoplightType: filterInput.stoplightType
          ? filterInput.stoplightType.value
          : '',
        filter: filterInput.filter,
        verified: filterInput.verified,
        page,
      }

      try {
        const response = await getListLibraryQuestions(filters)
        const data = response.data.data.listEconomicLibraryQuestions
        const newQuestions = overwrite
          ? data.content
          : [...questions, ...data.content]
        setQuestions(newQuestions)
        setPaginationData({
          page,
          totalPages: data.totalPages,
          prevPage: page,
        })
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    },
    [paginationData, filterInput, questions, user],
  )

  useEffect(() => {
    if (loading) return

    void loadQuestions(false)
  }, [paginationData.page])

  useEffect(() => {
    void loadQuestions(true)
  }, [filterInput])

  useEffect(() => {
    async function getData() {
      try {
        const data = await getLibraryFilterOptions()
        const rawOptions = data.data.data.listLibraryOptions

        const processed = {
          measurementUnits: rawOptions.measurementUnits.map(processingFunc),
          answerType: answerTypesOptions,
          stoplights: (rawOptions.stoplights || []).map(processingFunc),
        }

        setOptions(processed)
      } catch (error) {
        console.error(error)
      }
    }

    void getData()
  }, [i18n.language as PlatformLanguage])

  const onClose = useCallback((submitted: boolean) => {
    setOpenModal(null)

    if (!submitted) return

    void loadQuestions(true)
  }, [])

  const getCountryByCode = useCallback(
    (code: string) => countryOptions.find(country => country.code === code),
    [countryOptions],
  )

  const processQuestion = useCallback(
    (question: EconomicLibraryQuestion) => {
      return {
        ...question,
        answerType: question.answerType
          ? options.answerType.find(o => o.value === question.answerType)
          : '',
        options: question.options.map(o => ({ value: o, text: o })),
        measurementUnit: question.measurementUnit
          ? options.measurementUnits.find(
              m => m.value === question.measurementUnit,
            )
          : '',
        stoplightType: question.stoplightType
          ? options.stoplights.find(t => t.value === question.stoplightType)
          : '',
      }
    },
    [options],
  )

  const handleViewQuestion = useCallback(
    (question: EconomicLibraryQuestion) => {
      const formQuestion = processQuestion(question)
      setSelectedQuestion(formQuestion)
      setOpenModal('VIEW')
    },
    [],
  )

  const canShowManagementFeatures =
    userHasAnyPermissions([PERMISSIONS.ECONOMICS_WRITE]) ||
    userHasAnyRole([ROLES.ADMIN, ROLES.PS_TEAM])

  const canShowDeleteButton =
    userHasAnyPermissions([PERMISSIONS.ECONOMICS_DELETE]) ||
    userHasAnyRole([ROLES.ADMIN])

  const canShowCreateButton =
    userHasAnyPermissions([PERMISSIONS.ECONOMICS_WRITE]) ||
    userHasAnyRole([ROLES.ADMIN, ROLES.PS_TEAM])

  return (
    <Container variant="stretch">
      {loading && (
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      )}

      <EconomicLibraryForm
        options={options}
        open={openModal === 'CREATE'}
        // @ts-expect-error
        question={selectedQuestion}
        onClose={onClose}
      />

      <EconomicLibraryDetailsModal
        open={openModal === 'VIEW'}
        question={selectedQuestion}
        onClose={() => {
          setOpenModal(null)
        }}
      />

      <EconomicLibraryDeleteModal
        open={openModal === 'DELETE'}
        question={selectedQuestion}
        toggleModal={() => {
          setOpenModal(null)
        }}
        afterSubmit={onClose}
      />

      <ScreenHeader
        title={t('views.toolbar.economics')}
        image={chooseLifeMap}
      />

      <EconomicLibraryFilters
        options={options}
        loading={loading}
        filterInput={filterInput}
        setFilterInput={setFilterInput}
        showManagementFeatures={canShowManagementFeatures}
        handleCreate={() => {
          setSelectedQuestion({})
          setOpenModal('CREATE')
        }}
      />

      <Grid container spacing={2} style={{ marginBottom: '2rem' }}>
        {questions.map(question => (
          <Grid item key={question.id} xs={12} sm={6} md={4} lg={4} xl={3}>
            <div className={classes.cardContainer}>
              <div
                className={classes.fullWidthContainer}
                onClick={() => {
                  handleViewQuestion(question)
                }}
              >
                <Typography variant="h6" className={classes.cardTitle}>
                  {question.questionText}
                </Typography>

                {question.verified && (
                  <Tooltip title={t('views.indicator.create.verified')}>
                    <CheckIcon
                      className={classes.icon}
                      style={{ color: '#309E43' }}
                    />
                  </Tooltip>
                )}
              </div>

              <QuestionType answerType={question.answerType} />

              <Typography
                variant="subtitle2"
                className={classes.description}
                onClick={() => {
                  handleViewQuestion(question)
                }}
              >
                {question.shortName.slice(0, 100)}
                {question.shortName.length > 100 ? '...' : ''}
              </Typography>

              <div className={classes.fullWidthContainer}>
                <div className={classes.container}>
                  <LocationOnIcon className={classes.icon} />

                  <Typography variant="h6" style={{ fontSize: 14 }}>
                    {getCountryByCode(question.country)?.label}
                  </Typography>
                </div>

                <div>
                  {canShowDeleteButton && (
                    <Tooltip title={t('general.delete')}>
                      <IconButton
                        color="inherit"
                        onClick={() => {
                          setSelectedQuestion(question)
                          setOpenModal('DELETE')
                        }}
                        size="large"
                      >
                        <DeleteIcon className={classes.icon} />
                      </IconButton>
                    </Tooltip>
                  )}

                  {canShowCreateButton && (
                    <Tooltip title={t('general.edit')}>
                      <IconButton
                        color="inherit"
                        onClick={() => {
                          const formQuestion = processQuestion(question)
                          setSelectedQuestion(formQuestion)
                          setOpenModal('CREATE')
                        }}
                        size="large"
                      >
                        <EditIcon className={classes.icon} />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>

      {paginationData.page + 1 < paginationData.totalPages && (
        <div className={classes.showMoreButtonContainer}>
          <Button
            color="primary"
            variant="contained"
            aria-label="Show more"
            component="span"
            onClick={() => {
              setPaginationData({
                page: paginationData.page + 1,
                totalPages: paginationData.totalPages,
              })
            }}
          >
            {t('general.showMore')}
          </Button>
        </div>
      )}
    </Container>
  )
}

export default withLayout(EconomicLibrary)
