import { Box } from '@mui/material'

import Footer from '~/Footer'
// Project
import Header from '~/Header'

import { withRouter } from './shared/Deprecated_withRouter'

/**
 * @deprecated Use MainLayout.tsx instead
 *
 * ex:
 * ```
 * <MainLayout>
 *    <div>
 *      ...
 *    </div>
 * </MainLayout>
 * ````
 */
export default function withLayout(Component) {
  return withRouter(({ location, ...props }) => {
    return (
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Header />
        <Box sx={{ flexGrow: 1, marginTop: '70px' }}>
          <Component {...props} />
        </Box>
        <Footer />
      </Box>
    )
  })
}
