import { type PayloadAction, createSlice } from '@reduxjs/toolkit'

import { type CurrentSurvey } from '~/utils/types/current-survey'

const currentSurveySlice = createSlice({
  name: 'currentSurvey',
  initialState: null as CurrentSurvey | null,
  reducers: {
    // TODO: Type this action
    updateSurvey: (_state, action: PayloadAction<any>) => action.payload,
  },
})

export const { updateSurvey } = currentSurveySlice.actions

export default currentSurveySlice.reducer
