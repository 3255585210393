import React from 'react'

import { Grid } from '@mui/material'
import { connect } from 'react-redux'

import { verifiedCodenames } from '../../api'
import InputWithLabel from '../../components/InputWithLabel'
import GenericSelector from '../../components/selectors/GenericSelector'
import { verifiedEconomicCodenames } from '../../services/economic-library'

const CodeNameSelector = ({
  isEconomic,
  codeName,
  onChangeCodeName,
  onChangeOther,
  onBlur,
  error,
  fromSurveyBuilder,
  isDisabled,
  user,
}) => {
  const [codeNames, setCodeNames] = useState([])
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation()

  useEffect(() => {
    Promise.all([
      !isEconomic &&
        verifiedCodenames(fromSurveyBuilder).then(
          response => response.data.data.allVerifiedCodeNames,
        ),
      isEconomic &&
        verifiedEconomicCodenames().then(
          response => response.data.data.listVerifiedEconomicCodenames,
        ),
    ])
      .then(data => {
        const options = (data[0] || data[1]).map(o => ({
          value: o,
          label: o,
        }))
        !fromSurveyBuilder &&
          options.push({
            value: t('general.other'),
            label: t('general.other'),
          })
        setCodeNames(options)
        // If we only recieve the value code as prop
        if (codeName && !codeName.hasOwnProperty('value')) {
          const data = options.find(o => o.value === codeName)
          onChangeCodeName(data)
        }
        setLoading(false)
      })
      .catch(e => {
        setLoading(false)
        console.log(e)
      })
  }, [])

  return (
    <React.Fragment>
      <Grid item md={4} xs={12}>
        <GenericSelector
          label={t('views.indicator.create.codeName')}
          withTitle
          value={codeName}
          options={codeNames}
          isLoading={loading}
          onChange={onChangeCodeName}
          required
          onBlur={onBlur}
          error={error}
          maxMenuHeight={150}
          isDisabled={isDisabled}
        />
      </Grid>
      {!fromSurveyBuilder &&
        codeName &&
        codeName.value === t('general.other') && (
          <Grid item md={4} xs={12} style={{ padding: '12px 8px' }}>
            <InputWithLabel
              title={t(`views.survey.specifyOther`)}
              inputProps={{ maxLength: '100' }}
              name="otherCodeName"
              onChange={e => onChangeOther(e.target.value)}
              required
            />
          </Grid>
        )}
    </React.Fragment>
  )
}

const mapStateToProps = ({ user }) => ({ user })

export default connect(mapStateToProps)(CodeNameSelector)
