import { Check } from '@mui/icons-material'
import { Button, Container, Drawer, Stack, Typography } from '@mui/material'

export default function FooterPopup(props) {
  const { t } = useTranslation()

  return (
    <Drawer
      open={props.isOpen}
      anchor="bottom"
      PaperProps={{
        sx: { bgcolor: theme => theme.palette.primary.dark, p: 4 },
      }}
    >
      <Container>
        <Stack>
          <Typography
            variant="h6"
            sx={{
              color: theme => theme.palette.primary.contrastText,
              fontWeight: theme => theme.typography.fontWeightBold,
            }}
          >
            {props.title}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: theme => theme.palette.primary.contrastText,
            }}
          >
            {props.description}
          </Typography>
          <Button
            variant="contained"
            startIcon={<Check />}
            sx={{ alignSelf: 'center', mt: 2 }}
            onClick={props.handleButtonClick}
          >
            {t('general.gotIt')}
          </Button>
        </Stack>
      </Container>
    </Drawer>
  )
}
