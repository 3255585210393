import { Autocomplete, Box, CircularProgress, TextField } from '@mui/material'
import { useQuery } from '@tanstack/react-query'

import { mentorsByOrganizations } from '~/api'
import { useAppSelector } from '~/redux/hooks'

const ALL_FACILITATORS_VALUE = -1

export interface FacilitatorItem {
  label: string
  value: number
}

interface MultiSelectorProps {
  isMulti: true
  value: FacilitatorItem[]
  onChange: (
    selected: FacilitatorItem[],
    allFacilitators: FacilitatorItem[],
  ) => void
}

interface SingleSelectorProps {
  isMulti: false
  value: FacilitatorItem | null
  onChange: (
    selected: FacilitatorItem | null,
    allFacilitators: FacilitatorItem[],
  ) => void
}

type FacilitatorsFilterProps = {
  hubId?: number
  organizationIds: number[]
} & (MultiSelectorProps | SingleSelectorProps)

export default function FacilitatorsFilter({
  value,
  hubId,
  isMulti,
  organizationIds,
  onChange,
}: FacilitatorsFilterProps) {
  const { t } = useTranslation()
  const user = useAppSelector(state => {
    if (!state.user) throw new Error('No user')
    return state.user
  })

  const facilitatorsByOrg = useQuery({
    queryKey: ['facilitators', organizationIds, hubId, user],
    queryFn: async () => {
      return await mentorsByOrganizations(organizationIds, hubId ?? null)
    },
    select: (data): FacilitatorItem[] => {
      return data.data.data.mentorsByOrganizations.map(facilitator => ({
        label: facilitator.username,
        value: facilitator.id,
      }))
    },
  })

  const allFacilitatorsOption = {
    label: t('views.facilitatorFilter.allFacilitators'),
    value: ALL_FACILITATORS_VALUE,
  }

  //* NOTE: We add the "All Organizations" option
  let facilitatorsToShow: FacilitatorItem[] = []
  const facilitators = facilitatorsByOrg.data ?? []

  if (isMulti) {
    facilitatorsToShow =
      facilitators.length !== value?.length && facilitators.length > 1
        ? [allFacilitatorsOption, ...facilitators]
        : [...facilitators]

    if (value?.some(d => d.value === ALL_FACILITATORS_VALUE)) {
      facilitatorsToShow = []
    }
  } else {
    facilitatorsToShow = [...facilitators]
  }

  const shouldUseOverflowHack =
    (Array.isArray(value) && value.length > 0) ||
    (!Array.isArray(value) && !!value)

  return (
    <Autocomplete
      value={value}
      autoHighlight
      limitTags={3}
      multiple={isMulti}
      disableCloseOnSelect
      options={facilitatorsToShow}
      loading={facilitatorsByOrg.isLoading}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      loadingText={t('views.facilitatorFilter.loading')}
      noOptionsText={t('views.facilitatorFilter.noOption')}
      onChange={(_event, newValue) => {
        if (isMulti && Array.isArray(newValue)) {
          const isAllOptionSelected = newValue.some(
            f => f.value === ALL_FACILITATORS_VALUE,
          )
          const newFacilitators = isAllOptionSelected ? facilitators : newValue

          onChange(newFacilitators, facilitators)
        }

        if (!isMulti && !Array.isArray(newValue)) {
          onChange(newValue, facilitators)
        }
      }}
      renderInput={params => (
        <TextField
          {...params}
          label={t('views.facilitatorFilter.label')}
          InputProps={{
            ...params.InputProps,
            startAdornment: shouldUseOverflowHack && (
              <Box sx={{ maxHeight: 120, overflowY: 'auto' }}>
                {params.InputProps.startAdornment}
              </Box>
            ),
            endAdornment: (
              <>
                {facilitatorsByOrg.isLoading && <CircularProgress size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}
