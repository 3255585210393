import { Autocomplete, Box, CircularProgress, TextField } from '@mui/material'
import { useQuery } from '@tanstack/react-query'

import { getOrganizationsByHub } from '~/api'
import { useAppSelector } from '~/redux/hooks'

const ALL_ORGANIZATIONS_VALUE = 0

export interface OrgItem {
  label: string
  value: number
}

interface OrganizationsFilterProps {
  hubID: number | null
  value: OrgItem[]
  onChange: (selectedOrgs: OrgItem[], allOrgs: OrgItem[]) => void
}

export default function OrganizationsFilter({
  hubID,
  value,
  onChange,
}: OrganizationsFilterProps) {
  const { t } = useTranslation()
  const user = useAppSelector(state => {
    if (!state.user) throw new Error('No user')
    return state.user
  })

  const orgsByHubQuery = useQuery({
    queryKey: ['organizationsByHub', hubID, user],
    queryFn: async () => await getOrganizationsByHub(hubID),
    select({ data }) {
      const normalizedOrganizations: OrgItem[] = data.data.organizations.map(
        (org: { id: number; name: string }) => ({
          value: org.id,
          label:
            org.name.length > 20 ? `${org.name.slice(0, 20)}...` : org.name,
        }),
      )
      return normalizedOrganizations
    },
  })

  const allOrganizationsOption = {
    label: t('views.organizationsFilter.allOrganizations'),
    value: ALL_ORGANIZATIONS_VALUE,
  }

  //* NOTE: We add the "All Organizations" option
  let allOrganizations: OrgItem[] = []
  if (orgsByHubQuery.data) {
    const areOrgsAvailable = orgsByHubQuery.data.length > 1
    const areAllOrgsSelected = orgsByHubQuery.data.length === value.length

    if (areOrgsAvailable && !areAllOrgsSelected) {
      allOrganizations = [allOrganizationsOption, ...orgsByHubQuery.data]
    } else {
      allOrganizations = [...orgsByHubQuery.data]
    }
  }

  const allOrganizationsOptionIsSelected =
    value.some(org => org.value === ALL_ORGANIZATIONS_VALUE) ?? false

  //* NOTE: If the "All Organizations" option is selected don't show the other options
  if (allOrganizationsOptionIsSelected) {
    allOrganizations = []
  }

  //* NOTE: https://github.com/mui/material-ui/issues/19137#issuecomment-583209943
  const shouldUseOverflowHack = value.length > 0

  return (
    <Autocomplete
      multiple
      fullWidth
      autoHighlight
      limitTags={3}
      disableCloseOnSelect
      options={allOrganizations}
      noOptionsText={t('views.organizationsFilter.noOption')}
      loadingText={t('views.organizationsFilter.loading')}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      loading={orgsByHubQuery.isLoading}
      value={allOrganizationsOptionIsSelected ? orgsByHubQuery.data : value}
      onChange={(_event, newValue) => {
        const orgs = orgsByHubQuery.data ?? []
        const isAllOrgsSelected = newValue.some(
          org => org.value === ALL_ORGANIZATIONS_VALUE,
        )

        if (isAllOrgsSelected) {
          onChange(orgs, orgs)
          return
        }
        onChange(newValue, orgs)
      }}
      renderInput={params => (
        <TextField
          {...params}
          label={t('views.organizationsFilter.label')}
          InputProps={{
            ...params.InputProps,
            startAdornment: shouldUseOverflowHack && (
              <Box sx={{ maxHeight: 120, overflowY: 'auto' }}>
                {params.InputProps.startAdornment}
              </Box>
            ),
            endAdornment: (
              <>
                {orgsByHubQuery.isLoading && <CircularProgress size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}
