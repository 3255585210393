import { createQueryKeys } from '@lukemorales/query-key-factory'

import store from '~/redux'
import { listOfflineMapsByOrg } from '~/services/map-services'
import { type PlatformLanguage } from '~/utils/types/i18n'

const userId = store.getState().user?.id

export const mapsQueries = createQueryKeys('maps', {
  listOfflineByOrg: (language: PlatformLanguage, orgId: number) => ({
    queryKey: [language, orgId, { userId }],
    queryFn: async () => await listOfflineMapsByOrg(orgId),
  }),
})
