import { Save } from '@mui/icons-material'
import {
  Box,
  Button,
  CardMedia,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material'
import mixpanel from 'mixpanel-browser'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'

import { attachSnapshotStoplight, submitDraft, submitPictures } from '~/api'
import skippedLifemap from '~/assets/Family_center.png'
import BottomSpacer from '~/components/BottomSpacer'
import LeaveModal from '~/components/LeaveModal'
import NoProdWarningBanner from '~/components/NoProdWarningBanner'
import { ProgressBarContext } from '~/components/ProgressBar'
import TitleBar from '~/components/TitleBar'
import { useAppDispatch, useAppSelector } from '~/redux/hooks'
import { updateBackupUser } from '~/redux/slices/backupUser'
import { updateDraft } from '~/redux/slices/currentDraft'
import { updateUser } from '~/redux/slices/user'
import { downloadFile } from '~/utils/files-utils'
import { ROLES } from '~/utils/role-utils'
import {
  calculateTotalTime,
  isTimerNum,
  remakeTimers,
  sanitizeDraft,
  screenTimer,
  screenValidation,
} from '~/utils/survey-utils'
import { type CurrentDraft } from '~/utils/types/current-draft'

interface ToggleModalProps {
  title?: string
  variant?: string
  subtitle?: string
  continueButtonText?: string
  onLeave?: () => void
}

interface HandleRedictProps {
  familyId?: number
  familyCode?: string
  familyName?: string
  isRetake?: boolean
  justStoplight?: boolean
  stoplightSkipped?: boolean
}

export default function Final() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const [calculatedTimes, setCalculatedTimes] = useState({})

  const user = useAppSelector(state => {
    if (!state.user) throw new Error('No user')
    return state.user
  })
  const backupUser = useAppSelector(state => state.backupUser)
  const currentDraft = useAppSelector(state => {
    if (!state.currentDraft) throw new Error('No currentDraft')
    return state.currentDraft
  })
  const currentSurvey = useAppSelector(state => {
    if (!state.currentSurvey) throw new Error('No currentSurvey')
    return state.currentSurvey
  })

  const context = useContext(ProgressBarContext)
  const [isLoading, setIsLoading] = useState(false)

  const [modalInfo, setModalInfo] = useState({
    openModal: false,
    modalTitle: '',
    modalSubtitle: '',
    modalContinueButtonText: '',
    modalLeaveAction: () => {},
    modalVariant: '',
  })

  const [tries, setTries] = useState(0)

  const toggleModal = ({
    title,
    subtitle,
    continueButtonText,
    variant,
    onLeave,
  }: ToggleModalProps) => {
    setModalInfo({
      openModal: !!title,
      modalTitle: title ?? '',
      modalVariant: variant ?? '',
      modalSubtitle: subtitle ?? '',
      modalLeaveAction: onLeave ?? (() => {}),
      modalContinueButtonText: continueButtonText ?? '',
    })
  }

  const handleRedirect = ({
    familyId,
    familyName,
    familyCode,
    isRetake,
    stoplightSkipped,
    justStoplight,
  }: HandleRedictProps) => {
    if (backupUser) {
      dispatch(updateUser(backupUser))
      dispatch(updateBackupUser(null))
    }

    if (!stoplightSkipped) {
      navigate('/lifemap/send-lifemap', { state: { familyCode, familyName } })
      return
    }

    if ((isRetake ?? justStoplight) && familyId && user.role !== ROLES.FAMILY) {
      navigate(`/family/${familyId}`)
      return
    }

    if (user.dynamicLink) {
      navigate('/lifemap/farewell')
      return
    }

    if (user.role === ROLES.FAMILY) {
      navigate(`/my-profile`)
      return
    }

    navigate('/surveys')
  }

  const handleSnapshotSubmit = (currentDraft: CurrentDraft) => {
    // submit draft to server and wait for response
    const stoplightSkipped =
      currentSurvey.surveyConfig.stoplightOptional &&
      currentDraft.indicatorSurveyDataList &&
      currentDraft.indicatorSurveyDataList.length === 0

    mixpanel.track('finish_draft', {
      survey: currentSurvey.title,
      draft: currentDraft,
      user: user.username,
      role: user.role,
      env: user.env,
    })
    submitDraft(currentDraft, currentSurvey.surveyConfig.surveyLocation)
      .then(response => {
        const snapshotId = response.data.data.addSnapshot.snapshotId
        dispatch(updateDraft({ ...currentDraft, snapshotId }))

        const { familyId, code, name } = response.data.data.addSnapshot.family

        handleRedirect({
          familyId,
          familyCode: code,
          familyName: name,
          isRetake: currentDraft.isRetake ?? false,
          stoplightSkipped: stoplightSkipped ?? false,
          justStoplight: !!currentDraft.justStoplight,
        })

        // Reset ProgressBar Context
        context?.setScreensCount({})
      })
      .catch(e => {
        console.error(e)
        setTries(tries + 1)
        toggleModal({
          title: t('general.warning'),
          subtitle: t('general.saveError').replace('%n', currentDraft.draftId),
          continueButtonText: t('general.gotIt'),
          variant: 'warning',
          onLeave: () => {
            toggleModal({})
            setIsLoading(false)
          },
        })
      })
  }

  async function handleSubmit() {
    setIsLoading(true)

    const updatedDraft: CurrentDraft = structuredClone({
      ...currentDraft,
      ...calculatedTimes,
    })
    delete updatedDraft.familyData.familyMembersList[0].emailVerificationCode
    delete updatedDraft.familyData.familyMembersList[0].isEmailVerified

    if (updatedDraft.justStoplight) {
      try {
        await attachSnapshotStoplight(updatedDraft)
        dispatch(updateDraft({ ...updatedDraft }))

        handleRedirect({ justStoplight: true })
        // Reset ProgressBar Context
        context?.setScreensCount({})
      } catch (err) {
        setTries(tries + 1)
        toggleModal({
          title: t('general.warning'),
          subtitle: t('general.saveError').replace('%n', updatedDraft.draftId),
          continueButtonText: t('general.gotIt'),
          variant: 'warning',
          onLeave: () => {
            toggleModal({})
            setIsLoading(false)
          },
        })
      } finally {
        setIsLoading(false)
      }
      return
    }

    if (
      (updatedDraft.pictures && updatedDraft.pictures.length > 0) ??
      updatedDraft.sign
    ) {
      try {
        const response = await submitPictures(updatedDraft)
        const pictures = response.data
        updatedDraft.pictures = pictures.filter(
          picture => picture.type !== 'sign',
        )

        handleSnapshotSubmit({ ...updatedDraft })
      } catch (err) {
        delete updatedDraft.pictures
        handleSnapshotSubmit({ ...updatedDraft })
      }

      return
    }

    handleSnapshotSubmit(updatedDraft)
  }

  useEffect(() => {
    setIsLoading(false)
    let storedDraft = structuredClone(currentDraft)
    if (isTimerNum(storedDraft)) {
      const remakedTimers = remakeTimers(storedDraft)
      storedDraft = { ...storedDraft, ...remakedTimers }
    }
    const validationResult = screenValidation(
      storedDraft,
      'FINAL',
      currentSurvey,
    )
    if (validationResult) {
      enqueueSnackbar(t('validation.completionRequired'), { variant: 'error' })
      navigate(validationResult)
    }
    const screenTimes = screenTimer(storedDraft, 'Final')
    storedDraft = {
      ...storedDraft,
      ...screenTimes,
    }

    const times = calculateTotalTime(storedDraft)
    setCalculatedTimes(times)

    dispatch(updateDraft({ ...storedDraft }))
  }, [])

  function downloadData() {
    const sanitizedDraft = sanitizeDraft(
      currentDraft,
      currentSurvey.surveyConfig.surveyLocation,
    )
    downloadFile('data.json', JSON.stringify(sanitizedDraft), 'utf8')
  }

  return (
    <>
      <LeaveModal
        singleAction
        onClose={() => {}}
        open={modalInfo.openModal}
        title={modalInfo.modalTitle}
        variant={modalInfo.modalVariant}
        subtitle={modalInfo.modalSubtitle}
        continueButtonText={modalInfo.modalContinueButtonText}
        leaveAction={modalInfo.modalLeaveAction || (() => {})}
      />
      <TitleBar title={t('views.final.title')} progressBar />
      <Typography
        variant="h5"
        sx={{
          display: 'flex',
          textAlign: 'center',
          justifyContent: 'center',
          mt: theme => theme.spacing(5),
        }}
      >
        {t('views.final.surveyCompleted')}
      </Typography>
      {isLoading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            my: theme => theme.spacing(4),
          }}
        >
          <CircularProgress size={50} thickness={3} />
        </Box>
      )}

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <NoProdWarningBanner />
        <CardMedia
          component="img"
          image={skippedLifemap}
          sx={{
            objectFit: 'scale-down',
            width: { xs: 200, md: 300 },
            height: { xs: 200, md: 300 },
          }}
        />
      </Box>

      <Grid
        container
        spacing={2}
        sx={{ display: 'flex', justifyContent: 'center' }}
      >
        {tries > 2 && (
          <Grid item xs={12} sm={6}>
            <Button
              variant="outlined"
              color="primary"
              onClick={downloadData}
              fullWidth
              sx={{ mt: theme => theme.spacing(6) }}
            >
              {t('views.familyProfile.download')}
            </Button>
          </Grid>
        )}
        <Grid
          display="flex"
          item
          sm={6}
          xs={12}
          alignItems="center"
          justifyContent="center"
        >
          <Button
            color="primary"
            variant="contained"
            endIcon={<Save />}
            onClick={handleSubmit}
            sx={{
              mt: theme => theme.spacing(6),
              width: { xs: '100%', md: 500 },
            }}
            disabled={isLoading}
            test-id="close"
          >
            {t('general.close')}
          </Button>
        </Grid>
      </Grid>

      <BottomSpacer />
    </>
  )
}
