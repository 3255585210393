import { Checkbox } from '@mui/material'
import { type CheckboxProps } from '@mui/material/Checkbox/Checkbox'

/**
 *
 * @deprecated Use the native Material UI Component `<Checkbox />`
 */
const GreenCheckbox = (props: CheckboxProps) => {
  return <Checkbox {...props} />
}

export default GreenCheckbox
