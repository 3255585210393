import { type Theme } from '@mui/material/styles'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { APIProvider } from '@vis.gl/react-google-maps'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { IntercomProvider } from 'react-use-intercom'
import { PersistGate } from 'redux-persist/integration/react'

import Authenticator from './Authenticator'
import DatePickedProvider from './components/DatePickerProvider'
import LanguageProvider from './components/LanguageProvider'
import Routes from './components/Routes'
import Scroller, { ScrollerProvider } from './components/Scroller'
import CustomSnackbarProvider from './components/SnackbarProvider'
import MuiWrapperProvider from './components/providers/MuiWrapperProvider'
import './index.css'
import store, { persistor } from './redux'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const INTERCOM_APP_ID = import.meta.env.VITE_INTERCOM_API_KEY
const MAP_API_KEY = import.meta.env.VITE_MAP_API_KEY

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <MuiWrapperProvider>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <DatePickedProvider>
              <Router>
                <IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
                  <CustomSnackbarProvider>
                    <ScrollerProvider>
                      <Scroller />
                      <LanguageProvider>
                        <APIProvider
                          apiKey={MAP_API_KEY}
                          libraries={['places', 'geometry', 'drawing']}
                        >
                          <Authenticator>
                            <div style={{ width: '100%', margin: 'auto' }}>
                              <Routes />
                            </div>
                          </Authenticator>
                        </APIProvider>
                      </LanguageProvider>
                    </ScrollerProvider>
                  </CustomSnackbarProvider>
                </IntercomProvider>
              </Router>
            </DatePickedProvider>
          </PersistGate>
        </Provider>
      </MuiWrapperProvider>

      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}
