import type React from 'react'

import { useLocation, useNavigate, useParams } from 'react-router-dom'

/**
 * @deprecated This component is deprecated and should be avoided.
 * If your component is class based. Refactor it to use hooks.
 *
 * Hooks that this component wraps:
 * - useLocation
 * - useNavigate
 * - useParams
 */
export function withRouter<ComponentProps>(
  Component: React.FunctionComponent<ComponentProps>,
) {
  function ComponentWithRouterProp(props: ComponentProps) {
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()

    //* NOTE: This emulates the behavior of React Router v5's history
    //* Example: this.props.history.push('/my-url') === navigate('/my-url')
    const wrappedHistory = {
      location,

      push: (args: any) => {
        if (typeof args === 'string') navigate(args)
        else navigate(args.pathname, { state: args.state })
      },
      replace: (args: any) => {
        navigate(args, { replace: true })
      },
      goBack: () => {
        navigate(-1)
      },
    }

    const propsWithRouter = {
      ...props,
      location,
      navigate,
      history: wrappedHistory,
      params,
    }

    return <Component {...propsWithRouter} />
  }

  return ComponentWithRouterProp
}
