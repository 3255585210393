import { Box, Grid, Typography } from '@mui/material'

import IndicatorBall from '~/components/summary/IndicatorBall'
import { type Achievement } from '~/utils/types/current-draft'
import { type IndicatorValue } from '~/utils/types/surveys'

interface Indicator {
  key: string
  value: IndicatorValue
  questionText: string
}

function findIn(indicator, elements) {
  return !!elements.find(element => {
    const elementValue = element.snapshotStoplightId || element.indicator
    const indicatorValue = indicator.snapshotStoplightId || indicator.key
    return elementValue === indicatorValue
  })
}

interface DimensionQuestionProps {
  questions: Indicator[]
  previousIndicators?: Indicator[]

  priorities: unknown[]
  previousPriorities?: unknown[]
  achievements: Achievement[]
  previousAchivements?: Achievement[]

  onClickIndicator: (indicator: Indicator) => void

  isRetake?: boolean
  readOnly?: boolean
  mustShowPointer?: boolean
}

function DimensionQuestion({
  questions,
  previousIndicators,
  priorities,
  previousPriorities,
  achievements,
  previousAchivements,
  onClickIndicator,
  isRetake,
  mustShowPointer,
  readOnly,
}: DimensionQuestionProps) {
  const { t } = useTranslation()

  const colorsByIndicator = {
    1: { color: 'red', label: t('views.lifemap.red') },
    3: { color: 'green', label: t('views.lifemap.green') },
    2: { color: 'yellow', label: t('views.lifemap.yellow') },
    0: { color: 'skipped', label: t('views.lifemap.skipped') },
    9: { color: 'doesNotApply', label: t('views.lifemap.doesNotApply') },
  } as const

  return (
    <Grid container spacing={2} justifyContent="center">
      {questions.map((indicator, index) => {
        const hasAchievement = findIn(indicator, achievements)
        const hasPriority = findIn(indicator, priorities)

        let previousIndicator: Indicator | null = null

        if (isRetake) {
          const currentPreviousIndicator = previousIndicators?.find(
            prev => prev.key === indicator.key,
          )

          if (currentPreviousIndicator)
            previousIndicator = currentPreviousIndicator
        }

        const showPointer =
          !readOnly &&
          indicator.value !== 0 &&
          indicator.value !== 9 &&
          (mustShowPointer ?? (!hasPriority && !hasAchievement))

        return (
          <Grid
            item
            sm={6}
            md={3}
            lg={2}
            key={indicator.key}
            sx={{ cursor: showPointer ? 'pointer' : 'auto' }}
            onClick={
              showPointer
                ? () => {
                    onClickIndicator(indicator)
                  }
                : () => {}
            }
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {isRetake && previousIndicator && (
                <IndicatorBall
                  variant="medium"
                  styles={{ marginRight: -12 }}
                  priority={findIn(indicator, previousPriorities)}
                  achievement={findIn(indicator, previousAchivements)}
                  color={colorsByIndicator[previousIndicator.value].color}
                />
              )}
              <IndicatorBall
                priority={hasPriority}
                achievement={hasAchievement}
                color={colorsByIndicator[indicator.value].color}
              />
            </Box>
            <Typography
              variant="subtitle1"
              align="center"
              sx={{ mt: 2, mb: 4 }}
            >
              {indicator.questionText}
            </Typography>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default DimensionQuestion
