import React from 'react'

import { FormLabel } from '@mui/material'
import { withStyles } from '@mui/styles'

const StyledFormLabel = withStyles(() => ({
  root: {
    color: '#909090',
    marginBottom: 8,
    marginTop: 15,
    maxWidth: '100%',
  },
  focused: {
    color: '#909090!important',
  },
}))(props => <FormLabel color={'primary'} {...props} />)

export default StyledFormLabel
