import { type PayloadAction, createSlice } from '@reduxjs/toolkit'

import { type InterventionDefinition } from '~/utils/types/interventions'

const interventionSlice = createSlice({
  name: 'currentIntervention',
  initialState: null as InterventionDefinition | null,
  reducers: {
    updateIntervention: (
      _state,
      action: PayloadAction<InterventionDefinition | null>,
    ) => action.payload,
    resetCurrentIntervention: () => null,
  },
})

export const { updateIntervention, resetCurrentIntervention } =
  interventionSlice.actions
export default interventionSlice.reducer
