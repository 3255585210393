import { Button, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import mixpanel from 'mixpanel-browser'
import { useLocation, useNavigate } from 'react-router-dom'

import checkboxWithDots from '~/assets/checkbox_with_dots.png'
import BottomSpacer from '~/components/BottomSpacer'
import Container from '~/components/Container'
import LeaveModal from '~/components/LeaveModal'
import NavIcons from '~/components/NavIcons'
import NoProdWarningBanner from '~/components/NoProdWarningBanner'
import { useAppSelector } from '~/redux/hooks'

const useHeroStyles = makeStyles(theme => ({
  title: {
    position: 'relative',
    top: '55%',
    zIndex: 1,
    [theme.breakpoints.down('md')]: {
      width: '180px',
      fontSize: 24,
      lineHeight: 1.4,
      left: '10%',
    },
  },
  termsCheckboxImage: {
    margin: 'auto',
    position: 'absolute',
    right: 0,
    bottom: '-10%',
    width: '35%',
    [theme.breakpoints.down('lg')]: {
      transform: 'translateY(50%)',
      width: '33%',
      right: '5%',
      zIndex: 0,
    },
    [theme.breakpoints.down('sm')]: {
      top: '45%',
      left: '58%',
      transform: 'translateY(50%)',
      width: '35%',
      zIndex: 0,
    },
    [theme.breakpoints.down('xl')]: {
      bottom: '50%',
      transform: 'translateY(50%)',
      width: '35%',
      zIndex: 0,
    },
  },
  container: {
    position: 'absolute',
    top: 0,
    height: '100%',
    left: '50%',
    transform: 'translateX(-50%)',
  },
}))

const useMinorsConsentStyles = makeStyles(theme => ({
  titleContainer: {
    height: 220,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'hidden',
  },
  buttonTermsDisagree: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    textTransform: 'capitalize',
    textDecoration: 'underline',
  },
  buttonTermsAgree: {
    width: 260,
    margin: '0 10px',
  },
  buttonContainerTerms: {
    display: 'flex',
    justifyContent: 'center',
  },
  lowerTitle: {
    fontSize: 28,
    textAlign: 'center',
    marginBottom: 15,
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    textAlign: 'justify',
    paddingTop: 40,
    maxWidth: 660,
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
  },
  divider1: {
    width: '100%',
    height: 20,
  },
}))

interface TitleContainerProps {
  title: string
}

function Hero({ title }: TitleContainerProps) {
  const classes = useHeroStyles()

  return (
    <Container className={classes.container}>
      <Typography className={classes.title} variant="h4">
        {title}
      </Typography>

      <img
        src={checkboxWithDots}
        className={classes.termsCheckboxImage}
        alt=""
      />
    </Container>
  )
}

export default function Disclaimer() {
  const classes = useMinorsConsentStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const [showModal, setShowModal] = useState(false)

  const user = useAppSelector(state => {
    if (!state.user) throw new Error('No user')
    return state.user
  })
  const currentDraft = useAppSelector(state => state.currentDraft)
  const currentSurvey = useAppSelector(state => {
    if (!state.currentSurvey) throw new Error('No currentSurvey')
    return state.currentSurvey
  })

  function leaveSurvey() {
    mixpanel.track('leave_survey', {
      env: user.env,
      role: user.role,
      draft: currentDraft,
      user: user.username,
      survey: currentSurvey.title,
    })

    if (user.dynamicLink) {
      navigate('/welcome')
      return
    }

    if (currentDraft?.isRetake) {
      // TODO: Type currentDraft object
      const familyId = currentDraft?.familyData.familyId
      navigate(`/family/${familyId}`)
    } else {
      navigate('/surveys')
    }
  }

  return (
    <div>
      <div className={classes.titleContainer}>
        <NavIcons />

        {currentSurvey.disclaimer && (
          <Hero title={currentSurvey.disclaimer.title} />
        )}
      </div>
      <Container>
        <div className={classes.contentContainer}>
          <NoProdWarningBanner />

          <Typography variant="h5">
            {currentSurvey.disclaimer?.subtitle}
          </Typography>

          <br />

          {currentSurvey.disclaimer?.text
            .split(/(?:\\n)/g)
            .map((line, index) => (
              <Typography
                style={{ fontSize: 18 }}
                color="textPrimary"
                key={index}
              >
                {line}
                <br />
              </Typography>
            ))}
        </div>
      </Container>
      <div className={classes.buttonContainerTerms}>
        <Button
          variant="text"
          onClick={() => {
            setShowModal(true)
          }}
        >
          {t('general.disagree')}
        </Button>

        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            navigate('/lifemap/terms', {
              state: { projectId: location.state?.projectId ?? null },
            })
          }}
          test-id="agree"
        >
          {t('general.agree')}
        </Button>
      </div>
      <BottomSpacer />
      <LeaveModal
        title="Warning!"
        subtitle={t('views.modals.yourLifemapIsNotComplete')}
        cancelButtonText={t('general.no')}
        continueButtonText={t('general.yes')}
        onClose={() => {
          setShowModal(false)
        }}
        open={showModal}
        leaveAction={leaveSurvey}
      />
    </div>
  )
}
