import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber'
import { type TFunction } from 'i18next'
import { get } from 'lodash'

const phoneUtil = PhoneNumberUtil.getInstance()

const getErrorLabelForPath = (path, touched, errors, t) =>
  get(touched, path) && get(errors, path) && t(get(errors, path))
const pathHasError = (path, touched, errors) =>
  !!(get(touched, path) && get(errors, path))

const constructEstimatedMonthsOptions = (t: TFunction) => {
  const MAX_MONTHS = 24
  const monthsArray: Array<{ value: number; label: string }> = []

  for (let i = 0; i < MAX_MONTHS; i++) {
    const idx = i + 1
    const label =
      idx === 1
        ? `${idx} ${t('views.priority.month')}`
        : `${idx} ${t('views.priority.months')}`

    monthsArray.push({ value: idx, label })
  }

  return monthsArray
}

function capitalize(str = '', lower = false) {
  return (lower ? str.toLowerCase() : str).replace(
    /(?:^|\s|["'([{])+\S/g,
    match => match.toUpperCase(),
  )
}

function parseLines(value: string) {
  return value.replace(/(\n)/g, '\\n')
}
function unParseLines(value: string) {
  return value.replace(/(\\n)/g, '\n')
}

function removeAccents(value: string): string {
  const accentsRegex: RegExp = /[\u0300-\u036f]/g
  return value.normalize('NFD').replace(accentsRegex, '')
}

interface PhoneNumber {
  phoneCode: {
    id: string
    code: string
  }
  phoneNumber: string
}
export function getFormattedPhoneNumber({
  phoneCode,
  phoneNumber,
}: PhoneNumber) {
  const international = `+${phoneCode.id} ${phoneNumber}`
  const phone = phoneUtil.parse(international, phoneCode.code)
  const formattedPhone = phoneUtil.format(phone, PhoneNumberFormat.E164)

  return formattedPhone
}

export {
  getErrorLabelForPath,
  pathHasError,
  constructEstimatedMonthsOptions,
  capitalize,
  parseLines,
  removeAccents,
  unParseLines,
}
