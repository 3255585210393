import { Button, Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useWindowSize } from 'usehooks-ts'

import beginLifemap from '~/assets/begin_lifemap.png'
import BottomSpacer from '~/components/BottomSpacer'
import Container from '~/components/Container'
import TitleBar from '~/components/TitleBar'
import { useAppSelector } from '~/redux/hooks'
import { updateDraft } from '~/redux/slices/currentDraft'
import { screenTimer, screenValidation } from '~/utils/survey-utils'

export default function BeginStoplight() {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { width } = useWindowSize()
  const { enqueueSnackbar } = useSnackbar()

  const user = useAppSelector(state => {
    if (!state.user) throw new Error('No user')
    return state.user
  })
  const currentDraft = useAppSelector(state => {
    if (!state.currentDraft) throw new Error('No currentDraf')
    return state.currentDraft
  })
  const currentSurvey = useAppSelector(state => {
    if (!state.currentSurvey) throw new Error('No currentSurvey')
    return state.currentSurvey
  })

  const questions = currentSurvey.surveyStoplightQuestions.length
  const stoplightOptional = currentSurvey.surveyConfig.stoplightOptional

  const handleSkip = () => {
    dispatch(
      updateDraft({
        ...currentDraft,
        indicatorSurveyDataList: [],
        stoplightSkipped: true,
      }),
    )
    if (currentSurvey.surveyConfig.pictureSupport) {
      navigate('/lifemap/upload-pictures')
    } else if (currentSurvey.surveyConfig.signSupport) {
      navigate('/lifemap/sign')
    } else {
      navigate('/lifemap/final')
    }
  }

  const handleContinue = () => {
    if (user.interactive_help && width < 1025) {
      navigate('/lifemap/help-stoplight')
    } else {
      navigate('/lifemap/stoplight/0')
    }
  }

  useEffect(() => {
    const updatedDraft = { ...currentDraft }
    const validationResult = screenValidation(
      updatedDraft,
      'STOPLIGHT',
      currentSurvey,
    )
    if (validationResult) {
      enqueueSnackbar(t('validation.completionRequired'), { variant: 'error' })
      navigate(validationResult)
    }
    const screenTimes = screenTimer(updatedDraft, 'BeginLifemap')
    dispatch(updateDraft({ ...updatedDraft, ...screenTimes }))
  }, [])

  return (
    <>
      <TitleBar title={t('general.yourStoplight')} progressBar />
      <Container variant="stretch" className={classes.beginStopLightContainer}>
        <Typography variant="h5" className={classes.stopLightTitleContainer}>
          {stoplightOptional
            ? t('views.lifemap.thisLifeMapHasNoStoplight').replace(
                '%n',
                questions.toString(),
              )
            : t('views.lifemap.thisLifeMapHas').replace(
                '%n',
                questions.toString(),
              )}
        </Typography>
        <img
          className={classes.beginStopLightImage}
          src={beginLifemap}
          alt=""
        />

        <Grid container spacing={2} className={classes.buttonContainerForm}>
          {stoplightOptional && (
            <Grid item md={6} xs={12} container justifyContent="center">
              <Button
                variant="outlined"
                color="primary"
                onClick={handleSkip}
                style={{ color: 'green', width: '20rem' }}
              >
                {t('general.closeAndSign')}
              </Button>
            </Grid>
          )}
          {!stoplightOptional && (
            <Grid item md={6} xs={12} container justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                onClick={handleContinue}
                style={{ color: 'white', width: '20rem' }}
              >
                {stoplightOptional
                  ? t('general.completeStoplight')
                  : t('general.continue')}
              </Button>
            </Grid>
          )}
        </Grid>
        <BottomSpacer />
      </Container>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  buttonContainerForm: {
    marginBottom: theme.spacing(4),
    justifyContent: 'space-evenly',
    flex: 1,
    width: '100%',
  },
  beginStopLightContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  stopLightTitleContainer: {
    width: '50%',
    margin: '50px auto 0 auto',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
      fontSize: 18,
      lineHeight: 1.2,
    },
  },
  beginStopLightImage: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(10),
    position: 'relative',
    left: -10,
    [theme.breakpoints.down('lg')]: {
      width: 400,
      marginBottom: theme.spacing(5),
    },
    [theme.breakpoints.down(500)]: {
      width: 300,
    },
  },
  labelGreenRight: {
    marginRight: 20,
    fontSize: 16,
    color: theme.palette.primary.dark,
    paddingLeft: '3rem',
    textAlign: 'right',
  },
}))
