import React from 'react'

import lottie from 'lottie-web'

/**
 * Tiny wrapper for playing Lottie animations for non complex use cases (replaces react-lottie).
 */
export const Lottie = ({ options, width, height }) => {
  const element = useRef(null)
  const lottieInstance = useRef()

  useEffect(() => {
    if (element.current) {
      lottieInstance.current = lottie.loadAnimation({
        ...options,
        container: element.current,
      })
    }
  }, [options.animationData])

  return <div style={{ width, height }} ref={element}></div>
}

export default Lottie
