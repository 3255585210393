import { type Dispatch, type SetStateAction } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import {
  Close,
  LockResetTwoTone,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Collapse,
  Divider,
  Fade,
  IconButton,
  Modal,
  Stack,
  TextField,
} from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { isAxiosError } from 'axios'
import mixpanel from 'mixpanel-browser'
import { closeSnackbar, useSnackbar } from 'notistack'
import {
  type FieldError,
  type UseFormRegisterReturn,
  useForm,
} from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { z } from 'zod'

import pspLogo from '~/assets/header_logo.png'
import { useAppDispatch, useAppSelector } from '~/redux/hooks'
import { updateUser } from '~/redux/slices/user'
import { changePassword } from '~/services/auth-services'

import PasswordCriterias from '../shared/StrongPasswordCriterias'

interface ChangePasswordModalProps {
  isOpened: boolean
  setIsOpened: Dispatch<SetStateAction<boolean>>
}

export default function ChangePasswordModal({
  isOpened,
  setIsOpened,
}: ChangePasswordModalProps) {
  const { t } = useTranslation()

  return (
    <Modal
      open={isOpened}
      onClose={() => {
        setIsOpened(false)
      }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Fade in={isOpened} timeout={250}>
        <Card
          sx={{
            outline: 'none',
            borderRadius: 2,
            p: { xs: 1, sm: 2 },
            bgcolor: theme => theme.palette.background.default,
            width: theme => ({ xs: '100vw', sm: theme.breakpoints.values.sm }),
          }}
        >
          <CardHeader
            title={t('views.toolbar.changePassword')}
            titleTypographyProps={{
              align: 'center',
              color: theme => theme.palette.grey[600],
              fontSize: theme => ({
                xs: theme.typography.h6.fontSize,
                sm: theme.typography.h4.fontSize,
              }),
            }}
            action={
              <IconButton
                color="primary"
                onClick={() => {
                  setIsOpened(false)
                }}
              >
                <Close />
              </IconButton>
            }
          />
          <CardMedia
            height={75}
            alt="PSP Logo"
            component="img"
            image={pspLogo}
            sx={{ objectFit: 'contain' }}
          />
          <CardContent>
            <ChangePasswordForm setIsOpened={setIsOpened} />
          </CardContent>
        </Card>
      </Fade>
    </Modal>
  )
}

interface ChangePasswordFormProps {
  setIsOpened: Dispatch<SetStateAction<boolean>>
}

function ChangePasswordForm({ setIsOpened }: ChangePasswordFormProps) {
  const navigate = useNavigate()
  const location = useLocation()
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const { enqueueSnackbar } = useSnackbar()

  const dispatch = useAppDispatch()
  const user = useAppSelector(state => {
    if (!state.user) throw new Error('No user')
    return state.user
  })

  const [isNewPasswordStrong, setIsNewPasswordStrong] = useState(false)

  type UserPasswords = z.infer<typeof validationSchema>
  const validationSchema = z
    .object({
      currentPassword: z
        .string()
        .trim()
        .min(1, t('validation.fieldIsRequired')),
      newPassword: z.string().trim().min(1, t('validation.fieldIsRequired')),
      repeatNewPassword: z
        .string()
        .trim()
        .min(1, t('validation.fieldIsRequired')),
    })
    .refine(data => data.newPassword === data.repeatNewPassword, {
      message: t('views.changePasswordModal.passwordsDontMatch'),
      path: ['repeatNewPassword'],
    })
    .refine(data => data.newPassword !== data.currentPassword, {
      message: t('views.changePasswordModal.passwordNotSameAsOlder'),
      path: ['repeatNewPassword'],
    })
    .refine(() => isNewPasswordStrong, {
      message: t('views.changePasswordModal.passwordNotStrong'),
      path: ['newPassword'],
    })

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm<UserPasswords>({
    mode: 'onChange',
    resolver: zodResolver(validationSchema),
  })
  const shouldExpandNewPasswordValidations = !!dirtyFields.newPassword

  const submitChangePassword = useMutation({
    mutationFn: async (values: UserPasswords) => await changePassword(values),
    onError(error) {
      if (!isAxiosError(error)) {
        enqueueSnackbar(t('views.changePasswordModal.somethingWentWrong'), {
          variant: 'error',
        })
        console.error('PSP Platform', error)
        return
      }

      enqueueSnackbar(error.response?.data.message, { variant: 'error' })
    },

    onSuccess() {
      mixpanel.track('changed_password', {
        user,
        page: location.pathname,
      })

      navigate('/login')

      dispatch(updateUser(null))

      closeSnackbar()
      setIsOpened(false)

      enqueueSnackbar(t('views.changePasswordModal.passwordChanged'), {
        variant: 'success',
        persist: true,
      })
    },
  })

  return (
    <Stack>
      <Alert
        severity="warning"
        sx={{
          my: 2,
          textAlign: 'center',
          fontSize: theme => theme.typography.body1.fontSize,
        }}
      >
        {t('views.login.disclaimer')}
      </Alert>

      <Stack
        gap={1}
        component="form"
        onSubmit={handleSubmit(validValues => {
          submitChangePassword.mutate(validValues)
        })}
      >
        <PasswordInput
          error={errors.currentPassword}
          label={t('views.login.currentPassword')}
          register={register('currentPassword')}
        />

        <Divider sx={{ my: 2 }}>{t('views.login.newPassword')}</Divider>

        <PasswordInput
          label={t('views.login.newPassword')}
          register={register('newPassword')}
        />

        <Collapse in={shouldExpandNewPasswordValidations}>
          <Stack sx={{ mb: 2 }}>
            <PasswordCriterias
              password={watch('newPassword')}
              setIsPasswordValid={setIsNewPasswordStrong}
            />
          </Stack>
        </Collapse>

        <PasswordInput
          error={errors.repeatNewPassword}
          label={t('views.user.form.confirmPassword')}
          register={register('repeatNewPassword')}
        />

        <Stack sx={{ mt: 2 }} direction="row" justifyContent="center">
          <LoadingButton
            type="submit"
            variant="outlined"
            endIcon={<LockResetTwoTone />}
            loading={submitChangePassword.isLoading}
          >
            {t('views.toolbar.changePassword')}
          </LoadingButton>
        </Stack>
      </Stack>
    </Stack>
  )
}

interface PasswordInputProps {
  label: string
  error?: FieldError
  register: UseFormRegisterReturn<string>
}

function PasswordInput({ label, register, error }: PasswordInputProps) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)

  return (
    <TextField
      fullWidth
      autoComplete="off"
      variant="outlined"
      label={label + '*'}
      error={!!error?.message}
      helperText={error?.message}
      type={isPasswordVisible ? 'text' : 'password'}
      {...register}
      InputProps={{
        endAdornment: (
          <IconButton
            onClick={() => {
              setIsPasswordVisible(prev => !prev)
            }}
          >
            {isPasswordVisible ? (
              <VisibilityOff color="primary" />
            ) : (
              <Visibility />
            )}
          </IconButton>
        ),
      }}
      sx={{
        borderRadius: 2,
        fieldset: { border: 'none' },
        bgcolor: theme => theme.palette.background.paper,
        input: {
          ':hover': {
            transition: 'all 500ms ease',
            color: theme => theme.palette.primary.light,
          },
        },
      }}
    />
  )
}
