import { PlayCircleFilled as PlayCircleFilledIcon } from '@mui/icons-material'
import { Grid, IconButton, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import ReactPlayer from 'react-player'

const useStyles = makeStyles(_theme => ({
  audioHelp: {
    marginLeft: 2,
    font: 'Roboto',
    fontWeight: 400,
  },
}))

interface Props {
  audio: string
  playAudio: boolean
  handlePlayPause: () => void
  label?: string
}

function AudioHelp({ audio, playAudio, handlePlayPause, label = '' }: Props) {
  const classes = useStyles()

  const { t } = useTranslation()

  return (
    <div>
      {playAudio ? (
        <ReactPlayer
          width="300px"
          height="35px"
          controls
          playing={playAudio}
          url={audio}
          muted={!playAudio}
          config={{
            file: {
              forceAudio: true,
            },
          }}
        />
      ) : (
        <>
          <Grid container alignItems="center">
            <IconButton
              color="primary"
              onClick={() => {
                handlePlayPause()
              }}
              size="large"
            >
              <PlayCircleFilledIcon fontSize="large" />
            </IconButton>

            <Typography variant="subtitle1" className={classes.audioHelp}>
              {label || t('views.survey.audioHelp')}
            </Typography>
          </Grid>

          <ReactPlayer
            width="0px"
            height="0px"
            playing={playAudio}
            url={audio}
            muted={!playAudio}
            config={{
              file: {
                forceAudio: true,
              },
            }}
          />
        </>
      )}
    </div>
  )
}

export default AudioHelp
