import { useTranslation } from 'react-i18next'
import {
  Bar,
  BarChart,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts'

import { COLORS } from '../theme'

function renderCustomizedLabel(
  // TODO: Fix this type, quite difficult to find the correct type
  { value, x, y }: any,
  label: string,
) {
  const padding = 10

  return (
    <text
      style={{ fontFamily: 'Poppins', fontSize: 14, color: 'black' }}
      x={x}
      y={y && y - padding}
      textAnchor="top"
      dominantBaseline="top"
    >
      {value} {label}
    </text>
  )
}

interface SummaryBarChartProps {
  width?: string
  redIndicatorCount: number
  greenIndicatorCount: number
  isAnimationActive?: boolean
  yellowIndicatorCount: number
  skippedIndicatorCount: number
  doesNotApplyIndicatorCount: number
}

export default function SummaryBarChart({
  greenIndicatorCount,
  yellowIndicatorCount,
  redIndicatorCount,
  skippedIndicatorCount,
  doesNotApplyIndicatorCount,
  isAnimationActive = false,
  width,
}: SummaryBarChartProps) {
  const { t } = useTranslation()

  const data = [
    {
      name: 'data',
      red: redIndicatorCount,
      yellow: yellowIndicatorCount,
      green: greenIndicatorCount,
      skipped: skippedIndicatorCount,
      doesNotApply: doesNotApplyIndicatorCount,
    },
  ]

  return (
    <ResponsiveContainer width={width} height={280}>
      <BarChart
        data={data}
        barGap={35}
        barSize={18}
        layout="vertical"
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <XAxis hide type="number" />
        <YAxis hide dataKey="name" type="category" />

        <Bar
          isAnimationActive={isAnimationActive}
          dataKey="green"
          fill={COLORS.GREEN}
        >
          <LabelList
            dataKey="green"
            content={e => renderCustomizedLabel(e, t('views.dashboard.green'))}
          />
        </Bar>

        <Bar
          isAnimationActive={isAnimationActive}
          dataKey="yellow"
          fill={COLORS.YELLOW}
        >
          <LabelList
            dataKey="yellow"
            content={e => renderCustomizedLabel(e, t('views.dashboard.yellow'))}
          />
        </Bar>

        <Bar
          isAnimationActive={isAnimationActive}
          dataKey="red"
          fill={COLORS.RED}
        >
          <LabelList
            dataKey="red"
            content={e => renderCustomizedLabel(e, t('views.dashboard.red'))}
          />
        </Bar>

        {skippedIndicatorCount !== 0 && (
          <Bar
            isAnimationActive={isAnimationActive}
            dataKey="skipped"
            fill={COLORS.SKIPPED_GREY}
          >
            <LabelList
              dataKey="skipped"
              content={e =>
                renderCustomizedLabel(e, t('views.dashboard.skipped'))
              }
            />
          </Bar>
        )}

        {doesNotApplyIndicatorCount !== 0 && (
          <Bar
            isAnimationActive={isAnimationActive}
            dataKey="doesNotApply"
            fill={COLORS.DOES_NOT_APPLY_GREY}
          >
            <LabelList
              dataKey="doesNotApply"
              content={e =>
                renderCustomizedLabel(e, t('views.dashboard.doesNotApply'))
              }
            />
          </Bar>
        )}
      </BarChart>
    </ResponsiveContainer>
  )
}
