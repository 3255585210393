import { type ServerResponse, axios, serverHost } from '~/axios'
import { type Environment } from '~/utils/types/user'

interface DynamicLinkOptions {
  surveyDefinitionId: number
  username: string
  password: string
  toDate: string
  projects: number[]
}

export async function createDynamicLinkForSurvey(options: DynamicLinkOptions) {
  const response = await axios.post<
    ServerResponse<{ createDynamicSurvey: { url: string } }>
  >('/graphql', {
    query:
      'mutation createDynamicSurvey( $surveyDefinition: Long $user: String $password: String $toDate: String $projects: [Long] ) { createDynamicSurvey( surveyDefinition: $surveyDefinition user: $user password: $password toDate: $toDate projects: $projects ) { url } }',
    variables: {
      surveyDefinition: options.surveyDefinitionId,
      user: options.username,
      password: options.password,
      toDate: options.toDate,
      projects: options.projects,
    },
  })

  return response.data.data.createDynamicSurvey
}

export interface DynamicLinkDetails {
  accessToken: string
  createdBy: string
  createdDate: string
  env: Environment
  hub: number
  id: number
  organization: number
  status: 'ACTIVE'
  surveyDefinition: number
  toDate: string
  projects: number[]
}

export async function validateDynamicLinkToken(token: string) {
  const url = new URL(`/api/v1/public/survey/dynamic/details`, serverHost)
  url.searchParams.append('token', token)

  const response = await axios.get<DynamicLinkDetails>(url.toString())

  return response.data
}
