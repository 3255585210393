import { Grid } from '@mui/material'

import { useAppSelector } from '~/redux/hooks'
import { type FamilyFilterState } from '~/screens/Families'
import { useRole } from '~/utils/hooks/useRole'
import { canReadOrView } from '~/utils/permissions-utils'
import { ROLES, checkAccessToProjects } from '~/utils/role-utils'

import HubsFilter from './HubsFilter'
import OrganizationsFilter from './OrganizationsFilter'
import FacilitatorsFilter from './filters/FacilitatorsFilter'
import SearchInput from './inputs/SearchInput'
import ProjectsSelector from './selectors/ProjectsSelector'

interface FamilyFiltersProps {
  filters: FamilyFilterState
  setFilters: (updatedFilters: FamilyFilterState) => void
}

export default function FamiliesFilters({
  filters,
  setFilters,
}: FamilyFiltersProps) {
  const { userHasAnyRole } = useRole()

  const user = useAppSelector(state => {
    if (!state.user) throw new Error('No user')
    return state.user
  })

  const canShowHubFilters = userHasAnyRole([ROLES.ADMIN])
  const canShowFalicitatorFilter = userHasAnyRole([
    ROLES.ADMIN,
    ROLES.PS_TEAM,
    ROLES.HUB_ADMIN,
    ROLES.ORGANIZATION_ADMIN,
    ROLES.FACILITATOR_ADMIN,
  ])
  const canShowOrgFilters = userHasAnyRole([
    ROLES.ADMIN,
    ROLES.HUB_ADMIN,
    ROLES.ORGANIZATION_ADMIN,
  ])

  const canShowProjectsFilter =
    canReadOrView(user, 'facilitators', [
      ROLES.ADMIN,
      ROLES.PS_TEAM,
      ROLES.HUB_ADMIN,
      ROLES.FACILITATOR,
      ROLES.FACILITATOR_ADMIN,
      ROLES.ORGANIZATION_ADMIN,
    ]) && checkAccessToProjects(user)

  return (
    <Grid container spacing={2}>
      {canShowHubFilters && (
        <Grid item xs={12} md={6} lg={4}>
          <HubsFilter
            value={filters.hub}
            onChange={newHub => {
              setFilters({
                ...filters,
                hub: newHub,
                projects: [],
                organizations: [],
                facilitators: [],
              })
            }}
          />
        </Grid>
      )}
      {canShowOrgFilters && (
        <Grid item xs={12} md={6} lg={4}>
          <OrganizationsFilter
            value={filters.organizations}
            hubID={filters.hub?.value ?? null}
            onChange={organizations => {
              setFilters({
                ...filters,
                projects: [],
                facilitators: [],
                organizations,
              })
            }}
          />
        </Grid>
      )}
      <Grid item xs={12} md={6} lg={4}>
        <SearchInput
          value={filters.name}
          onChange={e => {
            setFilters({ ...filters, name: e })
          }}
        />
      </Grid>

      {canShowProjectsFilter && (
        <Grid item xs={12} md={6} lg={4}>
          <ProjectsSelector
            isMulti
            value={filters.projects}
            organizationIds={filters.organizations.map(o => o.value)}
            onChange={projects => {
              setFilters({ ...filters, projects })
            }}
          />
        </Grid>
      )}
      {canShowFalicitatorFilter && (
        <Grid item xs={12} md={6} lg={4}>
          <FacilitatorsFilter
            isMulti
            value={filters.facilitators}
            organizationIds={filters.organizations.map(o => o.value)}
            onChange={facilitators => {
              setFilters({ ...filters, facilitators })
            }}
          />
        </Grid>
      )}
    </Grid>
  )
}
