import { Logout } from '@mui/icons-material'
import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material'
import * as Sentry from '@sentry/react'
import mixpanel from 'mixpanel-browser'
//* NOTE: MomentJS Locales
import 'moment/dist/locale/es'
import 'moment/dist/locale/fr'
import 'moment/dist/locale/pt'
import 'moment/dist/locale/pt-br'
import 'moment/dist/locale/sk'
import { NavLink, useNavigate } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import { useWindowSize } from 'usehooks-ts'

import { axios } from '~/axios'
import PlatformLanguageSelector from '~/components/LanguageSelector'
import DesktopMenu from '~/components/header/DesktopMenu'
import HamburgerMenu from '~/components/header/HamburgerMenu'
import NotificationsMenu from '~/components/header/NotificationsMenu'
import { updateUser } from '~/redux/slices/user'
import { logout } from '~/services/auth-services'
import { useRole } from '~/utils/hooks/useRole'
import { permissionsItems } from '~/utils/permissions-utils'
import {
  NEW,
  ROLES,
  ROLES_MAP,
  canAccess,
  checkAccessToInterventions,
  checkAccessToProjects,
} from '~/utils/role-utils'

import { useAppDispatch, useAppSelector } from './redux/hooks'

export interface MenuTab {
  item: string
  platform?: string
  options?: string[]
}

export default function Header() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const intercom = useIntercom()
  const windowSize = useWindowSize()
  const user = useAppSelector(state => state.user)

  const { userHasAnyRole } = useRole()

  const [tabs, setTabs] = useState<MenuTab[]>([])
  const [userAnchorEl, setUserAnchorEl] = useState<HTMLElement | null>(null)

  const firstCharOfName = user?.name?.at(0) ?? user?.username?.at(0)

  useEffect(() => {
    if (!user) return
    const tabsByRole = ROLES_MAP[user.role] || []
    const permissionsRead = user.permissions
      ? user.permissions
          .filter(p => p.split('_')[1] === 'read')
          .map(p => p.split('_')[0])
      : []

    const tabsByPermissions: MenuTab[] = []

    permissionsItems.forEach(permissionItem => {
      permissionsRead.forEach(permissionRead => {
        if (
          permissionItem.item === permissionRead ||
          permissionItem.options?.find(o => o === permissionRead)
        ) {
          const existAlready = tabsByPermissions.findIndex(
            e => e.item === permissionItem.item,
          )
          if (permissionItem.options && permissionItem.options.length > 0) {
            if (existAlready !== -1) {
              tabsByPermissions[existAlready] = {
                item: permissionItem.item,
                platform: NEW,
                options: [
                  // @ts-expect-error
                  ...tabsByPermissions[existAlready].options,
                  permissionRead,
                ],
              }
            } else {
              tabsByPermissions.push({
                item: permissionItem.item,
                platform: NEW,
                options: [permissionRead],
              })
            }
          } else {
            tabsByPermissions.push({
              item: permissionRead,
            })
          }
        }
      })
    })

    const roleTabs = tabsByRole
      .filter(({ item: page, platform }) => {
        const specialPages = ['solutions', 'interventions', 'projects']
        const shouldShowPage = platform === NEW && !specialPages.includes(page)

        return (
          shouldShowPage ||
          (page === 'solutions' && canAccess(user, 'solutions')) ||
          (page === 'interventions' && checkAccessToInterventions(user)) ||
          (page === 'projects' &&
            platform === NEW &&
            checkAccessToProjects(user))
        )
      })
      .filter(({ item }) => {
        const hasUserPermissions = Array.isArray(user?.permissions)
        const hasUserAccessToPage = permissionsItems.some(p => p.item === item)

        return !hasUserPermissions || !hasUserAccessToPage
      })

    setTabs([...tabsByPermissions, ...roleTabs])
  }, [user])

  async function handleLogout() {
    try {
      if (!user) return
      await logout()
    } finally {
      navigate('/login')
      dispatch(updateUser(null))

      intercom.update({
        name: '',
        email: '',
        userId: '',
      })

      mixpanel.reset()
      Sentry.setUser(null)

      // NOTE: Reset axios headers (in case of using the dynamic link)
      axios.defaults.headers.post['Stoplight-Client-Id'] = 'stoplight-web'
    }
  }

  //* NOTE: Min width calc is based on 105px width per tab plus three fixed options
  const canShowDesktopMenu = windowSize.width > (tabs.length + 3) * 105

  if (!user) return null

  return (
    <AppBar
      elevation={1}
      position="fixed"
      sx={{ bgcolor: theme => theme.palette.background.default }}
    >
      <Toolbar disableGutters sx={{ px: 1 }}>
        {canShowDesktopMenu ? (
          <DesktopMenu tabs={tabs} />
        ) : (
          <HamburgerMenu tabs={tabs} />
        )}

        {/* Extra Buttons */}
        <Stack direction="row" ml="auto" alignItems="center" gap={2}>
          {canShowDesktopMenu && (
            <NavLink
              key="support"
              to={'/support'}
              style={{ textDecoration: 'none' }}
            >
              <Typography variant="subtitle1">
                {t('views.toolbar.support')}
              </Typography>
            </NavLink>
          )}

          <PlatformLanguageSelector />

          {userHasAnyRole([ROLES.PS_TEAM, ROLES.HUB_ADMIN]) && (
            <NotificationsMenu />
          )}

          <Box>
            <IconButton
              onClick={event => {
                setUserAnchorEl(event.currentTarget)
              }}
              size="small"
              aria-controls={userAnchorEl ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={userAnchorEl ? 'true' : undefined}
            >
              <Avatar
                sx={{ bgcolor: 'primary.main', textTransform: 'uppercase' }}
              >
                {firstCharOfName}
              </Avatar>
            </IconButton>
          </Box>
        </Stack>

        <Menu
          anchorEl={userAnchorEl}
          id="account-menu"
          open={Boolean(userAnchorEl)}
          onClose={() => {
            setUserAnchorEl(null)
          }}
          onClick={() => {
            setUserAnchorEl(null)
          }}
          slotProps={{
            paper: {
              sx: { bgcolor: theme => theme.palette.background.default },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem
            onClick={() => {
              navigate('/profile')
            }}
          >
            <Avatar
              sx={{ bgcolor: 'primary.main', textTransform: 'uppercase' }}
            >
              {firstCharOfName}
            </Avatar>
            <Box sx={{ ml: 1 }}>
              <Typography fontWeight="bold" color="primary">
                {user.name}
              </Typography>
              <Typography fontSize="small">{user.username}</Typography>
            </Box>
          </MenuItem>
          <MenuItem
            onClick={() => {
              void handleLogout()
            }}
          >
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            {t('views.toolbar.logout')}
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  )
}
