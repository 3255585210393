import axios from 'axios'

import { type ServerResponse } from '~/axios'

export interface Project {
  id: number
  title: string
  description: string
  active: boolean
  color: string
}

export async function getProjectsPaginated({
  page,
  filter,
  organizations,
}: {
  page: number
  filter: string
  organizations: number[]
}) {
  interface SearchProjectsResponse {
    content: Project[]
    totalElements: number
    totalPages: number
  }

  const query = /* GraphQL */ `
    query SearchProjects($page: Int, $filter: String, $organizations: [Long]) {
      searchProjects(
        page: $page
        filter: $filter
        organizations: $organizations
      ) {
        content {
          id
          title
          description
          active
          color
        }
        totalElements
        totalPages
      }
    }
  `

  return await axios<
    ServerResponse<{ searchProjects: SearchProjectsResponse }>
  >({
    method: 'post',
    url: '/graphql',
    data: {
      query,
      variables: {
        page,
        filter,
        organizations,
      },
    },
  })
}
