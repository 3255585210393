// React
import React from 'react'

import {
  Add as AddIcon,
  CloudUpload as CloudUploadIcon,
  HighlightOff as NotInterestedIcon,
} from '@mui/icons-material'
import {
  Box,
  FormHelperText,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import * as Excel from 'exceljs/dist/exceljs'

// Project
import RadioInput from '~/components/RadioInput'

const useStyles = makeStyles(theme => ({
  label: {
    fontWeight: 500,
    color: theme.palette.grey[600],
  },
  optionContainer: {
    marginLeft: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  optionNumber: {
    display: 'flex',
    justifyContent: 'inherit',
    marginRight: 4,
    minWidth: 20,
  },
  deleteIcon: {
    height: 'min-content',
    color: 'red',
    opacity: '50%',
    padding: 4,
    marginLeft: 4,
    '&:hover': {
      opacity: '100%',
    },
  },
  addOptionContainer: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  uploadFileContainer: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
  },
  radioButton: {
    '& .MuiFormControlLabel-root': {
      marginTop: 0,
    },
  },
  filterInput: {
    height: 15,
    paddingTop: '12.0px!important',
    paddingBottom: '12.0px!important',
    paddingRight: '14px!important',
    paddingLeft: '14px!important',
    fontFamily: 'Poppins',
    fontSize: '12px',
    backgroundColor: theme.palette.background.default,
  },
}))

const textInputRegex = /["\\]/g

export const EconomicOptionForm = ({
  options,
  updateOptions,
  otherOption,
  touched = true,
  focusOption,
  setIsInvalidForm,
  onFocusOption = e => {},
  onBlurOption = () => {},
  setTouched = () => {},
}) => {
  const classes = useStyles()
  const inputRef = useRef(null)
  const { t } = useTranslation()

  const [otherValue, setOtherValue] = useState('')
  const optionsLength = Array.isArray(options) ? options.length : 0

  const input = useMemo(
    () =>
      document.getElementById(
        focusOption !== null ? `${focusOption + 1}` : optionsLength - 1,
      ),
    [focusOption],
  )

  useEffect(() => {
    const otherOption = (options || []).find(option => option.otherOption)
    if (otherOption) {
      setOtherValue(otherOption.value)
    } else {
      setOtherValue('')
    }
  }, [options])

  useEffect(() => {
    if (input) {
      setTimeout(() => {
        input.focus()
      }, 0)
    } else if (inputRef.current) {
      setTimeout(() => inputRef.current.focus(), 0)
    }
  }, [optionsLength])

  useEffect(() => {
    if (!setIsInvalidForm) return
    const isInvalidForm = options.some(option =>
      option.text.match(textInputRegex),
    )

    setIsInvalidForm(isInvalidForm)
  }, [options])

  const addOption = ({ optionIndex }) => {
    const newOptions = Array.isArray(options) ? options : []
    if (
      typeof optionIndex === 'number' &&
      optionIndex + 1 !== newOptions.length
    ) {
      newOptions.splice(optionIndex + 1, 0, { value: '', text: '' })
    } else {
      newOptions.push({ value: '', text: '' })
    }
    updateOptions(newOptions)
  }

  const updateOption = (optionIndex, option, updateOtherValue) => {
    const newOptions = updateOtherValue
      ? Array.from(options).map(o => ({ ...o, otherOption: false }))
      : Array.from(options)
    newOptions[optionIndex] = {
      ...option,
      otherOption: updateOtherValue,
    }
    updateOptions(newOptions)
    updateOtherValue && setOtherValue(optionValue)
  }

  const deleteOption = optionIndex => {
    onBlurOption()
    const newOptions = Array.from(options)
    newOptions.splice(optionIndex, 1)
    updateOptions(newOptions)
  }

  const handleKeyPress = (event, optionIndex) => {
    if (event.key !== 'Enter') return

    event.preventDefault()
    addOption(optionIndex)
  }

  const handleOnChange = event => {
    const file = event.target.files[0]
    const wb = new Excel.Workbook()
    const reader = new FileReader()
    const fileOptions = []
    setTouched()

    reader.readAsArrayBuffer(file)
    reader.onload = () => {
      const buffer = reader.result
      try {
        wb.xlsx.load(buffer).then(workbook => {
          workbook.eachSheet(sheet => {
            sheet.eachRow(row => {
              fileOptions.push(row.values[1] ? row.values[1].toString() : '')
            })
            const newOptions = [
              ...options,
              ...fileOptions.map(option => ({
                value: option.toUpperCase(),
                text: option,
              })),
            ]
            updateOptions(newOptions)
          })
        })
      } catch (error) {
        console.error(error)
      }
    }
  }

  const showAddOption =
    focusOption === optionsLength - 1 ||
    focusOption === null ||
    optionsLength <= 1 ||
    focusOption > optionsLength - 1

  return (
    <div>
      <Typography variant="subtitle2" className={classes.label}>
        {t('views.surveyBuilder.options')}
      </Typography>
      <div style={{ minHeight: 15 }}>
        {Array.isArray(options) &&
          options.map((option, optionIndex) => (
            <React.Fragment key={optionIndex}>
              <div
                key={optionIndex}
                className={classes.optionContainer}
                onKeyDown={e => {
                  handleKeyPress(e, optionIndex, options)
                }}
              >
                <div className={classes.optionNumber}>
                  <Typography variant="subtitle2">{optionIndex + 1}</Typography>
                  <Typography variant="subtitle2">{'-'}</Typography>
                </div>

                <TextField
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  value={option.text}
                  inputRef={inputRef}
                  id={`${optionIndex}`}
                  onBlur={onBlurOption}
                  inputProps={{ maxLength: '500' }}
                  error={option.text.match(textInputRegex)}
                  onFocus={() => {
                    onFocusOption(optionIndex)
                  }}
                  InputProps={{ classes: { input: classes.filterInput } }}
                  helperText={
                    option.text.match(textInputRegex) &&
                    t('validation.invalidCharacter', {
                      error: option.text.match(textInputRegex)[0],
                    })
                  }
                  onChange={e => {
                    setTouched()
                    const newOption = {
                      ...option,
                      text: e.target.value,
                      value: e.target.value,
                    }
                    updateOption(
                      optionIndex,
                      newOption,
                      option.value === otherValue && option.value !== '',
                    )
                  }}
                />
                <Tooltip
                  title={t('general.delete')}
                  className={classes.deleteIcon}
                >
                  <IconButton
                    color="default"
                    component="span"
                    onMouseDown={() => {
                      deleteOption(optionIndex)
                    }}
                    size="large"
                  >
                    <NotInterestedIcon />
                  </IconButton>
                </Tooltip>
                {otherOption && (
                  <div className={classes.radioButton}>
                    <RadioInput
                      label={''}
                      value={option.value}
                      currentValue={otherValue}
                      onChange={() => {
                        const newOption = {
                          ...option,
                          otherOption: true,
                        }
                        updateOption(optionIndex, newOption, true)
                      }}
                    />
                  </div>
                )}
              </div>
              {optionIndex === focusOption &&
              focusOption !== optionsLength - 1 &&
              optionIndex + 1 !== options.length ? (
                <div
                  className={classes.addOptionContainer}
                  onMouseDown={() => {
                    addOption({ optionIndex })
                  }}
                >
                  <AddIcon style={{ fontSize: 16 }} />
                  <Typography variant="subtitle2">
                    {t('views.surveyBuilder.addOption')}
                  </Typography>
                </div>
              ) : null}
            </React.Fragment>
          ))}
      </div>
      {(!Array.isArray(options) || options.length === 0) && touched && (
        <FormHelperText error={true} style={{ textAlign: 'center' }}>
          {t('views.surveyBuilder.missingOptions')}
        </FormHelperText>
      )}
      <Box display="flex">
        {showAddOption ? (
          <div className={classes.addOptionContainer} onMouseDown={addOption}>
            <AddIcon style={{ fontSize: 16 }} />
            <Typography variant="subtitle2">
              {t('views.surveyBuilder.addOption')}
            </Typography>
          </div>
        ) : null}
        <Tooltip title={t('views.surveyBuilder.uploadFileTooltip')}>
          <div className={classes.addOptionContainer}>
            <input
              type="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              hidden
              id="icon-button-file"
              style={{ cursor: 'pointer' }}
              onClick={e => (e.target.value = null)}
              onChange={handleOnChange}
            />
            <label
              className={classes.uploadFileContainer}
              htmlFor="icon-button-file"
            >
              <CloudUploadIcon style={{ fontSize: 16, margin: '0 4px' }} />
              <Typography variant="subtitle2">
                {t('views.surveyBuilder.uploadFile')}
              </Typography>
            </label>
          </div>
        </Tooltip>
      </Box>
    </div>
  )
}

export default EconomicOptionForm
