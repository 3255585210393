import React from 'react'

import { FormControlLabel, Switch } from '@mui/material'
import { withStyles } from '@mui/styles'
import PropTypes from 'prop-types'

const SwitchInput = ({ value, label, classes, ...props }) => (
  <FilledFormControlLabel
    control={
      <Switch checked={value} classes={classes} color="primary" {...props} />
    }
    label={label}
    value={value}
  />
)

const FilledFormControlLabel = withStyles(() => ({
  root: {
    wordBreak: 'break-word',
    marginLeft: 0,
    borderRadius: 50,
    minHeight: 37,
    marginTop: 10,
  },
  label: {
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeight: 500,
  },
}))(props => <FormControlLabel color={'primary'} {...props} />)

export default SwitchInput

SwitchInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}
