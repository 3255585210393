import React from 'react'

import { Box, Button, CircularProgress, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import propTypes from 'prop-types'

import Card from './Card'

const useStyles = makeStyles(theme => ({
  spinnerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    backgroundColor: theme.palette.text.secondary,
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
  },
  showMoreContainer: {
    width: '100%',
    marginTop: 30,
    marginBottom: 30,
    display: 'flex',
  },
  showMoreButton: {
    margin: 'auto',
  },
}))

const StakeholderList = ({
  loading,
  items,
  handleEditModal,
  handleDeleteModal,
  paginationData,
  setPaginationData,
  canWrite,
  canDelete,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const nextPage = () => {
    setPaginationData({
      ...paginationData,
      page: paginationData.page + 1,
    })
  }
  return (
    <Box position="relative">
      {loading && (
        <Box className={classes.spinnerWrapper}>
          <CircularProgress size={50} thickness={2} />
        </Box>
      )}
      <Grid container spacing={2}>
        {items.map((item, index) => {
          return (
            <Card
              key={index}
              item={item}
              canWrite={canWrite}
              canDelete={canDelete}
              handleEditModal={handleEditModal}
              handleDeleteModal={handleDeleteModal}
            />
          )
        })}
      </Grid>

      <Box className={classes.showMoreContainer}>
        {paginationData.page + 1 < paginationData.totalPages && (
          <Button
            variant="contained"
            aria-label="Show more"
            className={classes.showMoreButton}
            component="span"
            onClick={() => {
              nextPage()
            }}
          >
            {t('general.showMore')}
          </Button>
        )}
      </Box>
    </Box>
  )
}

StakeholderList.propTypes = {
  loading: propTypes.bool,
  items: propTypes.array,
  handleEditModal: propTypes.func,
  handleDeleteModal: propTypes.func,
  paginationData: propTypes.object,
  setPaginationData: propTypes.func,
  canWrite: propTypes.func,
  canDelete: propTypes.func,
}

export default StakeholderList
