import React from 'react'

import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

import QuestionIcon from '../../../components/QuestionIcon'
import { COLORS } from '../../../theme'

const useStyles = makeStyles(() => ({
  answerTypeIcon: {
    width: 30,
    color: COLORS.MEDIUM_GREY,
    margin: '0px 10px 0px 2px',
  },
  label: {
    color: COLORS.MEDIUM_GREY,
  },
}))

const QuestionType = ({ answerType }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const labelForType = type => t(`answerType.${type}`)

  return (
    <Box display="flex" alignItems="center" flexDirection="row" pl={0.5}>
      <QuestionIcon type={answerType} iconClass={classes.answerTypeIcon} />
      <Typography variant="subtitle2" className={classes.label}>
        {labelForType(answerType)}
      </Typography>
    </Box>
  )
}

export default QuestionType
