import { Close as CloseIcon } from '@mui/icons-material'
import {
  Box,
  Button,
  CardMedia,
  CircularProgress,
  Fade,
  IconButton,
  Modal,
  Typography,
} from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import mixpanel from 'mixpanel-browser'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'

import { saveDraft } from '~/api'
import face from '~/assets/serious_face.png'
import { useAppDispatch, useAppSelector } from '~/redux/hooks'
import { updateBackupUser } from '~/redux/slices/backupUser'
import { updateUser } from '~/redux/slices/user'
import { normalizeDraftForSnapshot } from '~/services/lifemap-survey-services'
import { useRole } from '~/utils/hooks/useRole'
import { ROLES } from '~/utils/role-utils'

interface SaveDraftModalProps {
  open: boolean
  onClose: () => void
}

type NormalizedDraft = ReturnType<typeof normalizeDraftForSnapshot>

function SaveDraftModal({ open, onClose }: SaveDraftModalProps) {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { userHasAnyRole } = useRole()
  const { closeSnackbar, enqueueSnackbar } = useSnackbar()

  const user = useAppSelector(state => {
    if (!state.user) throw new Error('No user')
    return state.user
  })

  const currentSurvey = useAppSelector(state => {
    if (!state.currentSurvey) throw new Error('No currentSurvey')
    return state.currentSurvey
  })

  const currentDraft = useAppSelector(state => state.currentDraft)

  const backupUser = useAppSelector(state => state.backupUser)

  const [isSavingDraft, setIsSavingDraft] = useState(false)

  const saveDraftMutation = useMutation({
    mutationFn: async (normalizedDraft: NormalizedDraft) =>
      await saveDraft(normalizedDraft),
  })

  async function onSaveClicked() {
    setIsSavingDraft(true)

    try {
      const normalizedDraft = normalizeDraftForSnapshot(currentDraft!)
      await saveDraftMutation.mutateAsync(normalizedDraft)

      mixpanel.track('leave_survey', {
        env: user.env,
        role: user.role,
        draft: currentDraft,
        user: user.username,
        survey: currentSurvey.title,
      })

      if (backupUser) {
        dispatch(updateUser(backupUser))
        dispatch(updateBackupUser(null))
        backupUser.dynamicLink ? navigate('/welcome') : navigate('/surveys')
        return
      }

      const hasUserFamilyRole = userHasAnyRole([ROLES.FAMILY])
      if (user.dynamicLink) {
        navigate('/welcome')
        return
      }
      if (hasUserFamilyRole) {
        navigate('/my-profile')
        return
      }
      navigate('/surveys')
    } catch (error) {
      enqueueSnackbar(t('views.saveDraftModal.cannotSave'), {
        variant: 'error',
        action: key => (
          <IconButton
            key="dismiss"
            onClick={() => {
              closeSnackbar(key)
            }}
            size="large"
          >
            <CloseIcon style={{ color: 'white' }} />
          </IconButton>
        ),
      })
      onClose()
      setIsSavingDraft(false)
    }
  }
  return (
    <Modal
      open={open}
      onClose={isSavingDraft ? undefined : onClose}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Fade in={open}>
        <Box
          sx={{
            maxWidth: 470,
            outline: 'none',
            display: 'flex',
            borderRadius: 3,
            position: 'absolute',
            alignItems: 'center',
            p: { xs: 2, sm: 4 },
            flexDirection: 'column',
            backgroundColor: theme => theme.palette.background.default,
          }}
        >
          <CardMedia
            image={face}
            component="img"
            sx={theme => ({
              mt: theme.spacing(1),
              mb: theme.spacing(3),
              width: 70,
              height: 70,
            })}
          />

          <Typography sx={{ textAlign: 'center' }} gutterBottom variant="h4">
            {t('views.saveDraftModal.lifemapNotComplete')}
          </Typography>
          <Typography
            sx={{
              color: '#909090',
              fontSize: '14px',
              textAlign: 'center',
            }}
            variant="subtitle1"
          >
            {t('views.saveDraftModal.willSaveDraft')}
          </Typography>
          <Typography
            sx={{
              color: '#626262',
              fontSize: '14px',
              textAlign: 'center',
              mb: theme => theme.spacing(2),
            }}
            variant="h5"
          >
            {t('views.saveDraftModal.sureWantToExit')}
          </Typography>
          {isSavingDraft && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mb: theme => theme.spacing(3),
              }}
            >
              <CircularProgress size={50} thickness={3} />
            </Box>
          )}
          <Box sx={{ display: 'flex', gap: 2, width: '80%' }}>
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              onClick={onSaveClicked}
              disabled={isSavingDraft}
            >
              {t('general.yes')}
            </Button>
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              onClick={onClose}
              disabled={isSavingDraft}
            >
              {t('general.no')}
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  )
}

export default SaveDraftModal
