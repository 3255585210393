import {
  CheckCircle as CheckIcon,
  Close as CloseIcon,
} from '@mui/icons-material'
import { Grid, IconButton, Modal, Tooltip, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { supportedLanguages } from '../../api'

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'auto',
    maxHeight: '100vh',
  },
  container: {
    padding: '2em',
    backgroundColor: theme.palette.background.default,
    outline: 'none',
    width: '85vw',
    maxWidth: 1000,
    height: '50vh',
    minHeight: 500,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: '1em',
      paddingTop: '2.5rem',
      height: '100vh',
      width: '100vw',
    },
    overflowY: 'auto',
  },
  closeIcon: {
    position: 'absolute',
    top: 5,
    right: 5,
    marginBottom: 15,
  },
  columnContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: '8px 0',
  },
  label: {
    fontFamily: 'Open Sans',
    fontWeight: 500,
  },
  answer: {
    fontFamily: 'Open Sans',
    color: 'rgba(0,0,0,0.5)',
    textAlign: 'justify',
  },
}))

const EconomicLibraryDetailsModal = ({ open, question, onClose }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [languages, setLanguages] = useState([])

  useEffect(() => {
    if (Array.isArray(languages) && languages.length === 0 && open)
      supportedLanguages()
        .then(response => {
          const langs = response.data.data.supportedLanguages
          setLanguages(langs)
        })
        .catch(e => {
          console.log(e)
        })
  }, [open])

  const findLanguage = langCode =>
    languages.find(l => l.code === langCode) || {}

  const TextAnswer = ({ label, answer }) => (
    <div className={classes.columnContainer}>
      <Typography variant="h6" align="center" className={classes.label}>
        {label}
      </Typography>
      <Typography variant="h6" align="center" className={classes.answer}>
        {answer}
      </Typography>
    </div>
  )
  const ArrayAnswer = ({ label, answers }) => (
    <div className={classes.columnContainer}>
      <Typography variant="h6" align="center" className={classes.label}>
        {label}
      </Typography>
      {Array.isArray(answers) && (
        <ul style={{ margin: 0 }}>
          {answers.map(({ text }, index) => (
            <Typography
              key={index}
              variant="h6"
              className={classes.answer}
              style={{ margin: 0 }}
            >
              <li>{text}</li>
            </Typography>
          ))}
        </ul>
      )}
    </div>
  )

  const hasOptions = valueObject => {
    const answerType = valueObject ? valueObject.value : ''
    return (
      answerType === 'select' ||
      answerType === 'radio' ||
      answerType === 'checkbox'
    )
  }

  return (
    <Modal
      disableEnforceFocus
      disableAutoFocus
      className={classes.modal}
      open={open}
      onClose={onClose}
    >
      <div className={classes.container}>
        <Typography
          variant="h5"
          align="center"
          style={{ marginBottom: '2rem' }}
        >
          {t('views.economic.form.details')}
        </Typography>
        <IconButton
          className={classes.closeIcon}
          key="dismiss"
          onClick={() => onClose(false)}
          size="large"
        >
          <CloseIcon style={{ color: 'green' }} />
        </IconButton>

        <Grid container>
          <Grid item md={8} xs={12}>
            <TextAnswer
              label={`${t('views.indicator.create.indicatorTitle')}:`}
              answer={question.questionText}
            />
          </Grid>
          <Grid item md={4} xs={12} container>
            {question.verified && (
              <Tooltip title={t('views.indicator.create.verified')}>
                <CheckIcon
                  className={classes.icon}
                  style={{ color: '#309E43' }}
                />
              </Tooltip>
            )}
          </Grid>
        </Grid>

        <Grid container>
          <Grid item md={4} sm={6} xs={12}>
            <TextAnswer
              label={`${t('views.indicator.create.shortName')}:`}
              answer={question.shortName}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <TextAnswer
              label={`${t('views.indicator.create.codeName')}:`}
              answer={question.codeName}
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item md={4} sm={6} xs={12}>
            <TextAnswer
              label={`${t('views.languageFilter.label')}:`}
              answer={findLanguage(question.language).description}
            />
          </Grid>

          <Grid item md={4} sm={6} xs={12}>
            <TextAnswer
              label={t('views.economic.form.answerType')}
              answer={question.answerType ? question.answerType.label : ''}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextAnswer
              label={t('views.indicator.filter.stoplights')}
              answer={
                question.stoplightType ? question.stoplightType.label : ''
              }
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item md={4} sm={6} xs={12}>
            <TextAnswer
              label={`${t('views.economic.form.topic')}:`}
              answer={question.topic}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextAnswer
              label={`${t('views.solutions.form.country')}:`}
              answer={question.country ? question.country.label : ''}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextAnswer
              label={`${t('views.indicator.filter.measurement')}:`}
              answer={
                question.measurementUnit ? question.measurementUnit.label : ''
              }
            />
          </Grid>
        </Grid>

        {hasOptions(question.answerType) && (
          <Grid container>
            <Grid item md={4} sm={6} xs={12}>
              <ArrayAnswer
                label={`${t('views.surveyBuilder.options')}:`}
                answers={question.options}
              />
            </Grid>
          </Grid>
        )}
      </div>
    </Modal>
  )
}

export default EconomicLibraryDetailsModal
