async function getCurrentPositionPromisified(options?: PositionOptions) {
  return await new Promise(
    (resolve: PositionCallback, reject?: PositionErrorCallback | null) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, options)
    },
  )
}

export function useCurrentPosition() {
  const [gettingCurrentPosition, setGettingCurrentPosition] = useState(false)

  const getCurrentPosition = useCallback(async (options?: PositionOptions) => {
    try {
      setGettingCurrentPosition(true)
      const position = await getCurrentPositionPromisified(options)
      return position
    } finally {
      setGettingCurrentPosition(false)
    }
  }, [])

  return {
    gettingCurrentPosition,
    getCurrentPosition,
  }
}
