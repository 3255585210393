import React from 'react'

import { FormHelperText, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import Select, { components } from 'react-select'

import { selectStyle } from '../../utils/styles-utils'

const useStyles = makeStyles(theme => ({
  container: {
    margin: '5px 0',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
      marginBottom: 5,
      marginTop: 0,
    },
  },
  label: {
    marginBottom: 5,
    fontSize: 14,
  },
  selector: {
    width: '100%',
  },
}))

/**
 * @typedef {object} GenericSelectorProps
 * @property {string} label
 * @property {boolean} withTitle
 * @property {boolean} required
 * @property {boolean} error
 * @property {string?} errorMessage
 * @property {boolean} loading
 * @property {object} customClasses
 * @property {{value: string, label: string} | null} value
 * @property {Array<{value: string, label: string | JSX.Element}>} options
 * @property {({value: string, label: string}) => void} onChange
 * @property {boolean} isClearable
 */

/**
 * @param {GenericSelectorProps} props
 * @returns {JSX.Element}
 */
export default function GenericSelector({
  label,
  withTitle,
  required,
  error,
  errorMessage = '',
  loading,
  customClasses = {},
  value,
  options = [],
  onChange,
  isClearable = false,
  ...props
}) {
  const { t } = useTranslation()
  const classes = useStyles()
  const message = `${label} ${required ? ' *' : ''}`

  useEffect(() => {
    if (value && Array.isArray(value)) {
      onChange(value)
      return
    }

    // FIX: i suppose that this conditional checks if the value is a plain value like "VALUE" but, if you use [] you'll enter the condition and that's an unwanted behavior
    if (value && !value.hasOwnProperty('value') && options.length > 0) {
      const data = options.find(o => o.value === value)
      onChange(data)
    }
  }, [value, options])

  return (
    <div className={clsx(classes.container, customClasses.container)}>
      {withTitle && (
        <Typography
          variant="subtitle1"
          className={clsx(classes.label, customClasses.label)}
        >
          {message}
        </Typography>
      )}
      <div className={clsx(classes.selector, customClasses.selector)}>
        <Select
          value={value}
          options={options}
          onChange={onChange}
          placeholder={!withTitle ? message : ''}
          loadingMessage={() => t('views.languageFilter.loading')}
          noOptionsMessage={() => t('general.noOptionsToShow')}
          styles={selectStyle}
          closeMenuOnSelect
          hideSelectedOptions
          isClearable={isClearable}
          components={{
            DropdownIndicator,
          }}
          {...props}
        />
      </div>
      {error && (
        <FormHelperText error={error}>
          {errorMessage || t('validation.fieldIsRequired')}
        </FormHelperText>
      )}
    </div>
  )
}

const DropdownIndicator = props =>
  props.isDisabled ? (
    <React.Fragment />
  ) : (
    <components.DropdownIndicator {...props} />
  )
