import { FormControl } from '@mui/material'
import { connect } from 'formik'
import { get } from 'lodash'
import propTypes from 'prop-types'

import { getErrorLabelForPath, pathHasError } from '../utils/form-utils'
// Project
import Autocomplete from './Autocomplete'
import StyledFormLabel from './StyledFormLabel'

const AutocompleteWithFormik = ({
  classes,
  formik,
  name,
  label,
  required,
  textFieldProps,
  tReady,
  // If these props are passed, it'll calculate the options and value for the autocomplete
  rawOptions,
  valueKey,
  labelKey,
  // These props defeat the keys and rawOptions. Won't try to know what's current value and options
  value,
  options,
  maxSelectMenuHeight,
  ...props
}) => {
  const { t } = useTranslation()
  const error = pathHasError(name, formik.touched, formik.errors)
  const helperText = getErrorLabelForPath(
    name,
    formik.touched,
    formik.errors,
    t,
  )

  let effectiveOptions = options
  let effectiveValue = value
  // If parent specifies the following props, we can infer what are the actual
  // options and the value from the raw options array, and the keys for
  // label and value
  if (rawOptions && valueKey && labelKey) {
    effectiveOptions = (rawOptions || []).map(o => ({
      label: o[labelKey],
      value: o[valueKey],
    }))
    const valueFromFormik = get(formik.values, name)
    const selectedOption = rawOptions.find(e => e[valueKey] === valueFromFormik)
    const effectiveLabel = selectedOption ? selectedOption[labelKey] : ''
    effectiveValue = {
      value: valueFromFormik,
      label: valueFromFormik ? effectiveLabel : '',
    }
  }

  const onBlur = () => setTimeout(() => formik.setFieldTouched(name), 250)
  const onChange = v => formik.setFieldValue(name, v ? v.value : '')
  const innerProps = {
    name,
    options: effectiveOptions,
    value: effectiveValue,
    onBlur,
    onChange,
    maxSelectMenuHeight,
    fullWidth: true,
  }
  const autocompleteProps = { ...innerProps, ...props }
  const fieldProps = {
    required,
    label: '',
    error,
    helperText,
    ...{ ...(textFieldProps || {}) },
  }

  return (
    <FormControl style={{ width: '100%' }}>
      <StyledFormLabel component="legend" required={required} error={error}>
        {label}
      </StyledFormLabel>

      <Autocomplete {...autocompleteProps} textFieldProps={fieldProps} />
    </FormControl>
  )
}

AutocompleteWithFormik.propTypes = {
  name: propTypes.string.isRequired,
  label: propTypes.string,
  rawOptions: propTypes.array,
  valueKey: propTypes.string,
  labelKey: propTypes.string,
}

export default connect(AutocompleteWithFormik)
