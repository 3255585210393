import React from 'react'

import { Button, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import propTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: 20,
    paddingBottom: 40,
  },
  buttonStyle: {
    textDecoration: 'none',
  },
}))

const ActionsPanel = ({ readOnly, handleClick, actionText, canWrite }) => {
  const classes = useStyles()
  return (
    <Grid
      container
      justifyContent="space-between"
      spacing={2}
      className={classes.container}
    >
      <Grid item xs={12} md={6} sm={6} lg={6}></Grid>
      <Grid
        item
        xs={12}
        md={6}
        sm={6}
        lg={6}
        container
        justifyContent="flex-end"
      >
        {!readOnly && canWrite() && (
          <Button
            variant="contained"
            className={classes.buttonStyle}
            onClick={handleClick}
          >
            {actionText}
          </Button>
        )}
      </Grid>
    </Grid>
  )
}

ActionsPanel.propTypes = {
  readOnly: propTypes.bool,
  handleClick: propTypes.func,
  actionText: propTypes.string,
  canWrite: propTypes.func,
}

export default ActionsPanel
