import { type PayloadAction, createSlice } from '@reduxjs/toolkit'

import { type User } from '~/utils/types/user'

const userSlice = createSlice({
  name: 'user',
  initialState: null as User | null,
  reducers: {
    updateUser: (_state, action: PayloadAction<User | null>) => action.payload,
  },
})

export const { updateUser } = userSlice.actions

export default userSlice.reducer
