import GoogleLibPhoneNumber from 'google-libphonenumber'
import { get, intersectionWith, omit, startCase } from 'lodash'
import * as Yup from 'yup'

import { type EconomicSurveyData, type LastSnapshot } from '~/api'
import {
  type CurrentSurvey,
  type SurveyEconomicQuestion,
} from '~/utils/types/current-survey'

import { callingCodes } from './calling-codes'
import { CONDITION_TYPES, shouldShowQuestion } from './conditional-logic'
import { type CurrentDraft } from './types/current-draft'

export const SNAPSHOTS_STATUS = {
  DRAFT: 'DRAFT',
  COMPLETED: 'COMPLETED',
}

type EconomicQuestion = CurrentSurvey['surveyEconomicQuestions'][number]
export function getEconomicScreens(survey: CurrentSurvey) {
  let totalScreens = 0
  let currentTopic = ''

  const questionsPerScreen: Array<{
    forFamilyMember: EconomicQuestion[]
    forFamily: EconomicQuestion[]
  }> = []

  //* NOTE: Loop over the questions and separate them by screen
  survey.surveyEconomicQuestions.forEach(question => {
    const isSameTopic = question.topic === currentTopic
    const isQuestionForMember = question.forFamilyMember

    //* NOTE: If the topic of the questions change, change the page
    if (!isSameTopic) {
      currentTopic = question.topic
      totalScreens += 1
    }

    //* NOTE: If the Nth question per screen is empty
    //* then create a new question object:
    const questionsPerScreenIdx = totalScreens - 1
    const questionsPerScreenObject = questionsPerScreen[questionsPerScreenIdx]

    if (!questionsPerScreenObject) {
      questionsPerScreen[questionsPerScreenIdx] = {
        forFamily: [],
        forFamilyMember: [],
      }
    }

    if (!isQuestionForMember) {
      questionsPerScreen[questionsPerScreenIdx].forFamily.push(question)
    }

    if (isQuestionForMember) {
      questionsPerScreen[questionsPerScreenIdx].forFamilyMember.push(question)
    }
  })

  return { questionsPerScreen }
}

export function getConditionalQuestions(survey: CurrentSurvey) {
  const surveyEconomicQuestions = survey.surveyEconomicQuestions || []
  const conditionalQuestions: CurrentSurvey['surveyEconomicQuestions'] = []

  surveyEconomicQuestions.forEach(economicQuestion => {
    const isConditionalQuestion =
      economicQuestion.conditions?.length > 0 ||
      economicQuestion.conditionGroups?.length > 0

    if (isConditionalQuestion) {
      conditionalQuestions.push(economicQuestion)
    } else {
      //* NOTE: Checking conditional options only if needed
      const options = economicQuestion.options || []
      for (const option of options) {
        if (option.conditions && option.conditions.length > 0) {
          conditionalQuestions.push(economicQuestion)
          return
        }
      }
    }
  })
  return conditionalQuestions
}

export function getElementsWithConditionsOnThem(
  conditionalQuestions: EconomicQuestion[],
) {
  type Condition = EconomicQuestion['conditions'][number]

  const questionsWithConditions: string[] = []
  const memberKeysWithConditions: string[] = []

  function populateConditionsArrays(condition: {
    codeName: string
    type?: string
    scope?: string
  }) {
    const scope = condition.scope || condition.type
    const isAgeRelatedCondition = scope === CONDITION_TYPES.FAMILY

    const isCodenameAlreadyAdded = questionsWithConditions.includes(
      condition.codeName,
    )

    const isCodenameAlreadyAddedForMembers = memberKeysWithConditions.includes(
      condition.codeName,
    )

    if (!isAgeRelatedCondition && !isCodenameAlreadyAdded) {
      questionsWithConditions.push(condition.codeName)
    }

    if (isAgeRelatedCondition && !isCodenameAlreadyAddedForMembers) {
      memberKeysWithConditions.push(condition.codeName)
    }
  }

  conditionalQuestions.forEach(conditionalQuestion => {
    let conditions: Condition[] = []
    const { conditionGroups } = conditionalQuestion

    const hasConditionGroups = (conditionGroups?.length ?? 0) > 0

    if (hasConditionGroups) {
      conditionGroups.forEach(conditionGroup => {
        conditions = [...conditions, ...conditionGroup.conditions]
      })
    } else {
      conditions = conditionalQuestion.conditions ?? []
    }

    conditions.forEach(populateConditionsArrays)

    const options = conditionalQuestion.options || []

    options.forEach(option => {
      const { conditions: optionConditions = [] } = option
      optionConditions.forEach(populateConditionsArrays)
    })
  })
  return {
    questionsWithConditionsOnThem: questionsWithConditions,
    memberKeysWithConditionsOnThem: memberKeysWithConditions,
  }
}

function dropUnnecessaryFields(member: EconomicSurveyData) {
  if (member.value && member.other) {
    return omit(member, ['multipleValue'])
  }

  if (member.value && !member.other) {
    return omit(member, ['multipleValue', 'other'])
  }

  if (member.multipleValue) {
    return omit(member, ['value', 'other'])
  }

  return member
}

export function snapshotToDraft(
  snapshot: LastSnapshot,
  familyId: number,
  isJustStoplight: boolean,
  economicQuestions: SurveyEconomicQuestion[],
) {
  type ClonedDraft = Partial<LastSnapshot>
  type ClonedFamily = Partial<
    ClonedDraft['family'] & {
      familyId: number
      familyMembersList: LastSnapshot['family']['familyMemberDTOList']
    }
  >

  const clonedSnapshot: ClonedDraft = structuredClone(snapshot)
  const familyData: ClonedFamily = structuredClone(snapshot.family)

  const previousIndicatorSurveyDataList = [
    ...(clonedSnapshot.previousIndicatorSurveyDataList ?? []),
  ]
  const previousIndicatorPriorities = [
    ...(clonedSnapshot.snapshotStoplightPriorities ?? []),
  ]
  const previousIndicatorAchivements = [
    ...(clonedSnapshot.snapshotStoplightAchievements ?? []),
  ]

  delete clonedSnapshot.snapshotStoplightPriorities
  delete clonedSnapshot.snapshotStoplightAchievements

  familyData.familyId = familyId

  /**
   * Autofill socio-economic questions based on the answers of the last snapshot.
   * Only autofill the questions that exist in the survey definition.
   */
  const generalQuestions = economicQuestions.filter(
    question => !question.forFamilyMember,
  )
  const answersOfGeneralQuestionsInSurveyDefinition = intersectionWith(
    clonedSnapshot.economicSurveyDataList,
    generalQuestions,
    (a, b) => a.key === b.codeName,
  )
  const economicSurveyDataList =
    answersOfGeneralQuestionsInSurveyDefinition.map(dropUnnecessaryFields)

  // @ts-expect-error - expects a complete type but we're passing a partial
  familyData.familyMembersList = clonedSnapshot.family?.familyMemberDTOList.map(
    member => {
      const memberQuestions = economicQuestions.filter(
        question => question.forFamilyMember,
      )
      const answersOfMemberQuestionsInSurveyDefinition = intersectionWith(
        member.socioEconomicAnswers,
        memberQuestions,
        (a, b) => a.key === b.codeName,
      )
      return {
        ...member,
        socioEconomicAnswers: answersOfMemberQuestionsInSurveyDefinition.map(
          dropUnnecessaryFields,
        ),
      }
    },
  )

  delete clonedSnapshot.family
  delete familyData.familyMemberDTOList
  if (!isJustStoplight) delete clonedSnapshot.snapshotId

  const draft = {
    sign: '',
    pictures: [],
    draftId: crypto.randomUUID(),
    created: Date.now(),
    indicatorSurveyDataList: [],
    priorities: [],
    achievements: [],
    ...clonedSnapshot,
    economicSurveyDataList,
    familyData,
    previousIndicatorSurveyDataList,
    previousIndicatorPriorities,
    previousIndicatorAchivements,
    lifemapNavHistory: [],
    isRetake: true,
  }

  return draft
}

/**
 * @param string
 * @returns string
 * @deprecated DO NOT USE THIS. Use css instead
 */
export function capitalize(string: string) {
  return startCase(string).replace(/ /g, '')
}

const fieldIsRequired = 'validation.fieldIsRequired'

function buildValidationForField(
  question: CurrentSurvey['surveyEconomicQuestions'][number],
) {
  let validation = Yup.string()

  if (question.required) {
    validation = validation.required(fieldIsRequired)
  }
  return validation
}

export const buildValidationSchemaForQuestions = (
  questions: {
    forFamily: CurrentSurvey['surveyEconomicQuestions']
    forFamilyMember: CurrentSurvey['surveyEconomicQuestions']
  },
  draft: CurrentDraft,
) => {
  const forFamilySchema: Record<string, Yup.StringSchema<string>> = {}
  const familyQuestions = questions?.forFamily || []

  familyQuestions.forEach(question => {
    const showQuestion = shouldShowQuestion(question, draft)

    if (showQuestion) {
      forFamilySchema[question.codeName] = buildValidationForField(question)
    }
  })

  const forFamilyMemberSchema = {}
  const familyMemberQuestions = questions?.forFamilyMember || []
  const familyMembersList = get(draft, 'familyData.familyMembersList', [])

  familyMembersList.forEach((_member, index) => {
    const memberScheme = {}
    familyMemberQuestions.forEach(question => {
      if (shouldShowQuestion(question, draft, index)) {
        memberScheme[question.codeName] = buildValidationForField(question)
      }
    })
    forFamilyMemberSchema[index] = Yup.object().shape({
      ...memberScheme,
    })
  })

  const validationSchema = Yup.object().shape({
    forFamily: Yup.object().shape(forFamilySchema),
    forFamilyMember: Yup.object().shape(forFamilyMemberSchema),
  })
  return validationSchema
}

export function buildInitialValuesForForm(
  questions: {
    forFamily: CurrentSurvey['surveyEconomicQuestions']
    forFamilyMember: CurrentSurvey['surveyEconomicQuestions']
  },
  draft,
) {
  const forFamilyInitial = {}
  const familyQuestions = questions?.forFamily || []

  familyQuestions.forEach(question => {
    const draftQuestion =
      draft.economicSurveyDataList.find(e => e.key === question.codeName) || {}

    const hasOtherOption = question.options.find(o => o.otherOption)

    if (hasOtherOption) {
      forFamilyInitial[`custom${capitalize(question.codeName)}`] =
        Object.prototype.hasOwnProperty.call(draftQuestion, 'other') &&
        !!draftQuestion.other
          ? draftQuestion.other
          : ''
    }

    forFamilyInitial[question.codeName] =
      (Object.prototype.hasOwnProperty.call(draftQuestion, 'value') &&
      !!draftQuestion.value
        ? draftQuestion.value
        : draftQuestion.multipleValue) || ''

    delete forFamilyInitial[question.codeName].text
  })

  const forFamilyMemberInitial = {}
  const familyMemberQuestions = questions?.forFamilyMember || []
  const familyMembersList = get(draft, 'familyData.familyMembersList', [])
  familyMembersList.forEach((familyMember, index) => {
    const memberInitial = {}
    const socioEconomicAnswers = familyMember.socioEconomicAnswers || []
    familyMemberQuestions.forEach(question => {
      const draftQuestion =
        socioEconomicAnswers.find(e => e.key === question.codeName) || {}

      if (question.options.find(o => o.otherOption)) {
        memberInitial[`custom${capitalize(question.codeName)}`] =
          Object.prototype.hasOwnProperty.call(draftQuestion, 'other') &&
          !!draftQuestion.other
            ? draftQuestion.other
            : ''
      }

      memberInitial[question.codeName] =
        (Object.prototype.hasOwnProperty.call(draftQuestion, 'value') &&
        !!draftQuestion.value
          ? draftQuestion.value
          : draftQuestion.multipleValue) || ''
    })
    forFamilyMemberInitial[index] = memberInitial
  })

  return {
    forFamily: forFamilyInitial,
    forFamilyMember: forFamilyMemberInitial,
  }
}

export function deleteRelatedConditions(
  questions: CurrentSurvey['surveyEconomicQuestions'],
  target,
) {
  let numberOfDeletedConditions = 0
  const newQuestions = Array.from(questions).map(question => {
    const q = structuredClone(question)
    // Clean questions conditions
    let newConditions = Array.from(q.conditions || []).filter(
      c => c.codeName !== target.codeName,
    )

    // Clean options conditions
    const newOptions = Array.from(q.options || []).map(option => {
      const newOptionConditions = (option.conditions ?? []).filter(
        c => c.codeName !== target.codeName,
      )
      numberOfDeletedConditions =
        numberOfDeletedConditions +
        (option.conditions ?? []).length -
        newConditions.length
      option.conditions = newOptionConditions
      return option
    })

    q.options = newOptions

    // Clean age conditions
    if (q.codeName === target.codeName) {
      newConditions = Array.from(q.conditions || []).filter(
        c => c.type !== 'family',
      )
    }
    numberOfDeletedConditions =
      numberOfDeletedConditions +
      (q.conditions || []).length -
      newConditions.length
    q.conditions = newConditions
    return q
  })

  return { newQuestions, numberOfDeletedConditions }
}

type SurveyFlowScreen =
  | 'FamilyParticipant'
  | 'FamilyMembersNames'
  | 'Location'
  | 'SocioEconomicQuestion'
  | 'BeginLifemap'
  | 'Help'
  | 'Question'
  | 'Overview'
  | 'Picture'
  | 'Priorities'
  | 'SignIn'
  | 'Final'
  | 'Exit'

export function screenTimer(draft: CurrentDraft, screen: SurveyFlowScreen) {
  let screenTimes = {}
  switch (screen) {
    case 'FamilyParticipant':
      screenTimes = {
        economicTime:
          draft.economicTime.length === 0 &&
          draft.stoplightTime.length === 0 &&
          draft.lifemapTime.length === 0
            ? [...draft.economicTime, { event: 'start', time: Date.now() }]
            : draft.economicTime[draft.economicTime.length - 1].event ===
                  'end' &&
                draft.stoplightTime.length === 0 &&
                draft.lifemapTime.length === 0
              ? [...draft.economicTime, { event: 'start', time: Date.now() }]
              : draft.economicTime,
        stoplightTime:
          draft.stoplightTime.length > 0 &&
          draft.stoplightTime[draft.stoplightTime.length - 1].event === 'end' &&
          draft.lifemapTime.length === 0
            ? [...draft.stoplightTime, { event: 'start', time: Date.now() }]
            : draft.stoplightTime,
        lifemapTime:
          draft.lifemapTime.length > 0 &&
          draft.lifemapTime[draft.lifemapTime.length - 1].event === 'end'
            ? [...draft.lifemapTime, { event: 'start', time: Date.now() }]
            : draft.lifemapTime,
      }
      break
    case 'FamilyMembersNames':
    case 'Location':
    case 'SocioEconomicQuestion':
      screenTimes = {
        ...draft,
        economicTime:
          draft.economicTime.length > 0 &&
          draft.economicTime[draft.economicTime.length - 1].event === 'end' &&
          draft.stoplightTime.length === 0 &&
          draft.lifemapTime.length === 0
            ? [...draft.economicTime, { event: 'start', time: Date.now() }]
            : draft.economicTime,
        stoplightTime:
          draft.stoplightTime.length > 0 &&
          draft.stoplightTime[draft.stoplightTime.length - 1].event === 'end' &&
          draft.lifemapTime.length === 0
            ? [...draft.stoplightTime, { event: 'start', time: Date.now() }]
            : draft.stoplightTime,
        lifemapTime:
          draft.lifemapTime.length > 0 &&
          draft.lifemapTime[draft.lifemapTime.length - 1].event === 'end'
            ? [...draft.lifemapTime, { event: 'start', time: Date.now() }]
            : draft.lifemapTime,
      }
      break

    case 'BeginLifemap':
    case 'Help':
      screenTimes = {
        economicTime:
          draft.economicTime.length > 0 &&
          draft.economicTime[draft.economicTime.length - 1].event === 'start' &&
          draft.stoplightTime.length === 0 &&
          draft.lifemapTime.length === 0
            ? [...draft.economicTime, { event: 'end', time: Date.now() }]
            : draft.economicTime,
        stoplightTime:
          draft.stoplightTime.length > 0 &&
          draft.stoplightTime[draft.stoplightTime.length - 1].event === 'end' &&
          draft.lifemapTime.length === 0
            ? [...draft.stoplightTime, { event: 'start', time: Date.now() }]
            : draft.stoplightTime,
        lifemapTime:
          draft.lifemapTime.length > 0 &&
          draft.lifemapTime[draft.lifemapTime.length - 1].event === 'end'
            ? [...draft.lifemapTime, { event: 'start', time: Date.now() }]
            : draft.lifemapTime,
      }
      break
    case 'Question':
      screenTimes = {
        stoplightTime:
          draft.stoplightTime.length === 0 && draft.lifemapTime.length === 0
            ? [...draft.stoplightTime, { event: 'start', time: Date.now() }]
            : draft.stoplightTime[draft.stoplightTime.length - 1]?.event ===
                  'end' && draft.lifemapTime.length === 0
              ? [...draft.stoplightTime, { event: 'start', time: Date.now() }]
              : draft.stoplightTime,
        lifemapTime:
          draft.lifemapTime.length > 0 &&
          draft.lifemapTime[draft.lifemapTime.length - 1].event === 'end' &&
          draft.stoplightTime.length !== 0
            ? [...draft.lifemapTime, { event: 'start', time: Date.now() }]
            : draft.lifemapTime,
      }

      break
    case 'Overview':
      screenTimes = {
        stoplightTime:
          draft.stoplightTime.length > 0 &&
          draft.stoplightTime[draft.stoplightTime.length - 1].event ===
            'start' &&
          draft.lifemapTime.length === 0
            ? [...draft.stoplightTime, { event: 'end', time: Date.now() }]
            : draft.stoplightTime,
        lifemapTime:
          draft.lifemapTime.length === 0
            ? [...draft.lifemapTime, { event: 'start', time: Date.now() }]
            : draft.lifemapTime[draft.lifemapTime.length - 1].event === 'end' &&
                draft.stoplightTime.length > 0
              ? [...draft.lifemapTime, { event: 'start', time: Date.now() }]
              : draft.lifemapTime,
      }
      break

    case 'Picture':
      screenTimes = {
        lifemapTime:
          draft.lifemapTime.length > 0 &&
          draft.lifemapTime[draft.lifemapTime.length - 1].event === 'end'
            ? [...draft.lifemapTime, { event: 'start', time: Date.now() }]
            : draft.stoplightTime.length === 0
              ? [...draft.lifemapTime, { event: 'start', time: Date.now() }]
              : draft.lifemapTime,
      }

      break

    case 'Priorities':
      screenTimes = {
        lifemapTime:
          draft.lifemapTime.length > 0 &&
          draft.lifemapTime[draft.lifemapTime.length - 1].event === 'end'
            ? [...draft.lifemapTime, { event: 'start', time: Date.now() }]
            : draft.lifemapTime,
      }
      break

    case 'SignIn':
    case 'Final':
      screenTimes = {
        lifemapTime:
          draft.lifemapTime.length > 0 &&
          draft.lifemapTime[draft.lifemapTime.length - 1].event === 'start'
            ? [...draft.lifemapTime, { event: 'end', time: Date.now() }]
            : draft.lifemapTime,
      }
      break
    case 'Exit':
      screenTimes = {
        economicTime:
          draft.economicTime.length > 0 &&
          draft.economicTime[draft.economicTime.length - 1].event === 'start' &&
          draft.stoplightTime.length === 0 &&
          draft.lifemapTime.length === 0
            ? [...draft.economicTime, { event: 'end', time: Date.now() }]
            : draft.economicTime,
        stoplightTime:
          draft.stoplightTime.length > 0 &&
          draft.stoplightTime[draft.stoplightTime.length - 1].event ===
            'start' &&
          draft.lifemapTime.length === 0
            ? [...draft.stoplightTime, { event: 'end', time: Date.now() }]
            : draft.stoplightTime,

        lifemapTime:
          draft.lifemapTime.length > 0 &&
          draft.lifemapTime[draft.lifemapTime.length - 1].event === 'start'
            ? [...draft.lifemapTime, { event: 'end', time: Date.now() }]
            : draft.lifemapTime,
      }
      break
    default:
      screenTimes = {}
  }
  return screenTimes
}

/**
 * Converts an array to a number
 * TODO: I think we should refactor this to just pass the array of time
 * and just have one if check.
 */
export function convertTimers(draft: CurrentDraft) {
  const { economicTime, stoplightTime, lifemapTime } = draft
  let sumEconomicTime = 0
  let sumStoplightTime = 0
  let sumLifemapTime = 0

  if (Array.isArray(economicTime)) {
    economicTime.forEach((event, idx) => {
      const isEventStart = event?.event === 'start'
      const isNextEventEnd = economicTime[idx + 1]?.event === 'end'

      if (isEventStart && isNextEventEnd) {
        sumEconomicTime += economicTime[idx + 1].time - event.time
      }
    })
  }

  if (Array.isArray(stoplightTime)) {
    stoplightTime.forEach((event, idx) => {
      const isEventStart = event?.event === 'start'
      const isNextEventEnd = stoplightTime[idx + 1]?.event === 'end'

      if (isEventStart && isNextEventEnd) {
        sumStoplightTime += stoplightTime[idx + 1].time - event.time
      }
    })
  }

  if (Array.isArray(lifemapTime)) {
    lifemapTime.forEach((event, idx) => {
      const isEventStart = event?.event === 'start'
      const isNextEventEnd = lifemapTime[idx + 1]?.event === 'end'

      if (isEventStart && isNextEventEnd) {
        sumLifemapTime += lifemapTime[idx + 1].time - event.time
      }
    })
  }

  return {
    economicTime: sumEconomicTime || 0,
    stoplightTime: sumStoplightTime || 0,
    lifemapTime: sumLifemapTime || 0,
  }
}

export function remakeTimers(draft: CurrentDraft) {
  let { economicTime, stoplightTime, lifemapTime } = draft
  economicTime = economicTime
    ? [
        // @ts-expect-error - We should check if economicTime is a number or an object
        { event: 'start', time: Date.now() - economicTime },
        { event: 'end', time: Date.now() },
      ]
    : []
  stoplightTime = stoplightTime
    ? [
        // @ts-expect-error - We should check if economicTime is a number or an object
        { event: 'start', time: Date.now() - stoplightTime },
        { event: 'end', time: Date.now() },
      ]
    : []
  lifemapTime = lifemapTime
    ? [
        // @ts-expect-error - We should check if economicTime is a number or an object
        { event: 'start', time: Date.now() - lifemapTime },
        { event: 'end', time: Date.now() },
      ]
    : []
  return {
    economicTime,
    stoplightTime,
    lifemapTime,
  }
}

export function isTimerNum(draft: CurrentDraft) {
  return (
    typeof draft.economicTime === 'number' &&
    typeof draft.stoplightTime === 'number' &&
    typeof draft.lifemapTime === 'number'
  )
}

export function calculateTotalTime(draft: CurrentDraft) {
  let sumEconomicTime = 0
  let sumStoplightTime = 0
  let sumLifemapTime = 0

  const { economicTime = [], stoplightTime = [], lifemapTime = [] } = draft

  economicTime.forEach((e, index) => {
    if (
      !!e &&
      e.event === 'start' &&
      !!economicTime[index + 1] &&
      economicTime[index + 1].event === 'end'
    ) {
      sumEconomicTime += economicTime[index + 1].time - e.time
    }
  })

  stoplightTime.forEach((e, index) => {
    if (
      !!e &&
      e.event === 'start' &&
      !!stoplightTime[index + 1] &&
      stoplightTime[index + 1].event === 'end'
    ) {
      sumStoplightTime += stoplightTime[index + 1].time - e.time
    }
  })

  lifemapTime.forEach((e, index) => {
    if (
      !!e &&
      e.event === 'start' &&
      !!lifemapTime[index + 1] &&
      lifemapTime[index + 1].event === 'end'
    ) {
      sumLifemapTime += lifemapTime[index + 1].time - e.time
    }
  })

  return {
    economicTime: sumEconomicTime || 0,
    stoplightTime: sumStoplightTime || 0,
    lifemapTime: sumLifemapTime || 0,
  }
}

type ScreenToValidate =
  | 'PRIMARY_PARTICIPANT'
  | 'FAMILY_MEMBERS'
  | 'LOCATION'
  | 'ECONOMIC'
  | 'STOPLIGHT'
  | 'FINAL'
  | 'LOCATION'
  | 'OVERVIEW'

export const screenValidation = (
  draft: CurrentDraft | null,
  screen: ScreenToValidate,
  survey: CurrentSurvey,
) => {
  if (!draft) return '/lifemap/terms'

  const isAnonymous = survey.surveyConfig.isSurveyAnonymous

  const membersList = draft.familyData.familyMembersList
  const primaryParticipant = membersList.find(m => m.firstParticipant) ?? null

  //* NOTE: Primary Participant validation
  const isPrimaryParticipantValid =
    primaryParticipant &&
    !!primaryParticipant.firstName &&
    !!primaryParticipant.lastName &&
    !!primaryParticipant.birthCountry &&
    !!primaryParticipant.gender &&
    !!primaryParticipant.birthDate &&
    !!primaryParticipant.documentType

  const areMembersValid = membersList.every(member => !!member.firstName)
  const isMapValid = !!draft.familyData.latitude && !!draft.familyData.longitude

  const requiredQuestions =
    survey.surveyEconomicQuestions?.filter(economicQuestion => {
      const isRequired = economicQuestion.required
      const isForMember = economicQuestion.forFamilyMember
      const hasConditions = economicQuestion.conditions.length > 0
      const hasConditionGroups = economicQuestion.conditionGroups.length > 0

      return isRequired && !isForMember && !hasConditions && !hasConditionGroups
    }) ?? []

  const requiredMemberQuestions =
    survey.surveyEconomicQuestions?.filter(economicQuestion => {
      const isRequired = economicQuestion.required
      const isForMember = economicQuestion.forFamilyMember
      const hasConditions = economicQuestion.conditions.length > 0
      const hasConditionGroups = economicQuestion.conditionGroups.length > 0

      return isRequired && isForMember && !hasConditions && !hasConditionGroups
    }) ?? []

  const hasRequiredAnswers =
    draft.economicSurveyDataList.length >= requiredQuestions.length

  const hasRequiredMemberAnswers = membersList.every(member => {
    const totalAnswers = member.socioEconomicAnswers?.length ?? 0
    return totalAnswers >= requiredMemberQuestions.length
  })

  const isStoplightOptional = survey.surveyConfig.stoplightOptional ?? false
  const didAnswerStoplights =
    draft.indicatorSurveyDataList.length >=
    survey.surveyStoplightQuestions.length

  const isPrimaryValid = draft.agreedTerms
  const isMembersValid = !!isPrimaryParticipantValid && isPrimaryValid
  const isLocationValid = (areMembersValid && isMembersValid) || isAnonymous
  const isEconomicValid = isMapValid && isLocationValid

  const isStoplightValid =
    (hasRequiredAnswers && hasRequiredMemberAnswers && isEconomicValid) ||
    draft.justStoplight

  const isFinalValid =
    isStoplightValid && (didAnswerStoplights || isStoplightOptional)

  type ConditionByScreen = Record<
    ScreenToValidate,
    { isValid: boolean; fallbackRoute: string }
  >
  const conditionsByScreen: ConditionByScreen = {
    PRIMARY_PARTICIPANT: {
      isValid: isPrimaryValid,
      fallbackRoute: '/lifemap/primary-participant',
    },
    FAMILY_MEMBERS: {
      isValid: isMembersValid,
      fallbackRoute: '/lifemap/primary-participant',
    },
    LOCATION: {
      isValid: isLocationValid,
      fallbackRoute: '/lifemap/family-members',
    },
    ECONOMIC: {
      isValid: isEconomicValid,
      fallbackRoute: '/lifemap/location',
    },
    STOPLIGHT: {
      isValid: isStoplightValid,
      fallbackRoute: '/lifemap/economics/0',
    },
    OVERVIEW: {
      isValid: isFinalValid,
      fallbackRoute: '/lifemap/stoplight/0',
    },
    FINAL: {
      isValid: isFinalValid,
      fallbackRoute: '/lifemap/stoplight/0',
    },
  }

  if (conditionsByScreen[screen].isValid) return null
  else return conditionsByScreen[screen].fallbackRoute
}

function formatPhone(
  code: string,
  phone: string | null,
  surveyLocation: CurrentSurvey['surveyConfig']['surveyLocation'],
) {
  let phoneCode = code as (typeof callingCodes)[number]['value'] | null

  const phoneLength = phone?.length ?? 0
  if (phoneLength <= 0) return null

  let internationalPhoneNumber = ''
  const phoneUtil = GoogleLibPhoneNumber.PhoneNumberUtil.getInstance()

  if (code) {
    internationalPhoneNumber = `+${code} ${phone}`
  } else {
    phoneCode =
      callingCodes.find(callingCode => {
        return callingCode.code === surveyLocation.country
      })?.value ?? null

    internationalPhoneNumber = `+${phoneCode} ${phone}`
  }
  const phoneNumber = phoneUtil.parse(internationalPhoneNumber, code)
  phone = phoneNumber.getNationalNumber()?.toString() ?? null

  return phone
}

function validEconomicIndicator(
  economicSurveyData: CurrentDraft['economicSurveyDataList'][number],
) {
  const hasEconomicValue = !!economicSurveyData.value
  const hasMultipleValues = (economicSurveyData.multipleValue?.length ?? 0) > 0

  return hasEconomicValue || hasMultipleValues
}

export function sanitizeDraft(
  snapshot: CurrentDraft,
  surveyLocation: CurrentSurvey['surveyConfig']['surveyLocation'],
) {
  let { economicSurveyDataList } = snapshot
  const sanitizedSnapshot: Partial<CurrentDraft> = structuredClone(snapshot)

  delete sanitizedSnapshot.lifemapNavHistory
  delete sanitizedSnapshot.previousIndicatorSurveyDataList
  delete sanitizedSnapshot.previousIndicatorPriorities
  delete sanitizedSnapshot.previousIndicatorAchivements
  delete sanitizedSnapshot.isRetake
  delete sanitizedSnapshot.snapshotId
  delete sanitizedSnapshot.justStoplight
  delete sanitizedSnapshot.justStoplightRetake
  delete sanitizedSnapshot.agreedTerms

  economicSurveyDataList = economicSurveyDataList.filter(validEconomicIndicator)
  sanitizedSnapshot.economicSurveyDataList = economicSurveyDataList

  sanitizedSnapshot.familyData?.familyMembersList.forEach(member => {
    let { socioEconomicAnswers = [] } = member

    member.phoneNumber = formatPhone(
      member.phoneCode,
      member.phoneNumber,
      surveyLocation,
    )
    socioEconomicAnswers =
      socioEconomicAnswers?.filter(validEconomicIndicator) ?? []
    // eslint-disable-next-line no-param-reassign
    member.socioEconomicAnswers = socioEconomicAnswers
    // @ts-expect-error - Reason: countFamilyMembers does not exist in our types
    delete member.countFamilyMembers
  })

  return sanitizedSnapshot
}
