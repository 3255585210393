import i18n from '~/i18n'
import { type PlatformLanguage } from '~/utils/types/i18n'

type DATE_MASKS_TYPES = { [key in PlatformLanguage]: string }
const DATE_MASK_BY_LANG: DATE_MASKS_TYPES = {
  en: 'MM/DD/YYYY',
  es: 'DD/MM/YYYY',
  pt: 'DD/MM/YYYY',
  'pt-BR': 'DD/MM/YYYY',
  ht: 'DD/MM/YYYY',
  sk: 'DD/MM/YYYY',
  bg: 'DD/MM/YYYY',
  ro: 'DD/MM/YYYY',
  ar: 'DD/MM/YYYY',
} as const

/**
 * @param options This is an options object that comes from the Intl class.
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format#examples
 *
 * @example
 * formatDate(new Date('01/17/17'), { dateStyle: 'full' })
 */
export function formatDate(date: Date, options: Intl.DateTimeFormatOptions) {
  const language = i18n.language === 'ht' ? 'fr' : i18n.language

  return Intl.DateTimeFormat(language, options).format(
    serverDateToLocalDate(date),
  )
}
/**
 * A function to synchronize the server's time to the client's time (The server is in offset UTC+00:00)
 */
export function serverDateToLocalDate(serverDate: Date) {
  return serverDate.setHours(
    serverDate.getHours(),
    serverDate.getMinutes() - serverDate.getTimezoneOffset(),
  )
}

export function getDateMaskFormat() {
  return DATE_MASK_BY_LANG[i18n.language as PlatformLanguage]
}
