import { type ReactNode } from 'react'

import { Box } from '@mui/material'
import { useTheme } from '@mui/styles'

import Footer from '~/Footer'
import Header from '~/Header'

export default function MainLayout({ children }: { children: ReactNode }) {
  const { spacing } = useTheme()
  const navbarHeight = spacing(8.5)

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Header />
      <Box sx={{ flexGrow: 1, marginTop: navbarHeight }}>{children}</Box>
      <Footer />
    </Box>
  )
}
