import { createQueryKeys } from '@lukemorales/query-key-factory'

import store from '~/redux'
import { getSolutionsAccessTypes } from '~/services/solutions'
import { type PlatformLanguage } from '~/utils/types/i18n'

const userId = store.getState().user?.id

export const solutionsQueries = createQueryKeys('solutions', {
  accessTypes: (language: PlatformLanguage) => ({
    queryKey: [language, { userId }],
    queryFn: getSolutionsAccessTypes,
  }),
})
