import { Check, Clear } from '@mui/icons-material'
import { LinearProgress, Stack } from '@mui/material'
import { z } from 'zod'

const NEW_PASS_LENGTH = 'newPasswordLength'
const NEW_PASS_NUMBER = 'newPasswordNumber'
const NEW_PASS_SYMBOL = 'newPasswordSymbol'
const NEW_PASS_UPPERCASE = 'newPasswordUppercase'
const NEW_PASS_LOWERCASE = 'newPasswordLowercase'

const strongPasswordSchema = z
  .string()
  .trim()
  .superRefine((val, ctx) => {
    //* NOTE: Length
    if (val.length < 8) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: NEW_PASS_LENGTH,
      })
    }

    //* NOTE: Not uppercase
    if (val.match(/[A-Z]/g) === null) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: NEW_PASS_UPPERCASE,
      })
    }

    //* NOTE: Not lowercase
    if (val.match(/[a-z]/g) === null) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: NEW_PASS_LOWERCASE,
      })
    }

    //* NOTE: Not a number
    if (val.match(/[0-9]/g) === null) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: NEW_PASS_NUMBER,
      })
    }

    //* NOTE: Not a symbol
    if (val.match(/[^A-Za-z0-9\s]/g) === null) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: NEW_PASS_SYMBOL,
      })
    }
  })

export interface StrongPasswordCriteriasValidation {
  hasSymbols: boolean
  hasNumbers: boolean
  hasLowercase: boolean
  hasUppercase: boolean
  hasMinLength: boolean
}

interface StrongPasswordCriteriasProps {
  password: string
  setIsPasswordValid: (value: boolean) => void
}

export default function StrongPasswordCriterias({
  password,
  setIsPasswordValid,
}: StrongPasswordCriteriasProps) {
  const { t } = useTranslation()

  useEffect(() => {
    if (!password) return

    const result = strongPasswordSchema.safeParse(password)
    setIsPasswordValid(result.success)
  }, [password, setIsPasswordValid])

  const strongPasswordErrors: StrongPasswordCriteriasValidation =
    useMemo(() => {
      const result = strongPasswordSchema.safeParse(password)
      if (result.success) {
        return {
          hasNumbers: true,
          hasSymbols: true,
          hasLowercase: true,
          hasUppercase: true,
          hasMinLength: true,
        }
      }

      return {
        hasNumbers: !result.error.issues.some(
          issue => issue.message === NEW_PASS_NUMBER,
        ),
        hasSymbols: !result.error.issues.some(
          issue => issue.message === NEW_PASS_SYMBOL,
        ),
        hasMinLength: !result.error.issues.some(
          issue => issue.message === NEW_PASS_LENGTH,
        ),
        hasLowercase: !result.error.issues.some(
          issue => issue.message === NEW_PASS_LOWERCASE,
        ),
        hasUppercase: !result.error.issues.some(
          issue => issue.message === NEW_PASS_UPPERCASE,
        ),
      }
    }, [password])

  const conditionsMetCount = Object.values(strongPasswordErrors).filter(
    condition => condition,
  ).length
  const conditionsMetPercentage = (conditionsMetCount / 5) * 100

  return (
    <Stack>
      <LinearProgress
        color="primary"
        variant="determinate"
        value={conditionsMetPercentage}
        sx={{ p: 0.5, borderRadius: 2, my: 0.5 }}
      />

      <PasswordCriteriaStatusTitle
        text={t('validation.atLeastOneNumber')}
        isCorrect={strongPasswordErrors.hasNumbers}
      />

      <PasswordCriteriaStatusTitle
        text={t('validation.atLeastCharacters', { length: 8 })}
        isCorrect={strongPasswordErrors.hasMinLength}
      />

      <PasswordCriteriaStatusTitle
        text={t('validation.atLeastSymbol')}
        isCorrect={strongPasswordErrors.hasSymbols}
      />

      <PasswordCriteriaStatusTitle
        text={t('validation.smallLetter')}
        isCorrect={strongPasswordErrors.hasLowercase}
      />

      <PasswordCriteriaStatusTitle
        text={t('validation.capitalLetter')}
        isCorrect={strongPasswordErrors.hasUppercase}
      />
    </Stack>
  )
}

interface PasswordCriteriaStatusTitleProps {
  isCorrect: boolean
  text: string
}

function PasswordCriteriaStatusTitle({
  isCorrect,
  text,
}: PasswordCriteriaStatusTitleProps) {
  return (
    <Stack
      gap={0.5}
      direction="row"
      alignItems="center"
      sx={{
        fontSize: 14,
        transition: 'all 750ms ease',
        opacity: isCorrect ? 0.6 : 1,
        color: isCorrect ? 'green' : 'red',
      }}
    >
      {isCorrect ? (
        <Check sx={{ fontSize: 14 }} />
      ) : (
        <Clear sx={{ fontSize: 14 }} />
      )}
      {text}
    </Stack>
  )
}
