import { Close, QuestionMark, Warning } from '@mui/icons-material'
import { Divider, IconButton, Stack, Typography } from '@mui/material'
import {
  type CustomContentProps,
  SnackbarContent,
  useSnackbar,
} from 'notistack'
import { useSearchParams } from 'react-router-dom'

//* NOTE: This file was created following notistack's docs:
//* https://notistack.com/features/customization#custom-component

const PasswordWarningSnackbar = forwardRef<HTMLDivElement, CustomContentProps>(
  (props, ref) => {
    const {
      //* NOTE: Notistack Props
      id,
      message,
      ...defaultSnackbarProps
    } = props

    const { closeSnackbar } = useSnackbar()
    const [, setSearchParams] = useSearchParams()

    function handleOpenModal() {
      setSearchParams(params => {
        params.set('passwordModal', 'open')
        return params
      })
    }

    return (
      <>
        <SnackbarContent ref={ref} role="alert" {...defaultSnackbarProps}>
          <Stack
            direction="row"
            gap={2}
            alignItems="center"
            sx={{
              py: 1,
              px: 2,
              borderRadius: 2,
              color: theme => theme.palette.primary.contrastText,
              bgcolor: theme => theme.palette.warning.light,
            }}
          >
            <Warning />

            <Typography>{message}</Typography>

            <Divider
              flexItem
              orientation="vertical"
              sx={{ bgcolor: theme => theme.palette.divider }}
            />

            <Stack direction="row" gap={1}>
              <IconButton
                sx={{ color: theme => theme.palette.primary.contrastText }}
                onClick={() => {
                  handleOpenModal()
                }}
              >
                <QuestionMark />
              </IconButton>

              <IconButton
                sx={{ color: theme => theme.palette.primary.contrastText }}
                onClick={() => {
                  closeSnackbar(id)
                }}
              >
                <Close />
              </IconButton>
            </Stack>
          </Stack>
        </SnackbarContent>
      </>
    )
  },
)

export default PasswordWarningSnackbar

//* NOTE: Module augmentation to include this custom variant
declare module 'notistack' {
  interface VariantOverrides {
    passwordWarning: true
  }
}
