// React
import { Close, ExpandMore, LockReset, Warning } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Divider,
  Fade,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  Stack,
  Typography,
} from '@mui/material'
import { useSearchParams } from 'react-router-dom'

import backgroundImage from '~/assets/ballstoit.png'
import ChangePasswordModal from '~/components/modals/ChangePasswordModal'
import { useAppSelector } from '~/redux/hooks'

export default function PasswordWarningModal() {
  const [isChangePasswordModalOpened, setIsChangePasswordModalOpened] =
    useState(false)

  const { t } = useTranslation()
  const user = useAppSelector(state => state.user)

  const [searchParams, setSearchParams] = useSearchParams()
  const isOpened =
    searchParams.get('passwordModal') === 'open' && Boolean(user?.willExpire)

  const daysLeftToExpireMessage =
    user?.daysToExpirePassword === 1
      ? t('views.login.passwordWillExpire_one', {
          count: user?.daysToExpirePassword,
        })
      : t('views.login.passwordWillExpire_other', {
          count: user?.daysToExpirePassword,
        })

  function handleCloseModal() {
    setSearchParams(params => {
      params.delete('passwordModal')
      return params
    })
  }

  return (
    <>
      <ChangePasswordModal
        isOpened={isChangePasswordModalOpened}
        setIsOpened={setIsChangePasswordModalOpened}
      />

      <Modal
        open={isOpened}
        onClose={() => {
          handleCloseModal()
        }}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Fade in={isOpened} timeout={250}>
          <Card
            sx={{
              p: 2,
              outline: 'none',
              borderRadius: 2,
              position: 'relative',
              bgcolor: theme => theme.palette.background.default,
              width: theme => ({
                xs: '100vw',
                sm: theme.breakpoints.values.sm,
              }),
            }}
          >
            <CardMedia
              component="img"
              image={backgroundImage}
              sx={{
                inset: 0,
                zIndex: 1,
                opacity: 0.25,
                position: 'absolute',
                objectFit: 'scale-down',
              }}
            />

            <CardHeader
              sx={{ position: 'relative', zIndex: 10 }}
              title={t('views.passwordWarningModal.title')}
              titleTypographyProps={{
                align: 'center',
                color: theme => theme.palette.grey[600],
                fontSize: theme => theme.typography.h4.fontSize,
              }}
              action={
                <IconButton
                  color="primary"
                  onClick={() => {
                    handleCloseModal()
                  }}
                >
                  <Close />
                </IconButton>
              }
            />

            <CardContent sx={{ position: 'relative', zIndex: 10 }}>
              <Stack gap={2}>
                <Divider>
                  <Typography variant="h5" textAlign="center">
                    {user?.username}
                  </Typography>
                </Divider>

                <Alert severity="warning">
                  <Typography>
                    {daysLeftToExpireMessage}.{' '}
                    {t('views.passwordWarningModal.advice')}
                  </Typography>
                </Alert>

                <List
                  sx={{ bgcolor: theme => theme.palette.background.default }}
                >
                  <Typography>
                    {t('views.passwordWarningModal.passwordExpiredInfo')}:
                  </Typography>
                  <CustomListItem
                    text={t('views.passwordWarningModal.logoutInfo')}
                  />
                  <CustomListItem
                    text={t('views.passwordWarningModal.resetInfo')}
                  />
                  <CustomListItem
                    hideDivider
                    text={t('views.passwordWarningModal.lostAccessInfo')}
                  />
                </List>

                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography variant="subtitle1">
                      {t('views.passwordWarningModal.passwordChangeQuestion')}
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Typography>
                      {t('views.passwordWarningModal.passwordChangeAnswer')}
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <ButtonGroup sx={{ mx: 'auto' }}>
                  <Button
                    onClick={() => {
                      setIsChangePasswordModalOpened(true)
                    }}
                    startIcon={<LockReset />}
                  >
                    {t('views.toolbar.changePassword')}
                  </Button>
                  {/* NOTE: This button will be implemented once there's an article explaining password expiration */}
                  {/* <Button startIcon={<Lightbulb />}>Learn More</Button> */}
                </ButtonGroup>
              </Stack>
            </CardContent>
          </Card>
        </Fade>
      </Modal>
    </>
  )
}

interface CustomListItemProps {
  text: string
  hideDivider?: boolean
}

function CustomListItem({ text, hideDivider }: CustomListItemProps) {
  return (
    <>
      <ListItem>
        <ListItemIcon>
          <Warning />
        </ListItemIcon>
        <ListItemText>{text}</ListItemText>
      </ListItem>

      {!hideDivider && <Divider />}
    </>
  )
}
