import {
  Dehaze as DehazeIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Drawer,
  IconButton,
  MenuItem,
  MenuList,
  Typography,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { type MenuTab } from '~/Header'
import { useAppSelector } from '~/redux/hooks'

import { canAccess } from '../../utils/role-utils'

interface HamburgerMenuProps {
  tabs: MenuTab[]
}

export default function HamburgerMenu({ tabs }: HamburgerMenuProps) {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const [isDrawerOpened, setIsDrawerOpened] = useState(false)

  const user = useAppSelector(state => {
    if (!state.user) throw new Error('No user')
    return state.user
  })
  const specialOrgPath = `/hubs/${user.hub?.id}/organizations`
  const specialProyectPath = `/hubs/${user.hub?.id}/organizations/${user.organization?.id}/projects`

  const drawerOptions = useMemo(() => {
    let menuOptions: MenuTab[] = []

    canAccess(user, 'dashboard') && menuOptions.push({ item: 'dashboard' })
    menuOptions = [...menuOptions, ...tabs, { item: 'support' }]

    return menuOptions
  }, [tabs, user])

  return (
    <>
      <IconButton
        sx={{ alignSelf: 'center' }}
        onClick={() => {
          setIsDrawerOpened(prev => !prev)
        }}
      >
        <DehazeIcon sx={{ color: theme => theme.palette.primary.main }} />
      </IconButton>
      <Drawer
        open={isDrawerOpened}
        onClose={() => {
          setIsDrawerOpened(false)
        }}
        PaperProps={{
          sx: {
            borderTopRightRadius: 25,
            py: 2,
          },
        }}
      >
        <MenuList>
          {drawerOptions.map(({ item, options }) => {
            const isDropdown = Array.isArray(options)

            const label = t(`views.toolbar.${item}`)
            let path = `/${item}`
            if (item === 'projects') path = specialProyectPath
            if (item === 'organizations') path = specialOrgPath

            return isDropdown ? (
              <Accordion key={item} elevation={0}>
                <AccordionSummary
                  disableRipple={false}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography variant="subtitle1">{label}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {options.map(option => (
                    <MenuItem
                      key={option}
                      onClick={() => {
                        navigate(`/${option}`)
                      }}
                    >
                      <Typography variant="subtitle1">
                        {t(`views.toolbar.${option}`)}
                      </Typography>
                    </MenuItem>
                  ))}
                </AccordionDetails>
              </Accordion>
            ) : (
              <MenuItem
                key={path}
                onClick={() => {
                  navigate(path)
                }}
              >
                <Typography variant="subtitle1">{label}</Typography>
              </MenuItem>
            )
          })}
        </MenuList>
      </Drawer>
    </>
  )
}
