import { Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import { Spring } from 'react-spring/renderprops'
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'

import { COLORS } from '~/theme'

import CustomTooltip from '../CustomTooltip'
import IndicatorBall from './IndicatorBall'

let SummaryCountingSection = props => (
  <div className={props.classes.container}>
    <Spring
      config={{ tension: 150, friction: 40, precision: 1 }}
      from={{ number: props.isAnimationActive ? 0 : props.count }}
      to={{ number: props.count }}
    >
      {p => (
        <Typography variant="h5" className={props.classes.typographyStyle}>
          {Number.parseInt(p.number, 10)}
        </Typography>
      )}
    </Spring>
    <div className={props.classes.itemSeparator} />
    <IndicatorBall color={props.color} variant="tiny" animated={false} />
    <div className={props.classes.itemSeparator} />
    <Typography variant="subtitle1" noWrap>
      {props.label}
    </Typography>
  </div>
)
const summaryCountingSectionStyles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  itemSeparator: {
    width: theme.spacing(2),
  },
  typographyStyle: {
    width: '2em',
    display: 'flex',
    justifyContent: 'flex-end',
  },
})

SummaryCountingSection = withStyles(summaryCountingSectionStyles)(
  SummaryCountingSection,
)

const SummaryDonut = props => {
  const {
    classes,
    greenIndicatorCount,
    yellowIndicatorCount,
    redIndicatorCount,
    skippedIndicatorCount,
    doesNotApplyIndicatorCount,
    countingSection = true,
    isAnimationActive = false,
    width,
    height,
  } = props
  const { t } = useTranslation()
  const data = [
    { name: 'red', value: redIndicatorCount },
    { name: 'yellow', value: yellowIndicatorCount },
    { name: 'green', value: greenIndicatorCount },
    { name: 'skipped', value: skippedIndicatorCount },
    { name: 'doesNotApply', value: doesNotApplyIndicatorCount },
  ]
  const total =
    redIndicatorCount +
    yellowIndicatorCount +
    greenIndicatorCount +
    skippedIndicatorCount +
    doesNotApplyIndicatorCount

  return (
    <div className={classes.mainContainer} style={{ width }}>
      <div className={classes.donutContainer}>
        <ResponsiveContainer width="100%" height={height || 240}>
          <PieChart width={200} height={200}>
            <Pie
              data={data}
              dataKey="value"
              nameKey="name"
              innerRadius="50%"
              outerRadius="100%"
              paddingAngle={0}
            >
              <Cell key="red" fill={COLORS.RED} stroke={COLORS.RED} />
              <Cell key="yellow" fill={COLORS.YELLOW} stroke={COLORS.YELLOW} />
              <Cell key="green" fill={COLORS.GREEN} stroke={COLORS.GREEN} />
              <Cell
                key="skipped"
                fill={COLORS.SKIPPED_GREY}
                stroke={COLORS.SKIPPED_GREY}
              />
              <Cell
                key="doesNotApply"
                fill={COLORS.DOES_NOT_APPLY_GREY}
                stroke={COLORS.DOES_NOT_APPLY_GREY}
              />
            </Pie>
            <Tooltip
              content={
                <CustomTooltip
                  format={({ value, name }) =>
                    `${Math.round((value * 100) / total)}% ${t(
                      `views.dashboard.${name}`,
                    )}`
                  }
                />
              }
            />
          </PieChart>
        </ResponsiveContainer>
      </div>
      {countingSection && (
        <div className={classes.summaryCountingSectionContainer}>
          <SummaryCountingSection
            label={t('views.dashboard.green')}
            count={greenIndicatorCount}
            color="green"
          />
          <SummaryCountingSection
            label={t('views.dashboard.yellow')}
            count={yellowIndicatorCount}
            color="yellow"
          />
          <SummaryCountingSection
            label={t('views.dashboard.red')}
            count={redIndicatorCount}
            color="red"
          />
          <SummaryCountingSection
            label={t('views.dashboard.skipped')}
            count={skippedIndicatorCount}
            color="skipped"
          />
          <SummaryCountingSection
            label={t('views.dashboard.doesNotApply')}
            count={doesNotApplyIndicatorCount}
            color="doesNotApply"
          />
        </div>
      )}
    </div>
  )
}

const styles = theme => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  donutContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  summaryCountingSectionContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
})

export default withStyles(styles)(SummaryDonut)
