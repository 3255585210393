import { LoopOutlined } from '@mui/icons-material'
import { Box, TextField, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useDebounceCallback } from 'usehooks-ts'

const useStyles = makeStyles(theme => ({
  filterInput: {
    paddingTop: '14.0px!important',
    paddingBottom: '12.0px!important',
    paddingRight: '14px!important',
    paddingLeft: '14px!important',
    fontFamily: 'Poppins',
    fontSize: '12px',
  },
  inputRootLabel: {
    zIndex: 0, // For some reason default zIndex is greater than 0 ¯\_(ツ)_/¯
    fontSize: 14,
    color: theme.palette.grey[500],
    fontWeight: theme.typography.fontWeightMedium,
  },
  textField: {
    backgroundColor: theme.palette.background.default,
    marginTop: '0px!important',
    marginBottom: '0px!important',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: 2,
        border: `1.5px solid ${theme.palette.grey[300]}`,
      },
      '&:hover fieldset': {
        borderColor: 'hsl(0, 0%, 70%)',
      },
      '&.Mui-focused fieldset': {
        border: `1.5px solid ${theme.palette.primary.dark}`,
      },
    },
  },
}))

interface SearchTextFilterProps {
  value: string
  searchLabel: string
  searchByLabel: string
  onChange: (newValue: string) => void
}

const SearchTextFilter = ({
  value,
  onChange,
  searchLabel,
  searchByLabel,
}: SearchTextFilterProps) => {
  const classes = useStyles()
  const [isTyping, setIsTyping] = useState(false)

  const handleOnChange = useCallback((value: string) => {
    onChange(value)
    setIsTyping(false)
  }, [])

  const debounced = useDebounceCallback(handleOnChange, 1000)

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Typography variant="subtitle1" sx={{ mr: 2, fontSize: 14 }}>
        {searchLabel}
      </Typography>
      <TextField
        fullWidth
        margin="dense"
        variant="outlined"
        defaultValue={value}
        label={searchByLabel}
        className={classes.textField}
        InputLabelProps={{ classes: { root: classes.inputRootLabel } }}
        onChange={e => {
          debounced(e.target.value)
          setIsTyping(true)
        }}
        InputProps={{
          endAdornment: isTyping ? <TypingIcon /> : null,
          classes: { input: classes.filterInput },
        }}
      />
    </Box>
  )
}

function TypingIcon() {
  return (
    <LoopOutlined
      sx={theme => ({
        fontSize: theme.typography.subtitle1.fontSize,
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        animation: 'rotation 1.5s ease-in-out infinite',

        '@keyframes rotation': {
          '0%': {
            transform: 'rotate(0deg)',
          },
          '100%': {
            transform: 'rotate(360deg)',
          },
        },
      })}
    />
  )
}

export default SearchTextFilter
