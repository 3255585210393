import { FormHelperText, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import Select from 'react-select'

import { useLocalizedCountries } from '../../utils/hooks/useLocalizedCountries'
import { getLanguageByCode } from '../../utils/lang-utils'
import { autoCompleteStyle, selectStyle } from '../../utils/styles-utils'

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 20,
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      marginBottom: 10,
    },
  },
  withTitleContainer: {
    margin: '5px 0',
  },
  horizontalContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    margin: 0,
  },
  label: {
    marginBottom: 5,
    fontSize: 14,
  },
  horizontalLabel: {
    marginRight: 5,
  },
  autoCompleteLabel: {
    marginTop: 16,
    marginBottom: 8,
    color: theme.palette.grey[500],
  },
  selector: {
    width: '100%',
  },
}))

const CountrySelector = ({
  withTitle,
  horizontal = false,
  withAutoCompleteStyle = false,
  countryData,
  onChangeCountry,
  onBlur,
  required,
  parentLang,
  isDisabled,
  error,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const classes = useStyles()
  const label = `${t('views.solutions.form.country')} ${required ? ' *' : ''}`

  const [loading, setLoading] = useState(false)

  const lang = parentLang || language
  const languag = getLanguageByCode(lang)
  const countries = useLocalizedCountries(languag)
  const countryOptions = countries
    .filter(country => country.code !== 'default')
    .sort((a, b) => a.label.localeCompare(b.label))
    .map(country => ({
      value: country.code,
      label: country.label,
    }))

  useEffect(() => {
    setLoading(true)
    // If we only receive the country code as prop
    if (countryData && !countryData.hasOwnProperty('value')) {
      if (!countryOptions.length) {
        return
      }
      const data = countryOptions.find(o => o.value === countryData)
      onChangeCountry(data)
    }
    setLoading(false)
  }, [language, parentLang])

  return (
    <div
      className={clsx(
        classes.container,
        withTitle && classes.withTitleContainer,
        horizontal && withTitle && classes.horizontalContainer,
      )}
    >
      {withTitle && (
        <Typography
          variant="subtitle1"
          className={clsx(
            classes.label,
            horizontal && classes.horizontalLabel,
            withAutoCompleteStyle && classes.autoCompleteLabel,
          )}
        >
          {label}
        </Typography>
      )}

      <div className={classes.selector}>
        <Select
          value={countryData}
          onChange={value => onChangeCountry(value)}
          onBlur={onBlur}
          placeholder={withTitle ? '' : label}
          isLoading={loading}
          loadingMessage={() => t('views.dimensionFilter.loading')}
          noOptionsMessage={() => t('views.dimensionFilter.noOption')}
          options={countryOptions}
          hideSelectedOptions
          styles={withAutoCompleteStyle ? autoCompleteStyle : selectStyle}
          closeMenuOnSelect
          isClearable
          isDisabled={isDisabled}
          maxMenuHeight={150}
        />
      </div>
      {error && (
        <FormHelperText error={error}>
          {t('validation.fieldIsRequired')}
        </FormHelperText>
      )}
    </div>
  )
}

export default CountrySelector
