import { Button, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { updateDraft } from '~/redux/slices/currentDraft'

import SkippedImg from '../../assets/skipped.png'
import BottomSpacer from '../../components/BottomSpacer'
import Container from '../../components/Container'
import TitleBar from '../../components/TitleBar'

const goToQuestion = (surveyStoplightQuestions, question, navigate) => {
  surveyStoplightQuestions.forEach((surveyQuestion, index) => {
    if (question.codeName === surveyQuestion.codeName) {
      navigate(`/lifemap/stoplight/${index}`, {
        state: { overviewReturn: true },
      })
    }
  })
}

const SkippedIndicator = props => {
  const navigate = useNavigate()

  const question = props.currentSurvey.surveyStoplightQuestions.find(
    indicator => indicator.codeName === props.match.params.indicator,
  )

  const { classes } = props
  const { t } = useTranslation()
  return (
    <div>
      <TitleBar
        title={question.dimension}
        extraTitleText={question.questionText}
        progressBar={false}
      />
      <Container>
        <div className={classes.container}>
          <Typography variant="h5" gutterBottom>
            {t('views.skippedIndicator.subtitle')}
          </Typography>
          <div className={classes.imgContainer}>
            <img
              className={classes.skippedQuestionsImg}
              src={SkippedImg}
              alt=""
            />
          </div>
          <Button
            variant="contained"
            onClick={() => {
              goToQuestion(
                props.currentSurvey.surveyStoplightQuestions,
                question,
                navigate,
              )
            }}
            color="primary"
          >
            {t('views.skippedIndicator.buttonLabel')}
          </Button>
          <BottomSpacer />
        </div>
      </Container>
    </div>
  )
}

const styles = theme => ({
  skippedQuestionsImg: {
    width: 350,
    marginTop: 40,
    marginBottom: 30,
    alignSelf: 'center',
    [theme.breakpoints.down('500')]: {
      width: 285,
    },
  },
  imgContainer: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  container: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(7),
  },
})

const mapStateToProps = ({ currentSurvey, currentDraft }) => ({
  currentSurvey,
  currentDraft,
})

const mapDispatchToProps = { updateDraft }

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(SkippedIndicator),
)
