import { theme } from '~/theme'

const selectStyle = {
  control: (styles, { isFocused, isDisabled }) => ({
    ...styles,
    backgroundColor: isDisabled
      ? theme.palette?.background?.paper
      : theme.palette?.background?.default,
    borderRadius: 2,
    '&:hover': {
      borderColor: isFocused ? theme.palette?.primary?.main : 'hsl(0, 0%, 70%)',
    },
    border: isFocused
      ? `1.5px solid ${theme.palette?.primary?.main}`
      : `1.5px solid ${theme.palette?.grey[300]}`,
    boxShadow: isFocused ? `0 0 0 1px ${theme.palette?.primary?.main}` : 'none',
    overflowY: 'auto',
    maxHeight: 75,
  }),
  multiValueLabel: styles => ({
    ...styles,
    fontSize: 14,
    fontFamily: 'Poppins',
    fontWeight: 500,
    color: 'rgba(28,33,47,0.51)',
  }),
  multiValue: styles => ({ ...styles, color: 'rgba(28,33,47,0.51)' }),
  option: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: isFocused ? 'hsl(0,0%,90%)' : 'transparent',
    fontSize: 14,
    fontFamily: 'Poppins',
  }),
  noOptionsMessage: styles => ({
    ...styles,
    fontSize: 16,
    fontFamily: 'Poppins',
  }),
  loadingMessage: styles => ({
    ...styles,
    fontSize: 16,
    fontFamily: 'Poppins',
  }),
  singleValue: (styles, { isDisabled }) => ({
    ...styles,
    color: 'hsl(0, 0%, 20%)',
    fontSize: 14,
  }),
  menu: styles => ({
    ...styles,

    zIndex: 2,
  }),
}

const outlineSelectStyle = {
  control: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: '#FFFFFF',
    borderRadius: 2,
    '&:hover': { borderColor: isFocused ? '#309E43' : 'hsl(0, 0%, 70%)' },
    border: isFocused ? '1.5px solid #309E43' : '1.5px solid #DCDEE3',
    boxShadow: isFocused ? '0 0 0 1px #309E43' : 'none',
    overflowY: 'auto',
    maxHeight: 75,
  }),
  multiValueLabel: styles => ({
    ...styles,
    fontSize: 14,
    fontFamily: 'Poppins',
    fontWeight: 500,
    color: 'rgba(28,33,47,0.51)',
  }),
  multiValue: styles => ({ ...styles, color: 'rgba(28,33,47,0.51)' }),
  option: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: isFocused ? 'hsl(0,0%,90%)' : 'transparent',
    fontSize: 14,
    fontFamily: 'Poppins',
  }),
  noOptionsMessage: styles => ({
    ...styles,
    fontSize: 16,
    fontFamily: 'Poppins',
  }),
  loadingMessage: styles => ({
    ...styles,
    fontSize: 16,
    fontFamily: 'Poppins',
  }),
}

const autoCompleteStyle = {
  ...selectStyle,
  control: styles => ({
    ...styles,
    height: 49,
    maxHeight: 75,
    border: 'none',
    paddingRight: 11,
    boxShadow: 'none',
    overflowY: 'auto',
    '&:hover': { borderColor: 'none' },
    borderRadius: '8px 8px 0 0!important',
    backgroundColor: theme.palette?.background?.paper,
    borderBottom: `.5px solid ${theme.palette?.grey[500]}`,
  }),
  placeholder: styles => ({
    ...styles,
    fontSize: 16,
    paddingBottom: 25,
    color: theme.palette?.grey[600],
  }),
}

const pickerColor = [
  '#f28b82',
  '#fbbc04',
  '#fff475',
  '#ccff90',
  '#a7ffeb',
  '#cbf0f8',
  '#aecbfa',
  '#d7aefb',
  '#fdcfe8',
  '#e6c9a8',
  '#e8eaed',
] as const

export { selectStyle, outlineSelectStyle, autoCompleteStyle, pickerColor }
