import React from 'react'

import {
  CheckBox as Checkbox,
  Event as Date,
  ArrowDropDownCircle as DropDown,
  LooksOne as Number,
  RadioButtonChecked as Radio,
  ShortText as Text,
} from '@mui/icons-material'

const QuestionIcon = ({ type, iconClass }) => {
  if (type === 'select') {
    return <DropDown className={iconClass} />
  } else if (type === 'number') {
    return <Number className={iconClass} />
  } else if (type === 'radio') {
    return <Radio className={iconClass} />
  } else if (type === 'text') {
    return <Text className={iconClass} />
  } else if (type === 'checkbox' || type === 'boolean') {
    return <Checkbox className={iconClass} />
  } else if (type === 'date') {
    return <Date className={iconClass} />
  } else {
    return <DropDown className={iconClass} />
  }
}

export default QuestionIcon
