import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import {
  Button,
  CardMedia,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material'
import { NavLink, Link as RouterLink, useNavigate } from 'react-router-dom'

import { type MenuTab } from '~/Header'
import logo from '~/assets/header_logo.png'
import { useAppSelector } from '~/redux/hooks'
import { canAccess, getHomePage } from '~/utils/role-utils'

interface DesktopMenuProps {
  tabs: MenuTab[]
}

export default function DesktopMenu({ tabs }: DesktopMenuProps) {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const user = useAppSelector(state => {
    if (!state.user) throw new Error('No user')
    return state.user
  })
  const specialOrgPath = `/hubs/${user.hub?.id}/organizations`
  const specialProyectPath = `/hubs/${user.hub?.id}/organizations/${user.organization?.id}/projects`

  const mainLogoURL = canAccess(user, 'dashboard')
    ? `dashboard`
    : getHomePage(user)

  return (
    <>
      <IconButton component={RouterLink} to={`/${mainLogoURL}`}>
        <CardMedia
          src={logo}
          component="img"
          alt="Stoplight Logo"
          sx={{ width: 40, height: 40 }}
        />
      </IconButton>

      {tabs.map(({ item, options }) => {
        const isDropdown = Array.isArray(options)

        const label = t(`views.toolbar.${item}`)
        let path = `/${item}`
        if (item === 'projects') path = specialProyectPath
        if (item === 'organizations') path = specialOrgPath

        return (
          <Stack key={item} direction="row" alignItems="center">
            {isDropdown ? (
              <DropdownMenu
                title={t(`views.toolbar.${item}`)}
                options={options.map(option => ({
                  label: t(`views.toolbar.${option}`),
                  action: () => {
                    navigate(`/${option}`)
                  },
                }))}
              />
            ) : (
              <Link
                to={path}
                component={NavLink}
                sx={{
                  px: 1.5,
                  textDecoration: 'none',
                }}
              >
                <Typography variant="subtitle1">{label}</Typography>
              </Link>
            )}
          </Stack>
        )
      })}
    </>
  )
}

interface DropdownMenuProps {
  title: string
  options: Array<{ label: string; action: () => void }>
}

function DropdownMenu({ title, options }: DropdownMenuProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const isOpened = Boolean(anchorEl)

  function onClose() {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        endIcon={
          isOpened ? (
            <KeyboardArrowUp
              sx={{ color: theme => theme.typography.subtitle1.color }}
            />
          ) : (
            <KeyboardArrowDown
              sx={{ color: theme => theme.typography.subtitle1.color }}
            />
          )
        }
        onClick={event => {
          setAnchorEl(event.currentTarget)
        }}
      >
        <Typography variant="subtitle1">{title}</Typography>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isOpened}
        onClose={onClose}
      >
        {options.map(option => (
          <MenuItem
            key={option.label}
            onClick={() => {
              option.action()
              onClose()
            }}
          >
            <Typography variant="subtitle1">{option.label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
