import { useSelector } from 'react-redux'

import { type Role, type User } from '../types/user'

export function useRole() {
  const user = useSelector((state: { user: User }) => state.user)
  /**
   * Check if the current user has any of the roles in the list
   */
  function userHasAnyRole(roles: Role[]): boolean {
    if (Array.isArray(roles) && roles.length > 0) {
      return roles.some(role => role === user.role)
    }

    return false
  }

  return { userHasAnyRole }
}
