import {
  Close as CloseIcon,
  Info as InfoIcon,
  Redo as RedoIcon,
  Undo as UndoIcon,
} from '@mui/icons-material'
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Typography,
  alpha,
} from '@mui/material'
import { makeStyles } from '@mui/styles'

import AudioHelp from '~/components/AudioHelp'
import { MUIWrapperContext } from '~/components/providers/MuiWrapperProvider'
import { useAppSelector } from '~/redux/hooks'
import { type SurveyStoplightQuestion } from '~/utils/types/current-survey'
import { type IndicatorValue, type LifemapLevel } from '~/utils/types/surveys'

import StopLightQuestionCarousel from './StopLightQuestionCarousel'

const useStyles = makeStyles(theme => ({
  infoModal: {
    width: '80vw',
    height: '80vh',
    maxWidth: 550,
    maxHeight: 550,
    backgroundColor: '#fff',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    padding: theme.spacing(6),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
    },
  },
  closeIcon: {
    position: 'absolute',
    top: 5,
    right: 5,
    marginBottom: 15,
  },
  icon: {
    color: 'green',
    cursor: 'pointer',
    fontSize: 30,
  },
  innerContainer: {
    height: '100%',
    width: '100%',
    overflowY: 'auto',
    textAlign: 'justify',
    paddingRight: 10,
  },
}))

interface Props {
  pickedColor: null | IndicatorValue
  onColorPick: (color: IndicatorValue) => void
  question: SurveyStoplightQuestion
  allowSkip?: boolean
  allowToMarkAsDoesNotApply?: boolean
}

const compare = (a: LifemapLevel, b: LifemapLevel) => {
  if (a.value < b.value) return 1
  if (a.value > b.value) return -1
  return 0
}

export default function Levels({
  pickedColor,
  onColorPick,
  question,
  allowSkip = false,
  allowToMarkAsDoesNotApply = false,
}: Props) {
  const { t } = useTranslation()
  const classes = useStyles()

  const user = useAppSelector(state => {
    if (!state.user) throw new Error('No user')
    return state.user
  })

  const { currentDirection } = useContext(MUIWrapperContext)

  const sortedQuestion = question.stoplightColors.toSorted(compare) || []

  const [playHelpAudio, setPlayHelpAudio] = useState(false)
  const [showDefinition, setShowDefinition] = useState(false)

  useEffect(() => {
    setPlayHelpAudio(false)
  }, [question])

  return (
    <>
      <StopLightQuestionCarousel
        options={sortedQuestion}
        answeredValue={pickedColor}
        codeName={question.codeName}
        submitQuestion={e => {
          onColorPick(e)
        }}
      />
      <Grid
        container
        px={{ md: 10 }}
        alignItems="center"
        justifyContent="space-around"
      >
        <Grid item md={4} display="flex" flexDirection="row">
          {question.definition && (
            <IconButton
              color="primary"
              onClick={() => {
                setShowDefinition(true)
              }}
            >
              <InfoIcon fontSize="large" />
            </IconButton>
          )}

          {user.interactive_help && question.questionAudio && (
            <AudioHelp
              audio={question.questionAudio}
              playAudio={playHelpAudio}
              handlePlayPause={() => {
                setPlayHelpAudio(!playHelpAudio)
              }}
            />
          )}
        </Grid>

        <Grid
          item
          md={8}
          flexDirection={{ xs: 'column', md: 'row' }}
          display="flex"
          gap={2}
          justifyContent="end"
        >
          {allowToMarkAsDoesNotApply && (
            <Button
              color="primary"
              variant="outlined"
              startIcon={<CloseIcon />}
              onClick={() => {
                onColorPick(9)
              }}
              sx={{
                transition: 'all 100ms ease',
                bgcolor: theme =>
                  pickedColor === 9
                    ? alpha(theme.palette.primary.light, 0.25)
                    : '',
              }}
            >
              {t('views.dashboard.doesNotApply')}
            </Button>
          )}

          {allowSkip && (
            <Button
              startIcon={
                currentDirection === 'ltr' ? <RedoIcon /> : <UndoIcon />
              }
              color="primary"
              variant="outlined"
              sx={{
                transition: 'all 100ms ease',
                bgcolor: theme =>
                  pickedColor === 0
                    ? alpha(theme.palette.primary.light, 0.25)
                    : '',
              }}
              onClick={() => {
                onColorPick(0)
              }}
            >
              {t('views.lifemap.skipThisQuestion')}
            </Button>
          )}
        </Grid>
      </Grid>
      <Modal
        open={showDefinition}
        onClose={() => {
          setShowDefinition(!showDefinition)
        }}
      >
        <div className={classes.infoModal}>
          <IconButton
            className={classes.closeIcon}
            key="dismiss"
            onClick={() => {
              setShowDefinition(false)
            }}
            size="large"
          >
            <CloseIcon style={{ color: 'green' }} />
          </IconButton>

          <Typography variant="h5">
            {t('views.lifemap.indicatorDefinition')}
          </Typography>

          <Box mt={4} />

          <div className={classes.innerContainer}>
            {question.definition?.split(/(?:\\n)/g).map((i, key) => (
              <Typography variant="body2" color="textPrimary" key={key}>
                {i}
              </Typography>
            ))}
          </div>
        </div>
      </Modal>
    </>
  )
}
