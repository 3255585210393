import React from 'react'

import { Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { updateDraft } from '~/redux/slices/currentDraft'

import placeholder from '../../assets/grey_isologo.png'
import FooterPopup from '../../components/FooterPopup'
import TitleBar from '../../components/TitleBar'
import { isTimerNum, remakeTimers, screenTimer } from '../../utils/survey-utils'
import StopLightQuestionCarousel from './StopLightQuestionCarousel'

const useStyles = makeStyles(() => ({
  mainContainer: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonContainer: {
    margin: '2rem',
    zIndex: 0,
  },
}))

const StoplightQuestionHelp = ({ currentDraft }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const navigate = useNavigate()

  const options = [
    { value: 3, description: t('views.lifemap.green'), url: placeholder },
    { value: 2, description: t('views.lifemap.yellow'), url: placeholder },
    { value: 1, description: t('views.lifemap.red'), url: placeholder },
  ]

  const [showFooter, setShowFooter] = useState(true)

  useEffect(() => {
    let updatedDraft = { ...currentDraft }
    if (isTimerNum(updatedDraft)) {
      const remakedTimers = remakeTimers(updatedDraft)
      updatedDraft = { ...updatedDraft, ...remakedTimers }
    }
    const screenTimes = screenTimer(updatedDraft, 'Help')
    updateDraft({ ...updatedDraft, ...screenTimes })
  }, [])

  return (
    <div className={classes.mainContainer}>
      <TitleBar title={t('views.lifemap.info')} progressBar />
      <StopLightQuestionCarousel
        interactive
        codeName="info"
        options={options}
        submitQuestion={() => {}}
      />
      <FooterPopup
        title={t('views.lifemap.answer')}
        description={t('views.lifemap.answerText')}
        isOpen={showFooter}
        handleButtonClick={() => {
          setShowFooter(false)
        }}
        buttonText={t('general.gotIt')}
      />
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            navigate('/lifemap/stoplight/0')
          }}
        >
          {t('general.continue')}
        </Button>
      </div>
    </div>
  )
}

const mapDispatchToProps = {
  updateDraft,
}

const mapStateToProps = ({ currentDraft }) => ({ currentDraft })

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StoplightQuestionHelp)
