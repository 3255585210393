import { type Dispatch, type SetStateAction, createContext } from 'react'

import { LinearProgress } from '@mui/material'
import { useLocation } from 'react-router-dom'

import { useAppSelector } from '~/redux/hooks'
import { type CurrentSurvey } from '~/utils/types/current-survey'

import LifemapNav from './LifemapNav'

// Screens:
// PrimaryParticipant (1)
// Location (1)
// Economics (Variable)
// FamilyMembers (1 or 0)
// BeginStoploght (1)
// StoplightQuestions (Variable)
// SkippedQuestions (1 or 0)
// Overview (1)
// Final (1)

// We create a context because the ProgressBar as currently implemented is re-renderer in every screen
// and so the state if it was local and also the buildRouteTree() function that's is somewhat perf expensive
type ScrensCount = Record<string, number>
type SetScreensCount = Dispatch<SetStateAction<ScrensCount>>

export const ProgressBarContext = createContext<null | {
  screensCount: ScrensCount
  setScreensCount: SetScreensCount
}>(null)

export function ProgressBarProvider({ children }: { children: JSX.Element }) {
  const [screensCount, setScreensCount] = useState<ScrensCount>({})

  return (
    <ProgressBarContext.Provider value={{ screensCount, setScreensCount }}>
      <LifemapNav />
      {children}
    </ProgressBarContext.Provider>
  )
}

function updateCountForScreens(screensCount: ScrensCount, screens: string[]) {
  let clonedScreensCount = structuredClone(screensCount)
  let counter = 0

  //* NOTE: If the screensCount is not empty, then we initailize our counter
  //* with the length of screens
  const isScreensCountEmpty = Object.keys(clonedScreensCount).length === 0
  if (!isScreensCountEmpty) {
    counter = Object.keys(clonedScreensCount).length
  }

  screens.forEach(screen => {
    clonedScreensCount = Object.assign({}, clonedScreensCount, {
      [screen]: counter,
    })
    counter += 1
  })

  return clonedScreensCount
}

function buildScreensCount(
  currentSurvey: CurrentSurvey,
  screensCount: ScrensCount,
) {
  let clonedScreensCount = structuredClone(screensCount)

  const firstScreens = ['primary-participant', 'family-members', 'location']
  clonedScreensCount = updateCountForScreens(clonedScreensCount, firstScreens)

  const economicScreens =
    currentSurvey.economicScreens?.questionsPerScreen.map(
      (question, index) => `economics/${index}`,
    ) ?? []
  clonedScreensCount = updateCountForScreens(
    clonedScreensCount,
    economicScreens,
  )

  const helpScreens = ['begin-stoplight', 'help-stoplight']
  clonedScreensCount = updateCountForScreens(clonedScreensCount, helpScreens)

  const questionScreens = currentSurvey.surveyStoplightQuestions.map(
    (q, idx) => `stoplight/${idx}`,
  )
  clonedScreensCount = updateCountForScreens(
    clonedScreensCount,
    questionScreens,
  )

  const finalScreens = ['skipped-questions', 'overview', 'final']
  clonedScreensCount = updateCountForScreens(clonedScreensCount, finalScreens)

  return clonedScreensCount
}

export default function ProgressBar() {
  const progressBarContext = useContext(ProgressBarContext)
  if (!progressBarContext)
    throw new Error(
      'ProgressBarContext should be used inside of ProgressBarProvider',
    )

  const location = useLocation()
  const pathname = location.pathname.replace('/lifemap/', '')
  const { screensCount, setScreensCount } = progressBarContext

  const currentSurvey = useAppSelector(state => {
    if (!state.currentSurvey) throw new Error('No currentSurvey')
    return state.currentSurvey
  })

  useEffect(() => {
    const isScreensCountEmpty = Object.keys(screensCount).length === 0
    if (isScreensCountEmpty) {
      const newScreensCount = buildScreensCount(currentSurvey, {})
      setScreensCount(newScreensCount)
    }
  }, [currentSurvey, setScreensCount, screensCount])

  const progress = useMemo(() => {
    const currentScreen = screensCount[pathname]
    const isScreensCountEmpty = Object.keys(screensCount).length === 0

    if (isScreensCountEmpty) return 0
    return (currentScreen * 100) / (Object.keys(screensCount).length - 1)
  }, [pathname, screensCount])

  return <LinearProgress variant="determinate" value={progress} />
}
