import { z } from 'zod'

type Falsy = false | '' | 0 | null | undefined

/**
 * @example
 * const excludeFalsy = excludeFalsyWithMessage('This field is required')
 * const schema = z.object({
 *   name: z.string().optional().transform(excludeFalsy),
 * })
 */
export function excludeFalsyWithMessage(message: string) {
  return function <T>(value: T | Falsy, context: z.RefinementCtx) {
    if (!value) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message,
      })

      return z.NEVER
    }

    return value
  }
}
