import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import { Button, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import skippedQuestions from '~/assets/skipped_questions.png'
import Container from '~/components/Container'
import FooterPopup from '~/components/FooterPopup'
// Project
import TitleBar from '~/components/TitleBar'
import { MUIWrapperContext } from '~/components/providers/MuiWrapperProvider'
import { type CurrentDraft } from '~/utils/types/current-draft'
import {
  type CurrentSurvey,
  type SurveyStoplightQuestion,
} from '~/utils/types/current-survey'
import { type IndicatorValue } from '~/utils/types/surveys'

interface UserAnswer {
  value: IndicatorValue
  key: SurveyStoplightQuestion['codeName']
  dimension: SurveyStoplightQuestion['dimension']
  questionText: SurveyStoplightQuestion['questionText']
}

export default function SkippedQuestions() {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const classes = useStyles()

  const { currentDirection } = useContext(MUIWrapperContext)

  const currentSurvey = useSelector(
    (state: { currentSurvey: CurrentSurvey }) => state.currentSurvey,
  )

  const currentDraft = useSelector(
    (state: { currentDraft: CurrentDraft }) => state.currentDraft,
  )

  const [isFooterPopupVisible, setIsFooterPopupVisible] = useState(true)

  const userAnswers: UserAnswer[] = useMemo(() => {
    const filteredAnswers: UserAnswer[] = []

    currentSurvey.surveyStoplightQuestions.forEach(currentSurveyQuestion => {
      currentDraft.indicatorSurveyDataList.forEach(currentDraftQuestion => {
        const isSkipped = currentDraftQuestion.value === 0
        const isAnswered =
          currentDraftQuestion.key === currentSurveyQuestion.codeName

        if (isAnswered && isSkipped) {
          filteredAnswers.push({
            value: currentDraftQuestion.value,
            key: currentSurveyQuestion.codeName,
            dimension: currentSurveyQuestion.dimension,
            questionText: currentSurveyQuestion.questionText,
          })
        }
      })
    })

    return filteredAnswers
  }, [currentSurvey, currentDraft])

  const groupedAnswers = useMemo(
    () =>
      userAnswers.reduce((reducerObject, currentUserAnswer) => {
        reducerObject[currentUserAnswer.dimension] =
          reducerObject[currentUserAnswer.dimension] || []
        reducerObject[currentUserAnswer.dimension].push(currentUserAnswer)

        return reducerObject
      }, {}),
    [userAnswers],
  )

  function goToQuestion(e: UserAnswer) {
    const selectedQuestionIndex =
      currentSurvey.surveyStoplightQuestions.findIndex(
        question => question.codeName === e.key,
      )

    navigate(`/lifemap/stoplight/${selectedQuestionIndex}`, {
      state: { skippedReturn: true },
    })
  }

  return (
    <div>
      <TitleBar title={t('views.skippedIndicators')} />
      <Container variant="slim" className={classes.container}>
        <img
          className={classes.skippedQuestionsImg}
          src={skippedQuestions}
          alt=""
        />
        {Object.keys(groupedAnswers).map(elem => {
          return (
            <div key={elem}>
              {groupedAnswers[elem].map(e => {
                return (
                  <div
                    key={e.key}
                    onClick={() => {
                      goToQuestion(e)
                    }}
                    className={classes.overviewAnswers}
                  >
                    <Typography>{e.questionText}</Typography>
                    {currentDirection === 'rtl' ? (
                      <ArrowBackIos fontSize="small" />
                    ) : (
                      <ArrowForwardIos fontSize="small" />
                    )}
                  </div>
                )
              })}
            </div>
          )
        })}
        <div className={classes.buttonContainer}>
          {!isFooterPopupVisible && (
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                navigate('/lifemap/overview')
              }}
            >
              {t('general.continue')}
            </Button>
          )}
        </div>
      </Container>
      <FooterPopup
        isOpen={isFooterPopupVisible}
        title={t('views.lifemap.youSkipped')}
        description={t('views.lifemap.whyNotTryAgain')}
        handleButtonClick={() => {
          setIsFooterPopupVisible(false)
        }}
      />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  skippedQuestionsImg: {
    width: 350,
    marginTop: 40,
    marginBottom: 30,
    alignSelf: 'center',
    [theme.breakpoints.down(500)]: {
      width: 285,
    },
    [theme.breakpoints.down(400)]: {
      width: 185,
      height: 125,
    },
  },
  container: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  titleAndIconContainerPolicy: {
    backgroundColor: '#faefe1',
    display: 'flex',
    padding: '10px 40px 10px 10px',
    alignItems: 'center',
  },
  titleMainAll: {
    margin: 'auto',
  },
  overviewAnswers: {
    cursor: 'pointer',
    width: '100%',
    paddingTop: 35,
    paddingBottom: 35,
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '40px 0 50px 0',
  },
  icon: {
    fontSize: 24,
    color: theme.palette.grey[500],
    marginRight: 35,
  },
}))
